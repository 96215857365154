<template>
  <div class="page trazabilidad px-3 py-5 px-md-5">
    <div class="header-page">
      <h3>Trazabilidad</h3>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
        <!-- Pantalla Inicio Trazabilidad -->
        <div class="item-page-new" v-if="!listTrack">
          <div class="circle-icon-page d-flex justify-content-center align-items-center">
            <div>
              <img src="~@/assets/pages/track.png" class="img-fluid">
            </div>
          </div>
          <div class="pt-4">
            <h5 class="font-weight-bold" >Cree una nueva trazabilidad</h5>
          </div>
          <div class="pt-3">
            <b-button class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar trazabilidad</b-button>
          </div>
        </div>
        <div class="item-page-list w-100" v-if="listTrack">
          <div>
            <div class="row justify-content-end mt-4 mb-4" v-if="!is_leader()">
              <div class="col-2 text-left">
                <p class="font-weight-bold">Filtrar por línea:</p>
                <div>
                  <b-form-select v-model="line_id" @change="search(line_id)" :options="getLineList" class="mb-3">
                    <template #first>
                      <b-form-select-option value="undefined">Todas las líneas</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-1 allData text-left">
                <button class="btn-filter-search" @click="setDataTrack()">Refrescar</button>
              </div>
            </div>
            <div class="table-responsive">
              <table v-if="loadTable" class="table table-list list-track">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th colspan="3" class="row-entrada text-center">Entrada</th>
                    <th colspan="2" class="row-salida text-center">Salida</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="subhead-titles">
                    <td class="font-weight">Línea</td>
                    <td class="font-weight">OP</td>
                    <td class="font-weight">Referencia ~ Lote</td>
                    <td class="font-weight">Base</td>
                    <td class="font-weight">Orden de PB</td>
                    <td class="font-weight">Lote logístico de PB</td>
                    <td class="font-weight">Orden final maquila</td>
                    <td class="font-weight">Lote<br>Salida</td>
                    <td class="font-weight">Fecha de vencimiento</td>
                    <td></td>
                  </tr>
                  <tr v-for="(k,i) in dataTrack" :key="i">
                    <td><span class="text-dark font-weight-bold">{{ k.line_name }}</span></td>
                    <td ><b-button variant="link" @click="putCurrentTask(k.product_by_production_id)" class="text-dark font-weight-bold">{{ k.production_order }}</b-button></td>
                    <td>
                      <span class="d-block">{{k.reference_batch}}</span>
                    </td>
                    <td class="multiple-register">
                      <span class="d-block" v-for="(base, j) in k.bases" :key="j">{{base.refBase}}</span>
                    </td>
                    <td class="multiple-register">
                      <span class="d-block" v-for="(lts, s) in k.bases" :key="s">{{lts.ordenProduccion}}</span>
                    </td>
                    <td class="multiple-register">
                      <span class="d-block" v-for="(ope, h) in k.bases" :key="h">{{ope.loteLogisticoEntrada}}</span>
                    </td>
                    <td>
                      <span class="d-block">{{k.finalOrderMaquila}}</span>
                    </td>
                    <td>
                      <span class="d-block">{{k.loteLogisticoSalida}}</span>
                    </td>
                    <td>
                      <span class="d-block">{{k.fechaVencimiento}}</span>
                    </td>
                    <td>
                      <button class="btn py-0 px-2" @click="setDataToForm(k.trazId);updateTrack();setTrazToAction(k.trazId);showBlock();"><i class="far fa-edit text-success"></i></button>
                      <hr class="my-2">
                      <button class="btn py-0 px-2" @click="$bvModal.show('delete-task');setTrazToAction(k.trazId)"><i class="far fa-trash-alt text-danger"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Abre trazabilidad -->
            <!-- <div class="my-3" v-if="is_leader() && activeQualityControl === false"> -->
            <div class="my-3" v-if="is_leader() && activeQualityControl === false">
              <b-button class="btn-action-page py-2" @click="showBlock();currentData = []" size="lg">Agregar Trazabilidad</b-button>
            </div>
            <!-- Cierre de trazabilidad -->
            <div class="modal-delete-task">
              <b-modal ref="close-modal" id="delete-task" centered :hide-footer="true" :hide-header="true" >
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div class="py-5">
                    <div class="circle-icon-page d-flex justify-content-center align-items-center">
                      <div>
                        <img src="~@/assets/icons/trash.png" class="img-fluid">
                      </div>
                    </div>
                    <div class="pt-4 text-center">
                      <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar la ĺínea?</h5>
                      <div class="mt-4">
                        <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="close_modal(); setTrazToAction(null);">Cancelar</b-button>
                        <b-button  size="lg" class="btn-actions btn-delete mx-3" @click="delTraz(); close_modal();">Eliminar</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav-page p-4" :class="{ openSidebar: openOptions}">
      <side-block
        :icon="'track-interna'"
        :tpl="'track'"
        @closeBlock="close"
        :data="currentData"
      />
    </div>
    <div>
      <b-modal v-if="showModal" ref="close-sub-modal" id="detail-task" size="xl" :hide-footer="true" :hide-header="true" body-class="">
          <div class="d-flex justify-content-end">
            <button class="bg-transparent border-0" @click="close_sub_modal()">
              <i class="far fa-times-circle fa-2x color-grayLight"></i>
            </button>
          </div>
          <div class="px-3 mb-3">
             <h4 class="color-bluedark">Detalle: Orden de producción - Lote - Referencia</h4>
             <div class="p-4 bg-grayLight">
              <div class="row">
                <div class="col-6">
                  <div class="my-4">
                    <h6>Orden de Producción</h6>
                    <div class="bg-white border p-2">
                      {{currentTrackItem[0].ProductByProductionOrder.production_order}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Lote</h6>
                    <div class="bg-white border p-2">
                      {{currentTrackItem[0].ProductByProductionOrder.batch}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Referencia</h6>
                    <div class="bg-white border p-2">
                      {{currentTrackItem[0].product.reference}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Nombre</h6>
                    <div class="bg-white border p-2">
                      {{currentTrackItem[0].product.name}}
                    </div>
                  </div>
                    <h6>Ficha Técnica</h6>
                  <div class="pt-3">
                    <b-button class="btn-action-page py-2" @click="renderPdf(urlsheet)" size="lg">Ver Ficha</b-button>
                  </div>
                </div>
                <div class="col-6 border-left">
                  <div class="my-4">
                    <h6>Cantidad inicial</h6>
                    <div class="bg-white border p-2">
                      {{currentTrackItem[0].ProductByProductionOrder.start_quantity}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Bases</h6>
                    <div v-for="(base, i) in currentTrackItem[0].bases" :key="i" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                      <div class="col-5">
                        <h6>{{base.reference}}</h6>
                      </div>
                      <div class="col border-left">
                        <span>{{base.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Insumos</h6>
                    <div v-for="(supplie, i) in currentTrackItem[0].supplies" :key="supplie.id" :id="'supplie' + i + '-' + supplie.id" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                      <div class="col-4">
                        <h6>{{supplie.id}}</h6>
                      </div>
                      <div class="col border-left">
                        <span>{{supplie.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Estándares de la maquila</h6>
                    <!-- <div class="bg-white border p-2">
                      {{currentTrackItem[0].product.ppk_standard}}
                    </div> -->
                    <div v-for="(ppkStandardContent, i) in currentTrackItem[0].ppkStandardsProductList" :key="ppkStandardContent.id" :id="'supplie' + i + '-' + ppkStandardContent.id" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                      <div class="col-5">
                        <h6>
                          <span class="col-2">#{{ppkStandardContent.id}}</span>
                          <span>{{ppkStandardContent.name}}</span>
                        </h6>
                      </div>
                      <div class="col border-left">
                        <span>Orden de proceso: {{ppkStandardContent.process_order}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
          </div>
      </b-modal>
    </div>
    <div>
      <b-modal ref="pdf-modal" id="pdf-modal" size="xl" centered :hide-footer="true" :hide-header="true">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_pdf_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div align="center">
          <pdf :src="currentDataSheetURL" id="pdf-view" :resize="true" @loading="removeText()" :page="1">
            <template slot="loading">
              Cargando ficha técnica...
            </template>
          </pdf>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import pdf from 'pdfvuer'
import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    currentDataSheetURL: '',
    showModal: false,
    listTrack: true,
    searchTrack: '',
    currentTrackItem: [],
    currentData: [],
    urlsheet: '',
    loadTable: true,
    line_id: undefined
  }),
  mounted () {
    // if ((sessionStorage.token && sessionStorage.rol === '1') || sessionStorage.rol === '2') {
    //   this.setDataTrack()
    //   this.$store.dispatch('getLineListWithTeam')
    //   this.$root.$on('setDataTrack', () => {
    //     this.setDataTrack()
    //   })
    //   this.$store.dispatch('atLeastQualityRecordFn')
    //   this.$store.dispatch('statusModulesEnabled')
    // }
    if (sessionStorage.token) {
      this.$router.push({ name: 'Inicio' })
    }
  },
  computed: {
    getLineList () {
      return this.$store.state.lineListWithTeam
    },
    currentTask () {
      return this.$store.state.storeProduction.currentTask
    },
    dataTrack: {
      get () {
        return this.$store.state.storeTrack.dataTrack
      },
      set (value) {
        this.$store.state.storeTrack.dataTrack = value
      }
    },
    openOptions () {
      return this.$store.state.showOptions
    },
    activeQualityControl () {
      return this.$store.state.storeQualityModule.statusQualityModule
    },
    selectedLote: {
      set (value) {
        this.$store.state.storeTrack.selectedLote = value
      },
      get (value) {
        return this.$store.state.storeTrack.selectedLote
      }
    }
  },
  components: {
    SideBlock,
    pdf
  },
  methods: {
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    async search (linea) {
      await this.setDataTrack()
      if (linea !== 'undefined') {
        var array = []
        this.dataTrack.forEach(function (item, value) {
          if (item.line_id === parseInt(linea)) {
            array.push(item)
          }
        })
        this.dataTrack = array
      }
    },
    renderPdf (urlSent) {
      this.currentDataSheetURL = url + 'media/' + urlSent.split('/media/')[1]
      window.open(this.currentDataSheetURL, '_blank')
    },
    showBlock: function () {
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
      this.selectedLote = null
      this.$store.state.storeTrack.selectedRef = null
    },
    updateTrack () {
      this.$store.state.storeTrack.updateTrack = true
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    close_modal: function () {
      this.$refs['close-modal'].hide()
      this.selectedLote = null
    },
    setTrazToAction: function (traz) {
      this.$store.commit('actionSetTrazToAction', traz)
    },
    async putCurrentTask (task) {
      const self = this
      await axios.get('/api/productinfo/' + task, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.currentTrackItem = [{ product: response.data.Product, bases: response.data.Bases, supplies: response.data.Supplies, ProductByProductionOrder: response.data.ProductByProductionOrder, ppkStandardsProductList: response.data.ppkStandardsProductList }]
        self.showModal = true
      })
      this.dataTrack.forEach(function (item, index) {
        if (item.ref === task) {
          self.urlsheet = item.url
          self.urlsheet = url + 'media/' + self.urlsheet.split('/media/')[1]
        }
      })
      this.$bvModal.show('detail-task')
    },
    async delTraz () {
      var refToDel = this.$store.state.storeTrack.trazToAction
      await axios.post('/api/deleteTraceability/', { traceability_id: refToDel }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      })
      this.setDataTrack()
      this.setTrazToAction(null)
    },
    async setDataToForm (trazId) {
      const self = this
      this.dataTrack.forEach(function (item, index) {
        if (item.trazId === trazId) {
          self.$store.state.storeTrack.selectedLine = item.line_id
          self.$store.state.storeTrack.selectedRef = item.reference_batch
          self.$store.state.storeTrack.selectedLote = item.batch
          self.$store.state.storeTrack.selectedOP = item.production_order
          self.$store.state.storeTrack.bases = []
          var auxBases = []
          item.bases.forEach(function (item, index) {
            auxBases.push({ refBase: item.refBase })
            var value = item.lote
            self.$store.commit('actionSetInputLote', { index, value })
            value = item.loteLogisticoEntrada
            self.$store.commit('actionSetInputLoteLogistico', { index, value })
            value = item.ordenProduccion
            self.$store.commit('actionSetInputordenProduction', { index, value })
            self.$store.state.storeTrack.imagesPreview.push(url + 'media/' + item.file_url.split('/media/')[1])
            value = item.julian_day
            self.$store.commit('actionSetInputJulianDay', { index, value })
          })
          self.$set(self.$store.state.storeTrack.bases, 'bases', auxBases)
        }
      })
    },
    infoLoaded () {
      if (this.currentTrackItem.length !== 0) {
        return true
      } else {
        return false
      }
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
      this.selectedLote = null
    },
    close_pdf_modal () {
      this.$refs['pdf-modal'].hide()
    },
    async setDataTrack () {
      const self = this
      self.dataTrack = []
      await axios.post('/api/getTraceabilities/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.forEach(function (traz, index) {
          var basestrack = []
          traz.trazabilityproduct.forEach(function (bases, index) {
            basestrack.push({ refBase: bases.base_id, loteLogisticoEntrada: bases.entry_logistics_batch, lote: bases.entry_batch, ordenProduccion: bases.entry_production_order, file_url: bases.file, julian_day: bases.julian_day })
          })
          self.dataTrack.push({
            trazId: traz.trazability.id,
            line_id: traz.trazability.line,
            line_name: traz.line_name,
            ref: traz.trazability.reference,
            reference_batch: traz.trazability.reference_batch,
            batch: traz.trazability.batch,
            production_order: traz.trazability.production_order,
            product_by_production_id: traz.trazability.productsByProduccionOrder_id,
            url: url + 'media' + (traz.datasheet_url).split('media')[1],
            loteLogisticoSalida: traz.trazability.output_batch,
            fechaVencimiento: traz.trazability.output_due_date,
            finalOrderMaquila: traz.trazability.output_production_order,
            bases: basestrack
          })
          self.loadTable = true
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .list-track {
    thead {
      tr {
        background-color: #F5F6F8;
        border-bottom: 1px solid #ccc;
        th.row-entrada {
          background-color: #464FB5;
          color: #FFF;
        }
        th.row-salida {
          background-color: #363D8A;
          color: #FFF;
        }
      }
    }
    tbody {
      tr.subhead-titles {
        background-color: #FFF;
        border: 0;
      }
      td {
        vertical-align: middle;
        &.multiple-register {
          span {
            border-bottom: 1px solid #8e8e8e;
            padding-bottom: 5px;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 950.98px) {
    #detail-task {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  }

  @media (min-width: 950.98px) {
    #detail-task, #pdf-modal {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 15%;
        margin-right: 10%;
      }
    }
  }

  .container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.font-weight {
  font-weight: 600;
}
  </style>

<template>
    <div class="w-100" :class="'list-' + state">
        <div class="item-page-list">
          <div>
            <div class="text-left mt-3 d-md-flex align-items-center header-list-quality">
              <div class="header-column-left col">
                <span class="icon-list align-middle" :class="'icon-' + color"><i :class="{'fa-exclamation' : state != 'success', 'fa-check' : state === 'success'}" class="fas"></i></span>
                <span v-if="state == 'success'" class="mx-3 h5 align-middle" :class="'color-' + color">Procesos verificados</span>
                <span v-if="state == 'info'" class="mx-3 h5 align-middle" :class="'color-' + color">Procesos pendientes</span>
                <span class="mx-0 date-process align-middle" :class="'color-' + color">
                  <span v-if="state == 'danger'" class="mx-0 date-production align-middle" :class="'color-' + color">↓</span>
                </span>
                <b-badge variant="danger align-middle">{{lenQualityRecords}}</b-badge>
                <!-- <b-badge variant="danger align-middle">{{data.length}}</b-badge> -->
              </div>
              <div class="header-column-right d-flex align-items-center justify-content-lg-end col">
                <div>
                  <b-button v-if="lenQualityRecords > limit" @click="$emit('modalAllRecordsQuality', { dataRecords: data, state: state })" class="bg-transparent text-info border-0 p-0">Ver todos</b-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-list list-quality">
                <tbody>
                  <tr class="subhead-titles">
                    <td class="font-weight">Línea</td>
                    <td class="font-weight">Orden</td>
                    <td class="font-weight">Referencia</td>
                    <td class="font-weight">Lote</td>
                    <td class="font-weight">Hora inicio</td>
                    <td class="font-weight">Hora fin</td>
                    <td class="font-weight">Estado</td>
                    <td></td>
                  </tr>
                  <!-- <tr v-for="(q,i) in data.slice(0, limit)" :key="i" :class="{state: data[i].asValidator == false, 'warning': data[i].asValidator == true}"> -->
                  <tr v-for="(q,i) in data.slice(0, limit)" :key="i" :class="[data[i].asValidator == false ? state:'warning']">
                    <td><strong>{{q.linea}}</strong></td>
                    <td><strong>{{q.op}}</strong></td>
                    <td>{{ q.referencia }}</td>
                    <td>{{ q.lote }}</td>
                    <td>{{q.hora_inicio }}</td>
                    <td>{{q.hora_fin }}</td>
                    <td>{{q.estado}}</td>
                    <td>
                      <button v-if="state == 'success'" class="btn py-0 px-2" @click="$emit('openSingleProcess', { dataSend: data[i], estado: state })"><i class="far fa-eye"></i></button>
                      <button v-if="state == 'info' && data[i].asValidator == false" class="btn py-0 px-1 text-success" @click="$emit('openSingleProcess', { dataSend: data[i], estado: state })"><i class="far fa-edit"></i></button>
                      <button v-if="data[i].asValidator == true" class="btn py-0 px-1 text-success" @click="$emit('openSingleProcess', { dataSend: data[i], estado: state })">
                        <i class="far fa-square"></i> <i class="fas fa-arrow-right"></i> <i class="far fa-check-square"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
/* const axios = require('axios') */
export default {
  name: 'ListQuality',
  props: {
    state: String,
    color: String,
    data: Array,
    limit: Number,
    lenQualityRecords: Number
  },
  data () {
    return {
      dataSegment: []
    }
  },
  computed: {},
  created () {}
}
</script>

<style lang="scss">
 .cursor-pointer {
   cursor: pointer;
 }
 .header-list-quality {
  background: #fbfbfb;
  padding: 15px 10px;
 }
 .font-weight {
  font-weight: 600;
}
</style>

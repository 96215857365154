<template>
  <div v-if="data !== undefined" class="form-control-calidad">
    <div class="header-form text-center py-3">
      <div class="h5">Verificación</div>
    </div>
    <div class="d-flex mb-4">
      <div class="col-6 p-4">
        <pdf v-if="urlDataSheet !== undefined" :src="urlDataSheet" id="pdf_view" :resize="true" :annotation="false" :text="false" @error="presetErrorPdf">
          <template slot="loading">
            Cargando ficha técnica...
          </template>
          <template slot="no_found">
            No se encontró la ficha técnica.
          </template>
        </pdf>
        <div v-else>No se encontró alguna ficha técnica que esté relacionada con la referencia. Recarga la página</div>
      </div>
      <div class="col-6 border-left py-4 pr-4">
        <!-- OP -->
        <div class="my-4 text-left">
          <h6>Orden de producción</h6>
          <div class="bg-white border p-2">{{ data.op }}</div>
        </div>
        <!-- Referencia -->
        <div class="my-4 mb-0 text-left">
          <h6>Referencia - Lote</h6>
          <div class="bg-white border p-2">{{ data.reference_id }} - {{ data.lote }}</div>
        </div>
        <div class="mt-0 text-left">
          <h6>Descripción de Referencia</h6>
          <div class="bg-white border p-2">{{ data.referencia }}</div>
        </div>
        <hr>
        <!-- Insumos de OP -->
        <div class="text-left mt-4" v-if="data.product_supplies">
          <div class="table-responsive">
            <table class="w-100 table-bases">
              <thead>
                <tr>
                  <th colspan="6" class="text-center">
                    <p class="font-weight-bold m-0">Planificación de Insumos</p>
                    <p class="font-weight-bold m-0">Datos de entrada</p>
                  </th>
                </tr>
                <tr>
                  <th><span><strong>Insumo</strong></span></th>
                  <th><span>Nombre</span></th>
                  <th><span>Cantidad</span></th>
                  <th><span>Lote</span></th>
                  <th><span>Lote logístico</span></th>
                  <th><span>Fecha de vencimiento</span></th>
                  <th><span>Creador</span></th>
                </tr>
              </thead>
              <tbody class="table-responsive-x-scroll">
                <tr v-for="(product_supply, index) in data.product_supplies" :key="index" :class="{'border-left-base' : product_supply.supplies_code__is_base}">
                  <td class="text-center position-relative">
                    <strong>{{ product_supply.supplies_code_id }}</strong>
                  </td>
                  <td class="text-center">
                    {{ product_supply.supplies_code__name }}
                  </td>
                  <td class="text-center">
                    {{ product_supply.quantity }}
                  </td>
                  <td class="text-center">
                    {{ product_supply.entry_batch }}
                  </td>
                  <td class="text-center">
                    {{ product_supply.entry_logistics_batch }}
                  </td>
                  <td class="text-center">
                    {{ product_supply.entry_due_date }}
                  </td>
                  <td class="text-center">
                    {{ product_supply.user_liable }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Trazabilidad de Insumos -->
        <div class="text-left mt-4" v-if="data.supplies">
          <table class="w-100 table-insumos">
              <thead>
                <tr>
                  <th colspan="6" class="text-center">
                    <p class="font-weight-bold m-0">Trazabilidad de Insumos</p>
                    <p class="font-weight-bold m-0">Datos de salida</p>
                  </th>
                </tr>
                <tr>
                  <th><span><strong>Insumo</strong></span></th>
                  <th><span>Nombre</span></th>
                  <th><span>Lote</span></th>
                  <th><span>Hora</span></th>
                  <th><span>Fecha de vencimiento</span></th>
                  <th><span>Foto</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in data.supplies" :key="index" :class="{'border-left-base' : record.is_base}">
                  <td class="text-center position-relative">
                    <strong>{{ record.supply_id }}</strong>
                  </td>
                  <td class="text-center">
                    {{ record.supply_name }}
                  </td>
                  <td class="text-center">
                    {{ record.batch }}
                  </td>
                  <td class="text-center">
                    {{ record.hour }}
                  </td>
                  <td class="text-center">
                    {{ record.due_date }}
                  </td>
                  <td class="text-center">
                    <div class="image-upload text-center">
                      <div ref="icon" class="d-flex align-items-center justify-content-center" id="container_icon" v-if="getImages(record.file) == undefined">
                        <i class="fas fa-camera fa-2x color-grayLight"></i>
                      </div>
                      <img :src="getImages(record.file)" id="picture_witness" v-if="getImages(record.file) !== undefined" class="imagedisplay">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdfvuer from 'pdfvuer'
const { url } = require('../../api_config.js')
export default {
  name: 'FormControlCalidad',
  props: {
    icon: String,
    data: {
      type: Object,
      default: undefined
    },
    dataSheet: {
      type: String,
      default: undefined
    },
    extraData: Array,
    closeBox: Function
  },
  data () {
    return {
      urlDataSheet: undefined
    }
  },
  components: {
    // pdf
    pdf: pdfvuer
  },
  computed: {
  },
  methods: {
    reassignValue: function () {
      const self = this
      this.resetValue().then(function () {
        self.urlDataSheet = self.dataSheet
      })
    },
    async resetValue () {
      this.urlDataSheet = undefined
    },
    closeBlock: function () {
      this.$store.commit('actionBlockOptions', false)
      this.$store.commit('actionOrvelay', false)
    },
    presetErrorPdf: function (event) {
      if (event !== undefined && event.name !== undefined) {
        if (event.name === 'MissingPDFException') {
          document.getElementById('pdf_view').innerHTML = 'No se encontró la ficha técnica de esta referencia. <br />Puede que sirva recargar la página'
        }
      }
    },
    getImages: function (dataFile) {
      if (dataFile) {
        if (
          typeof dataFile === 'string' && dataFile.includes('Traceability')
        ) {
          return url + 'media/' + dataFile
        } else {
          return dataFile
        }
      } else {
        dataFile = undefined
        return undefined
      }
    }
  },
  updated () {
    if (this.urlDataSheet !== this.dataSheet) {
      this.reassignValue()
    }
  }
}
</script>
<style lang="scss">
  .imagedisplay {
    height: auto;
    width: 3vw;
    border: 1px solid #bbb;
    position: static;
    z-index: 3;
  }
  .table-responsive-x-scroll {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .border-left-base {
    border-left-color: var(--yellow) !important;
  }
</style>

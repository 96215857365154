<template>
  <div class="side-block">
    <div class="text-right box-close-block" @click="$emit('closeBlock', false ); resetForm();">
      <i class="fas fa-times fa-2x btn-close"></i>
    </div>
    <div class="mb-2">
      <img :src="getImgUrl(icon)" alt="">
    </div>
    <div>
      <!-- Plan de Producción -->
      <FormPlanProduccion  v-if="tpl === 'plan-produccion'" />
      <!-- Plan de Producción familia -->
      <form-plan-produccion-familia v-if="tpl === 'task'"/>
      <!-- Apertura de Línea -->
      <form-apertura-linea v-if="tpl === 'team'" :data="data" />
      <!-- Trazabilidad -->
      <form-trazabilidad v-if="tpl === 'track'" :data="data" />
      <!-- Actividad -->
      <form-actividad v-if="tpl === 'activity'" :data="data" :closeBox="closeBox"/>
      <!-- Calidad -->
      <form-control-calidad v-if="tpl === 'quality'" :data="dataQuality" :closeBox="closeBox" :dataSheet="urlDataSheet"/>
      <!-- Orden de Producción -->
      <form-orden-produccion v-if="tpl === 'order'" :data="data" />
      <!-- Form de BSN -->
      <form-bsn v-if="tpl === 'bsn'" :data="data" />
      <!-- Form Purchase -->
      <form-purchase-order v-if="tpl === 'purchase'" :data="data"/>
      <!-- Form Nota Despacho -->
      <remission-order v-if="tpl === 'remission'" :data="data"/>
      <!-- Form lista Empoaque -->
      <packing-list v-if="tpl === 'packing'" :data="data"/>
      <!-- Form Control de Inventario -->
      <form-control-inventario v-if="tpl === 'control-inventario'" :data="data"/>
      <!-- Form Picking -->
      <form-picking v-if="tpl === 'picking'" :data="data"/>
      <!-- Form MarsFfood -->
      <form-marsfood v-if="tpl === 'marsfood'" :data="data"/>
    </div>
    <div class="mt-2 icon-finish position-relative">
      <img :src="getImgUrl(icon)" alt="">
      <span class="i-check"><i class="far fa-check-circle fa-2x"></i></span>
    </div>
    <div class="mt-4" v-if="btnAdd">
      <!-- Botón de agregar Plan de produccion-->
      <b-button v-if="tpl === 'plan-produccion' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" id="addPlanProduccion" ref="buttonPlan" @click="addPlanProduccion()">Agregar</b-button>
      <b-button v-if="tpl === 'plan-produccion' && editReferenciaPlan == true" class="btn-action-page py-2" size="lg" ref="updatePlan" @click="updatePlanProduccion()">Actualizar</b-button>
      <b-button v-if="tpl === 'task' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" ref="buttonPlan" @click="addPlan()">Agregar</b-button>
      <b-button v-if="tpl === 'task' && editReferenciaPlan == true" class="btn-action-page py-2" size="lg" @click="updatePlan(); $emit('closeBlock', false )">Actualizar</b-button>
      <b-button v-if="tpl == 'team' && editLine == false" class="btn-action-page py-2" size="lg" @click="aperturaLinea()" :disabled="disabledBtnAdd">Agregar</b-button>
      <b-button v-if="tpl == 'team' && editLine == true" class="btn-action-page py-2" size="lg" @click="updatelineTeam()" id="update_team" :disabled="disabledBtnUpdate">Actualizar</b-button>
      <br v-if="tpl == 'team' && disabledBtnUpdate">
      <div v-if="tpl == 'team' && disabledBtnUpdate" class="text-center" style="color: #03185A;">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <b-button ref="addTrack" v-if="tpl == 'track' && getUpdateTrack == false" class="btn-action-page py-2" size="lg" @click="addTrack()">Agregar</b-button>
      <b-button v-if="tpl == 'track' && getUpdateTrack == true" class="btn-action-page py-2" size="lg" @click="updateTrack()">Actualizar</b-button>
      <b-button v-if="statusQualityModule === true && tpl === 'activity' && !getActivateSelectedTab() && getCurrentActivity() === closingQualityControl" class="btn-action-page py-2" size="lg" @click="startClear()">Comenzar cierre</b-button>
      <b-button v-if="statusQualityModule === true && tpl === 'activity' && !getActivateSelectedTab() && (getCurrentActivity() === creationContinueQualityControl)" class="btn-action-page py-2" size="lg" @click="addTraceability(getCurrentActivity())">Agregar trazabilidad</b-button>
      <b-button v-if="statusQualityModule === true && tpl === 'activity' && getActivateSelectedTab() > 0 && (getCurrentActivity() === creationContinueQualityControl)" class="btn-action-page py-2" size="lg" @click="addTraceabilityExecute(getCurrentActivity())">Confirmar</b-button>
      <b-button v-if="tpl === 'quality' && rolAdmin == true && verificateProcessBtn == true" class="btn-action-page py-2" size="lg" @click="confirmVerificateQualityControl()">{{ messageVerificateProcessBtn }}</b-button>
      <div v-if="tpl === 'quality' && verificateProcessDiv == true" class="div-action-page form-control py-2 font-weight-bold" size="lg">{{ messageVerificateProcessBtn }}</div>
      <!-- Botón de agregar en Orden de producción -->
      <b-button v-if="tpl === 'order' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" ref="buttonPlan" @click="addPlan()">Agregar</b-button>
      <!-- Botón de agregar en la instancia BSN -->
      <b-button v-if="tpl === 'bsn' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" id="addPlanForBSN" ref="buttonPlan" @click="addPlanForBSN()">Agregar</b-button>
      <b-button v-if="tpl === 'bsn' && editReferenciaPlan == true" class="btn-action-page py-2" size="lg" @click="updatePlanForBsn(); $emit('closeBlock', false )">Actualizar</b-button>
      <!-- Botón de agregar purchase -->
      <b-button v-if="tpl === 'purchase' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" id="buttonPurchase" @click="addPurchase()">Agregar</b-button>
      <b-button v-if="tpl === 'purchase' && editReferenciaPlan == true" class="btn-action-page py-2" size="lg" @click="updatePlanForBsn(); $emit('closeBlock', false )">Actualizar</b-button>
      <!-- Botón de agregar remission -->
      <b-button v-if="tpl === 'remission' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" id="buttonDispatch" @click="addDispatchNote()">Agregar</b-button>
      <b-button v-if="tpl === 'remission' && editReferenciaPlan == true" class="btn-action-page py-2" size="lg" id="buttonDispatchedit" @click="editRemission()">Actualizar</b-button>
      <!-- Botón de agregar packing list -->
      <b-button v-if="tpl === 'packing' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" id="buttonPacking" @click="addPackingList()">Agregar</b-button>
      <b-button v-if="tpl === 'packing' && editReferenciaPlan == true" class="btn-action-page py-2" size="lg" @click="updatePlanForBsn(); $emit('closeBlock', false )">Actualizar</b-button>
      <!-- Botón de agregar control de inventario -->
      <b-button v-if="tpl === 'control-inventario' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" id="controlInventario" ref="buttonInventory" @click="addInventoryControl()">Agregar</b-button>
      <!-- Botón de agregar picking -->
      <b-button v-if="tpl === 'picking' && editReferenciaPlan == false" class="btn-action-page py-2" size="lg" id="picking" ref="buttonPicking" @click="addPicking()">Agregar</b-button>
    </div>
  </div>
</template>

<script>
import FormPlanProduccion from '@/components/FormPlanProduccion.vue'
import FormAperturaLinea from '@/components/FormAperturaLinea.vue'
import FormPlanProduccionFamilia from '@/components/FormPlanProduccionFamilia.vue'
import FormTrazabilidad from '@/components/FormTrazabilidad.vue'
import FormActividad from '@/components/FormActividad.vue'
import FormControlCalidad from '@/components/FormControlCalidad.vue'
import FormBsn from '@/components/FormBsn.vue'
import FormOrdenProduccion from '@/components/FormOrdenProduccion.vue'
import FormPurchaseOrder from '@/components/FormPurchaseOrder.vue'
import RemissionOrder from '@/components/FormNotaDespacho.vue'
import PackingList from '@/components/FormListaEmpaque.vue'
import FormControlInventario from '@/components/FormControlInventario.vue'
import FormPicking from '@/components/FormPicking.vue'
import FormMarsfood from '@/components/FormMarsfood.vue'

const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'SideBlock',
  props: {
    icon: String,
    data: {
      type: Array,
      default: undefined
    },
    dataQuality: {
      type: Object,
      default: undefined
    },
    urlDataSheet: {
      type: String,
      default: undefined
    },
    tpl: String,
    messageVerificateProcess: String,
    messageVerificateProcessBtn: String,
    verificateProcessBtn: Boolean,
    verificateProcessDiv: Boolean,
    rolAdmin: Boolean,
    btnAdd: {
      type: Boolean,
      default: true
    },
    btnBack: {
      type: Boolean,
      default: false
    },
    url: String
  },
  components: {
    FormPlanProduccion,
    FormAperturaLinea,
    FormPlanProduccionFamilia,
    FormTrazabilidad,
    FormActividad,
    FormControlCalidad,
    FormBsn,
    FormOrdenProduccion,
    FormPurchaseOrder,
    RemissionOrder,
    PackingList,
    FormControlInventario,
    FormPicking,
    FormMarsfood
  },
  data () {
    return {
      disabledBtnAdd: false,
      disabledBtnUpdate: false,
      fileU: '',
      selected: null,
      options: [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '2', text: '3' }
      ],
      filterUser: '',
      activeSearchLeder: false,
      tabSideBlock: 0,
      test1: 4
    }
  },
  mounted () {
    // this.$store.dispatch('atLeastQualityRecordFn')
  },

  computed: {
    statusQualityModule: {
      set (value) {
        this.$store.state.storeQualityModule.statusQualityModule = value
      },
      get () {
        return this.$store.state.storeQualityModule.statusQualityModule
      }
    },
    creationContinueQualityControl: {
      set (value) {
        this.$store.state.storeQualityModule.creationContinueQualityControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.creationContinueQualityControl
      }
    },
    actionProcessControl: {
      set (value) {
        this.$store.state.storeQualityModule.actionProcessControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.actionProcessControl
      }
    },
    closingQualityControl: {
      set (value) {
        this.$store.state.storeQualityModule.closingQualityControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.closingQualityControl
      }
    },
    actionSamplingProcessControl: {
      set (value) {
        this.$store.state.storeQualityModule.actionSamplingProcessControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.actionSamplingProcessControl
      }
    },
    statusDisplayQuantityBox: {
      set (value) {
        this.$store.state.storeProduction.statusDisplayQuantityBox = value
      },
      get () {
        return this.$store.state.storeProduction.statusDisplayQuantityBox
      }
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    statusLogisticBatch () {
      return this.$store.state.storeTrack.statusLogisticBatch
    },
    lenLogisticBatch () {
      return this.$store.state.storeTrack.lenLogisticBatch
    },
    getStatusBtnTrack () {
      return this.$store.state.storeTrack.statusTextBtn
    },
    editReferenciaPlan () {
      return this.$store.state.editarReferenciaPlan
    },
    editLine () {
      return this.$store.state.storeLine.updateLine
    },
    getUpdateTrack () {
      return this.$store.state.storeTrack.updateTrack
    },
    purchaseOrder: {
      get () {
        return this.$store.state.storeTrack.purchaseOrder
      },
      set (value) {
        this.$store.state.storeTrack.purchaseOrder = value
      }
    },
    startRemission: {
      get () {
        return this.$store.state.storeTrack.startRemission
      },
      set (value) {
        this.$store.state.storeTrack.startRemission = value
      }
    },
    monthOp: {
      get () {
        return this.$store.state.storeTrack.monthOp
      },
      set (value) {
        this.$store.state.storeTrack.monthOp = value
      }
    },
    yearOp: {
      get () {
        return this.$store.state.storeTrack.yearOp
      },
      set (value) {
        this.$store.state.storeTrack.yearOp = value
      }
    },
    monthOptions: {
      get () {
        return this.$store.state.storeTrack.monthOptions
      },
      set (value) {
        this.$store.state.storeTrack.monthOptions = value
      }
    },
    yearOptions: {
      get () {
        return this.$store.state.storeTrack.yearOptions
      },
      set (value) {
        this.$store.state.storeTrack.yearOptions = value
      }
    },
    dataPicking: {
      get () {
        return this.$store.state.storeTrack.dataPicking
      },
      set (value) {
        this.$store.state.storeTrack.dataPicking = value
      }
    },
    dataControlInventory: {
      get () {
        return this.$store.state.storeTrack.dataControlInventory
      },
      set (value) {
        this.$store.state.storeTrack.dataControlInventory = value
      }
    },
    dataOrderStatus: {
      get () {
        return this.$store.state.storeTrack.dataOrderStatus
      },
      set (value) {
        this.$store.state.storeTrack.dataOrderStatus = value
      }
    },
    dataTrack: {
      get () {
        return this.$store.state.storeTrack.dataTrack
      },
      set (value) {
        this.$store.state.storeTrack.dataTrack = value
      }
    },
    postTabsArray: {
      set (value) {
        this.$store.state.storeInventory.postTabsArray = value
      },
      get () {
        return this.$store.state.storeInventory.postTabsArray
      }
    },
    mandatorySupplyProductionPlan: {
      set (value) {
        this.$store.state.storeQualityModule.mandatorySupplyProductionPlan = value
      },
      get () {
        return this.$store.state.storeQualityModule.mandatorySupplyProductionPlan
      }
    },
    supplyProcessingStatus: {
      set (value) {
        this.$store.state.storeQualityModule.supplyProcessingStatus = value
      },
      get () {
        return this.$store.state.storeQualityModule.supplyProcessingStatus
      }
    }
  },
  methods: {
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    getActivateSelectedTab () {
      return this.$store.state.storeActivity.actividadFormTabs
    },
    getCurrentActivity () {
      return this.$store.state.storeActivity.current_activity
    },
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    async addTrack () {
      const self = this
      const selectedOP = self.$store.state.storeTrack.selectedOP
      const selectedLote = self.$store.state.storeTrack.selectedLote
      var arrayData = []
      var trazId = null
      var selectedRef = this.$store.state.storeTrack.selectedRef
      var fileNotUploaded = false
      var updateTrack = this.$store.state.storeTrack.updateTrack
      var emptyFields = false
      var badPA = false
      var julianDay = this.$store.state.storeTrack.julianDay
      var refIsSelected = (selectedRef != null)
      if (refIsSelected) {
        this.$store.state.storeTrack.bases.bases.forEach(function (item, index) {
          arrayData.push({ base: item.refBase, lote: self.$store.state.storeTrack.input.lote[index], loteLogistico: self.$store.state.storeTrack.input.loteLogistico[index], ordenProduccion: self.$store.state.storeTrack.input.ordenProduction[index], julian_day: self.$store.state.storeTrack.input.julian_day[index] })
          if (self.$store.state.storeTrack.input.lote[index] == null || self.$store.state.storeTrack.input.lote[index] === '' || self.$store.state.storeTrack.input.loteLogistico[index] == null || self.$store.state.storeTrack.input.loteLogistico[index] === '' || self.$store.state.storeTrack.input.ordenProduction[index] === '' || self.$store.state.storeTrack.input.ordenProduction[index] == null || self.$store.state.storeTrack.input.julian_day[index] === undefined) {
            emptyFields = true
          }
          if (self.statusLogisticBatch) {
            if (self.$store.state.storeTrack.input.loteLogistico[index] != null) {
              if (self.$store.state.storeTrack.input.loteLogistico[index].length !== self.lenLogisticBatch) {
                badPA = true
              }
            } else {
              badPA = true
            }
          }
        })
        this.$store.state.storeTrack.bases.bases.forEach(function (item, index) {
          var fotoEvidencia = document.getElementById('file-input-' + index).files[0]
          if (fotoEvidencia == null) {
            fileNotUploaded = true
          }
        })
      }
      if (!refIsSelected) {
        this.$bvToast.toast('No has seleccionado una referencia.', {
          title: 'Referencia no seleccionada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (selectedOP == null) {
        this.$bvToast.toast('No has seleccionado Orden de producción.', {
          title: 'Orden de producción no seleccionada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (selectedLote == null) {
        this.$bvToast.toast('No has seleccionado Lote.', {
          title: 'Lote no seleccionado',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (fileNotUploaded && !updateTrack) {
        this.$bvToast.toast('Faltan evidencias por subir.', {
          title: 'Evidencias sin subir',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (badPA) {
        this.$bvToast.toast('El formato del lote logístico es incorrecto.', {
          title: 'Lote logístico incorrecto',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (emptyFields) {
        this.$bvToast.toast('Debes completar todos los campos.', {
          title: 'Campos incompletos',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (julianDay === '') {
        this.$bvToast.toast('Debe ingresar un día juliano valido', {
          title: 'Campos incompletos',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        this.$refs.addTrack.setAttribute('disabled', '')
        this.$refs.addTrack.innerText = 'Cargando...'
        await axios.post('/api/addtraceability/', { ref: selectedRef, codeOp: selectedOP, batch: selectedLote, data: arrayData }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          trazId = response.data.traz_id
        }).catch(function () {
          alert('Por favor, selecccione desde el Administrador una base primaria')
        })
        self.dataTrack = []
        axios.post('/api/getTraceabilities/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          response.data.forEach(function (traz, index) {
            var basestrack = []
            traz.trazabilityproduct.forEach(function (bases, index) {
              basestrack.push({ refBase: bases.base_id, loteLogisticoEntrada: bases.entry_logistics_batch, lote: bases.entry_batch, ordenProduccion: bases.entry_production_order, file_url: bases.file, julian_day: bases.julian_day })
            })
            self.dataTrack.push({
              trazId: traz.trazability.id,
              line_id: traz.trazability.line,
              line_name: traz.line_name,
              // ref: traz.trazability.product_code,
              ref: traz.trazability.reference,
              reference_batch: traz.trazability.reference_batch,
              batch: traz.trazability.batch,
              production_order: traz.trazability.production_order,
              product_by_production_id: traz.trazability.productsByProduccionOrder_id,
              url: url + 'media' + (traz.datasheet_url).split('media')[1],
              loteLogisticoSalida: traz.trazability.output_batch,
              fechaVencimiento: traz.trazability.output_due_date,
              finalOrderMaquila: traz.trazability.output_production_order,
              bases: basestrack
            })
            self.loadTable = true
          })
        })
        var lastIndex = self.$store.state.storeTrack.bases.bases.length - 1
        await this.$store.state.storeTrack.bases.bases.forEach(function (item, index) {
          var fotoEvidencia = document.getElementById('file-input-' + index).files[0]
          var data = new FormData()
          data.append('traceability_id', trazId)
          data.append('file', fotoEvidencia)
          data.append('base_id', item.refBase)
          axios.post('/api/uploadBaseFileTraceability/', data, {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') }
          }).then(function (response) {
            if (lastIndex === index) {
              self.$emit('setDataTrack')
            }
          }).catch(function (error) {
            console.log(error)
          })
        })
        this.$refs.addTrack.removeAttribute('disabled')
        this.$refs.addTrack.innerText = 'Agregar'
        this.resetForm()
        this.$store.state.storeTrack.imagesPreview = []
        this.$store.commit('actionSetTrazToAction', false)
      }
    },
    async updatelineTeam () {
      const self = this
      var leaderSelected = (self.$store.state.storeLine.setLeader.id != null)
      this.disabledBtnUpdate = true
      const buttomUpdate = document.getElementById('update_team')
      buttomUpdate.innerText = 'Cargando...'
      if (leaderSelected) {
        await axios.post('/api/updatelineTeam/', {
          line_id: self.$store.state.storeLine.selected,
          team: self.$store.state.storeLine.setTeam,
          leader: self.$store.state.storeLine.setLeader
        }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.disabledBtnUpdate = false
          buttomUpdate.innerText = 'Agregar'
        })
        if (this.is_leader()) {
          axios.get('/api/getInfoLine/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            self.$store.commit('actionSetinfoUsers', response.data)
          })
        } else {
          axios.get('/api/getInfoLineAdmin/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            self.$store.commit('actionSetinfoUsers', response.data)
          })
        }
        this.resetForm()
        this.$bvToast.toast('El equipo se ha actualizado con exito.', {
          title: 'Lider',
          variant: 'success',
          autoHideDelay: 5000,
          solid: true
        })
        this.$store.state.storeLine.simpleUser = []
        axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.state.storeLine.simpleUser = response.data
          // response.data.forEach(function (item, index) {
          //   var nameFull = item.first_name + ' ' + item.last_name
          //   self.$store.state.storeLine.simpleUser.push({
          //     id: item.id, -> idUser
          //     name: nameFull,
          //     avatar: 'https://tinyfac.es/data/avatars/BA0CB1F2-8C79-4376-B13B-DD5FB8772537-200w.jpeg'
          //   })
          // })
        })
        this.$emit('closeBlock', false)
        this.$store.state.storeLine.simpleUser = []
        await axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.state.storeLine.simpleUser = response.data
          // response.data.forEach(function (item, index) {
          //   var nameFull = item.first_name + ' ' + item.last_name
          //   self.$store.state.storeLine.simpleUser.push({
          //     id: item.id,
          //     name: nameFull,
          //     avatar: 'https://tinyfac.es/data/avatars/BA0CB1F2-8C79-4376-B13B-DD5FB8772537-200w.jpeg'
          //   })
          // })
        })
        self.$store.commit('actionSetLineList', [])
        await axios.get('/api/getAvailableLines/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          var auxLineList = []
          response.data.json_lines.forEach(function (item, index) {
            auxLineList.push({ value: item.id, text: item.id })
          })
          self.$store.commit('actionSetLineList', auxLineList)
        })
      } else {
        this.$bvToast.toast('Porfavor seleccione un lider.', {
          title: 'Seleccione un lider',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
        this.disabledBtnUpdate = false
      }
    },
    closeBox () {
      this.$emit('closeBlock', false)
    },
    async refExistInPlan (ref, date) {
      var existRef = false
      await axios.get('/api/refInPlan/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        existRef = false
        response.data.forEach(function (item, index) {
          if (item.product_code === ref && item.workplan_code__date === date) {
            existRef = true
          }
        })
      })
      return existRef
    },
    async addPlan () {
      const self = this
      var ficha = document.getElementById('fileFichaTecnica').files[0]
      var reference = this.$store.state.referenciaPlan
      var cantidad = this.$store.state.cantidadPlan
      var horasPlan = this.$store.state.horasPlan
      var updateForm = this.$store.state.editarReferenciaPlan
      var date = this.$store.state.storeProduction.date
      var refExist = await this.refExistInPlan(reference, date)
      if (refExist) {
        this.$bvToast.toast('La referencia seleccionada ya se encuentra en uno de los planes de producción, porfavor actualicela o subala a el plan de hoy.', {
          title: 'Referencia duplicada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (date === null) {
        this.$bvToast.toast('Debe ingresar la fecha del plan.', {
          title: 'Fecha no ingresada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (ficha === null && !updateForm) {
        this.$bvToast.toast('Porfavor adjunte una ficha técnica.', {
          title: 'Ficha técnica no subida',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (reference === null) {
        this.$bvToast.toast('Debes seleccionar una referencia.', {
          title: 'Referencia',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (cantidad < 1) {
        this.$bvToast.toast('Debes asignar una cantidad', {
          title: 'Cantidad',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (ficha === undefined) {
        this.$bvToast.toast('Debes subir una ficha técnica.', {
          title: 'Ficha Técnica',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        this.$refs.buttonPlan.setAttribute('disabled', '')
        this.$refs.buttonPlan.innerText = 'Cargando...'
        var imagefile = document.getElementById('fileFichaTecnica').files[0]
        var data = new FormData()
        data.append('file', imagefile)
        data.append('date', date)
        data.append('name', imagefile.name)
        data.append('document_type', 'PDF')
        data.append('user_id', sessionStorage.getItem('user_id'))
        data.append('product_code', reference)
        data.append('quantity', cantidad)
        data.append('hours', horasPlan)
        await axios.post('/api/startworkplan/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.Status !== 'error') {
            self.$refs.buttonPlan.removeAttribute('disabled')
          } else {
            this.$bvToast.toast('Error de conexión, prueba nuevamente.', {
              title: 'Error de conexión',
              variant: 'danger',
              autoHideDelay: 5000,
              solid: true
            })
          }
          self.$refs.buttonPlan.innerText = 'Agregar'
        }).catch(function (error) {
          console.log(error)
        })
        await this.$store.dispatch('getInfoProduction')
        this.resetForm()
        if (this.is_leader()) {
          self.$emit('closeBlock', false)
        }
      }
    },
    async addPlanForBSN () {
      const self = this
      var batch = this.$store.state.storeProduction.batchList
      var reference = this.$store.state.referenciaPlan
      var codeOp = this.$store.state.ProductionOrder
      // var lengthOp = this.$store.state.storeProduction.length_op
      var codeOc = this.$store.state.ProductionPurchase
      var cantidad = this.$store.state.cantidadPlan
      var horasPlan = this.$store.state.horasPlan
      var date = this.$store.state.storeProduction.date
      var refExist = await this.refExistInPlan(reference, date)
      var arraySupplies = []
      var emptyFields = null
      // const regex = new RegExp(`^.{${lengthOp}}$`)
      self.$store.state.storeProduction.insumoBsn.forEach(function (item, index) {
        if (item.quantity === undefined || self.$store.state.storeTrack.inputBsn.quantity[index] === '') {
          emptyFields = true
        } else {
          arraySupplies.push({ id: item.id, quantity: item.quantity, batch: self.$store.state.storeTrack.inputBsn.batch[index] ? self.$store.state.storeTrack.inputBsn.batch[index] : '' })
          emptyFields = false
        }
      })
      if (codeOp === null) {
        this.$bvToast.toast('Por favor, ingrese un numero de orden de produccion', {
          title: 'Número de OP',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      // } else if (lengthOp !== 0 && !regex.test(codeOp)) {
      //   this.$bvToast.toast(`Por favor, La OP debe tener ${lengthOp} caracteres.`, {
      //     title: 'Longitud de OP',
      //     variant: 'warning',
      //     autoHideDelay: 5000,
      //     solid: true
      //   })
      } else if (refExist) {
        this.$bvToast.toast('La referencia seleccionada ya se encuentra en uno de los planes de producción, porfavor actualicela o subala a el plan de hoy.', {
          title: 'Referencia duplicada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (emptyFields === true) {
        this.$bvToast.toast('Los campos de cantidad de los insumos son obligatorios.', {
          title: 'Campos incompletos de cantidad',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (date === null) {
        this.$bvToast.toast('Debe ingresar la fecha del plan.', {
          title: 'Fecha no ingresada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (reference === null) {
        this.$bvToast.toast('Debes seleccionar un código del producto.', {
          title: 'Código producto',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (cantidad < 1) {
        this.$bvToast.toast('Debes asignar una cantidad', {
          title: 'Cantidad',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (batch.length === 0) {
        this.$bvToast.toast('Ingresa al menos un lote con 8 dígitos con la cantidad separada por un guión presionando ENTER', {
          title: 'Lote',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        var imagefile = document.getElementById('fileFichaTecnica').files[0]
        var data = new FormData()
        data.append('file', imagefile)
        data.append('date', date)
        data.append('document_type', 'PDF')
        data.append('user_id', sessionStorage.getItem('user_id'))
        data.append('product_code', reference)
        data.append('quantity', cantidad)
        data.append('hours', horasPlan)
        data.append('code_op', codeOp)
        data.append('batch', this.$store.state.storeProduction.batchList)
        data.append('code_oc', codeOc)
        data.append('supplies_list', JSON.stringify(arraySupplies))
        await axios.post('/api/startworkplan/', data, {
          headers: { Authorization: 'token ' + sessionStorage.getItem('token') }
        }).then(function (response) {
          if (response.data.Status === 'Success with Work Plan.') {
            self.$refs.buttonPlan.removeAttribute('disabled')
            self.announceToast('Éxito', 'Se agrego correctamente.', 2000, 'success')
            self.$refs.buttonPlan.innerText = 'Agregar'
            localStorage.removeItem('Batchs')
          }
        }).catch(function (error) {
          if (error.response.data.Status === 'The production order already exists, enter one that does not exist') {
            self.$refs.buttonPlan.removeAttribute('disabled')
            self.$refs.buttonPlan.innerText = 'Agregar'
            self.announceToast('Número de OP', 'El numero de orden de produccion ya existe', 5000, 'danger')
            localStorage.removeItem('Batchs')
          }
        })
        localStorage.removeItem('Batchs')
        await this.$store.dispatch('getInfoProduction')
        this.resetForm()
        this.$store.state.storeProduction.batchList = []
        this.$store.state.storeTrack.inputBsn.quantity = []
        this.$store.state.storeTrack.inputBsn.batch = []
        this.$store.state.ProductionOrder = ''
        this.$store.state.ProductionPurchase = ''
        document.getElementById('codeOp').value = ''
        document.getElementById('tags').innerHTML = ''
        if (this.is_leader()) {
          self.$emit('closeBlock', false)
        }
      }
    },
    async validAddPlanProduction () {
      const codeOp = this.$store.state.dataPlanProduccion.op
      const date = this.$store.state.dataPlanProduccion.date
      let continueProcess = true
      if (codeOp === null || codeOp.trim() === '') {
        this.$bvToast.toast('Por favor, ingrese un numero de orden de produccion', {
          title: 'Número de OP',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
        continueProcess = false
      }
      if (date === null || date.trim() === '') {
        this.$bvToast.toast('Debe ingresar la fecha del plan.', {
          title: 'Fecha no ingresada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
        continueProcess = false
      }
      const rowsPlanProduction = this.$store.state.dataPlanProduccion.rowsPlanProduccion
      let invalidQuantityItems = ''
      let invalidBatchProduct = ''
      let invalidQuantityProduct = ''
      for (let index = 0; index < rowsPlanProduction.length; index++) {
        const product = rowsPlanProduction[index]
        if (!product.cantidad) {
          invalidQuantityProduct += `Producto con referencia ${product.codigo.ref}. `
        }
        if (!product.lote) {
          invalidBatchProduct += `Producto con referencia ${product.codigo.ref}. `
        }
        if (this.supplyProcessingStatus && this.mandatorySupplyProductionPlan) {
          for (let supplyIndex = 0; supplyIndex < product.suppliesList.length; supplyIndex++) {
            const supply = product.suppliesList[supplyIndex]
            if (!supply.quantity || supply.quantity === '0') {
              invalidQuantityItems += `Producto con referencia ${product.codigo.ref} - insumo: ${supply.id_supply}. `
            }
          }
        }
      }
      if (invalidQuantityProduct.length > 0) {
        this.announceToast('Cantidad de productos', `Faltan las cantidades de los productos en: ${invalidQuantityProduct}`, 25000, 'danger')
        continueProcess = false
      }
      if (invalidBatchProduct.length > 0) {
        this.announceToast('Lotes de productos', `Faltan los lotes de los productos en: ${invalidBatchProduct}`, 25000, 'danger')
        continueProcess = false
      }
      if (invalidQuantityItems.length > 0) {
        this.announceToast('Insumos', `Faltan las cantidades de los insumos en: ${invalidQuantityItems}`, 25000, 'danger')
        continueProcess = false
      }
      return continueProcess
    },
    async addPlanProduccion () {
      const codeOp = this.$store.state.dataPlanProduccion.op
      const date = this.$store.state.dataPlanProduccion.date
      const imagefile = document.getElementById('fileFichaTecnica').files[0]
      const continueProcess = await this.validAddPlanProduction()
      if (continueProcess) {
        const data = new FormData()
        data.append('file', imagefile)
        data.append('date', date.trim())
        data.append('document_type', 'PDF')
        data.append('user_id', sessionStorage.getItem('user_id'))
        data.append('code_op', codeOp.trim())
        this.$store.state.dataPlanProduccion.rowsPlanProduccion.forEach(obj => {
          data.append('references_list', JSON.stringify(obj))
          data.append('fichas', obj.ficha)
        })
        this.$refs.buttonPlan.setAttribute('disabled', '')
        this.$refs.buttonPlan.innerText = 'Cargando...'
        await axios.post('/api/startworkplan/', data, {
          headers: { Authorization: 'token ' + sessionStorage.getItem('token'), 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach((error) => {
              this.announceToast(error.title, error.content, 7000, 'warning')
              this.$refs.buttonPlan.removeAttribute('disabled')
              this.$refs.buttonPlan.innerText = 'Agregar'
            })
          } else {
            this.$refs.buttonPlan.removeAttribute('disabled')
            this.$refs.buttonPlan.innerText = 'Agregar'
            this.announceToast('Éxito', 'Se agrego correctamente.', 2000, 'success')
            this.$store.state.dataPlanProduccion = { op: '', date: '', rowsPlanProduccion: [{ codigo: '', descripcion: '', cantidad: '', lote: '', horas: 0, ficha: null, fichaName: '', suppliesObject: {}, suppliesList: [], basesCount: 0, suppliesCount: 0 }], indexRowSupplyPlanProduction: 0 }
            document.getElementById('FichaTecnicaReferencia0').value = ''
            this.$store.state.filereferencesStatus = [false]
            document.getElementById('fileFichaTecnica').value = ''
            this.$store.state.storeProduction.fichaTecnica = ''
            this.$store.dispatch('getInfoProduction')
          }
        }).catch((error) => {
          console.error(error)
          this.$refs.buttonPlan.removeAttribute('disabled')
          this.$refs.buttonPlan.innerText = 'Agregar'
        })
      }
    },
    async updatePlanProduccion () {
      var codeOp = this.$store.state.dataPlanProduccion.op
      var date = this.$store.state.dataPlanProduccion.date
      var imagefile = document.getElementById('fileFichaTecnica').files[0]
      var rowsReferences = this.$store.state.dataPlanProduccion.rowsPlanProduccion
      this.$refs.updatePlan.setAttribute('disabled', '')
      this.$refs.updatePlan.innerText = 'Cargando...'
      var data = new FormData()
      data.append('date', date)
      data.append('file', imagefile)
      data.append('code_op', codeOp)
      data.append('references_list', JSON.stringify(rowsReferences))
      await axios.post('/api/updateproductplan/', data, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') }
      }).then((response) => {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach((error) => {
            this.announceToast(error.title, error.content, 7000, 'warning')
            this.$refs.updatePlan.removeAttribute('disabled')
            this.$refs.updatePlan.innerText = 'Agregar'
          })
        } else {
          this.announceToast('Éxito', 'Se actualizo correctamente.', 2000, 'success')
          this.$refs.updatePlan.removeAttribute('disabled')
          this.$refs.updatePlan.innerText = 'Agregar'
          this.$store.dispatch('getInfoProduction')
          this.$emit('closeBlock', false)
        }
      }).catch((error) => {
        console.error(error)
        this.$refs.updatePlan.removeAttribute('disabled')
        this.$refs.updatePlan.innerText = 'Agregar'
        this.announceToast('Advertencia', 'Algo salió mal. Por favor, vuelva a intentarlo.', 2000, 'warning')
      })
    },
    async addPurchase () {
      const self = this
      let dataSend = {}
      var id = this.$store.state.storeProduction.idrefbyop
      var codeOp = this.$store.state.storeInventory.opForSideBlock
      var cantidad = this.$store.state.cantidadPlan
      var codeOc = this.$store.state.ProductionPurchase
      var date = this.$store.state.storeProduction.date
      var reference = this.$store.state.storeProduction.referenciaOc
      if (!codeOp?.code && codeOp?.code === '') {
        this.$bvToast.toast('Debes ingresar un código de OP', {
          title: 'Código OP',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (codeOc === null || codeOc === '') {
        this.$bvToast.toast('Debes ingresar un código de OC', {
          title: 'Código OC',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (date === null) {
        this.$bvToast.toast('Debe ingresar la fecha del plan.', {
          title: 'Fecha no ingresada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (reference === '') {
        this.$bvToast.toast('Debes seleccionar una referencia.', {
          title: 'Referencia',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (cantidad < 1) {
        this.$bvToast.toast('Debes asignar una cantidad', {
          title: 'Cantidad',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        var imagefile = document.getElementById('fileFichaTecnica').files[0]
        if (imagefile === undefined) {
          this.fileU = ''
        } else {
          this.fileU = imagefile
        }
        if (typeof codeOc === 'string') {
          dataSend = {
            code_oc: codeOc,
            code_op: codeOp,
            date: date,
            product_code: reference,
            quantity: cantidad,
            id: id,
            file: this.fileU
          }
        }
        if (typeof codeOc === 'object') {
          dataSend = {
            code_oc: codeOc?.code,
            code_op: codeOp,
            date: date,
            product_code: reference,
            quantity: cantidad,
            id: id,
            file: this.fileU
          }
        }
        await axios.post('/api_orders/StartProductionOrderaPurchase/', dataSend, {
          validateStatus: function (status) {
            return status < 500
          },
          headers: { Authorization: 'token' + sessionStorage.getItem('token') }
        }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            document.getElementById('buttonPurchase').removeAttribute('disabled')
            document.getElementById('buttonPurchase').innerText = 'Agregar'
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, error.time, 'warning')
            })
          } else {
            self.announceToast('Éxito', 'Se agrego correctamente.', 2000, 'success')
            document.getElementById('buttonPurchase').removeAttribute('disabled')
            document.getElementById('buttonPurchase').innerText = 'Agregar'
            self.$store.dispatch('getInfoProduction')
            self.dataOrderStatus = []
            axios.get('/api_orders/getorders/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
              self.dataOrderStatus = response.data.data
            })
            self.$store.state.storeInventory.opForSideBlock = ''
            self.$store.state.ProductionPurchase = ''
            self.$store.state.cantidadPlan = ''
            self.$store.state.storeProduction.referencia = ''
            self.$store.state.storeProduction.fichaTecnica = ''
            self.$emit('closeBlock', false)
          }
        })
        this.resetForm()
        if (this.is_leader()) {
          self.$emit('closeBlock', false)
        }
      }
    },
    async addDispatchNote () {
      var codeOc = this.$store.state.ProductionPurchase
      var codeOp = this.$store.state.storeProduction.codeSelect
      var referencia = this.$store.state.referenciaRemision
      var batch = this.$store.state.productionBatch
      var cantidad = this.$store.state.cantidadPlan
      var codeSiesa = this.$store.state.codeSiesa
      var dispatchStatus = this.$store.state.storeProduction.dispatchStatus.value
      var packingsLists = this.$store.state.storeProduction.packingsLists
      document.getElementById('buttonDispatch').setAttribute('disabled', '')
      document.getElementById('buttonDispatch').innerText = 'Cargando...'
      const dataSend = {
        codeOp: codeOp,
        quantity: cantidad,
        referencia: referencia,
        code_siesa: codeSiesa,
        status: dispatchStatus,
        codeOc: codeOc,
        batch: batch,
        packingList: packingsLists
      }
      const self = this
      await axios.post('/api_orders/CreateRemission/', dataSend, { headers: { Authorization: 'token' + sessionStorage.getItem('token') } }).then((response) => {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach(function (error) {
            document.getElementById('buttonDispatch').removeAttribute('disabled')
            document.getElementById('buttonDispatch').innerText = 'Agregar'
            self.announceToast('Error', error.title, error.time, 'danger')
          })
        } else {
          document.getElementById('buttonDispatch').removeAttribute('disabled')
          this.announceToast('Éxito', 'Se agrego correctamente.', 2000, 'success')
          document.getElementById('buttonDispatch').innerText = 'Agregar'
          this.resetForm()
          this.$store.state.storeProduction.packingsLists = null
          this.$store.state.storeProduction.codeSelect = null
          this.$store.state.referenciaRemision = null
          this.$store.state.ProductionPurchase = ''
          this.$store.state.storeProduction.dispatchStatus = { value: null, text: '' }
          this.$store.state.productionBatch = ''
          this.$store.state.cantidadPlan = ''
          this.$store.state.codeSiesa = ''
          this.$store.dispatch('getInfoProduction')
          self.startRemission = []
          axios.get('/api_orders/StartRemission/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            self.startRemission = response.data.data
            self.monthOp = response.data.month
            self.yearOp = response.data.year
            self.monthOptions = response.data.monthOptions
            self.yearOptions = response.data.yearOptions
          })
          self.$emit('closeBlock', false)
        }
      }).catch(function (error) {
        document.getElementById('buttonDispatch').removeAttribute('disabled')
        document.getElementById('buttonDispatch').innerText = 'Agregar'
        self.announceToast(error, 'Campos vacíos. Por favor, vuelva a intentarlo.', 5000, 'danger')
      })
    },
    async addPackingList () {
      const self = this
      var observations = this.$store.state.storeProduction.observations
      if (observations === null) {
        this.$bvToast.toast('Debes ingresar una observación', {
          title: 'Error en observación',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        document.getElementById('buttonPacking').setAttribute('disabled', '')
        document.getElementById('buttonPacking').innerText = 'Cargando...'
        var data = new FormData()
        data.append('observations', observations)
        data.append('pk', '203')
        await axios.post('/api_orders/EditPackingList/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.Status !== 'error') {
            document.getElementById('buttonPacking').removeAttribute('disabled')
            self.announceToast('Éxito', 'Se agrego correctamente.', 2000, 'success')
          }
          document.getElementById('buttonPacking').innerText = 'Agregar'
        }).catch(function (error) {
          self.announceToast(error, 'Campos vacíos. Por favor, vuelva a intentarlo.', 5000, 'danger')
        })
        await this.$store.dispatch('getInfoProduction')
        this.resetForm()
        document.getElementById('observations').value = ''
        if (this.is_leader()) {
          self.$emit('closeBlock', false)
        }
      }
    },
    async updatePlanForBsn () {
      var imagefile = document.getElementById('fileFichaTecnica').files[0]
      var data = new FormData()
      data.append('date', this.$store.state.storeProduction.dateBsn)
      data.append('file', imagefile)
      data.append('quantity', this.$store.state.cantidadPlan)
      data.append('hours', this.$store.state.horasPlan)
      data.append('reference', this.$store.state.referenciaPlan)
      data.append('code_op', this.$store.state.ProductionOrder)
      data.append('batchs', this.$store.state.productionBatch)
      data.append('code_oc', this.$store.state.ProductionPurchase)
      await axios.post('/api/updateproductplan/', data, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') }
      }).then(function (response) {
        console.log(response)
      }).catch(function () {
        self.announceToast('Advertencia', 'Algo salió mal. Por favor, vuelva a intentarlo.', 2000, 'warning')
      })
      this.$store.dispatch('getInfoProduction')
      this.resetForms()
    },
    resetForms: function () {
      const self = this
      if (this.tpl === 'bsn') {
        self.$store.commit('actionSetReferencia', {})
        this.$store.commit('actionSetOP', '')
        this.$store.commit('actionSetBases', [])
        this.$store.commit('actionSetInsumos', [])
        this.$store.commit('actionSetLotes', [])
        this.$store.commit('actionSetHoras', 0)
        this.$store.commit('actionSetCantidad', 0)
        this.$store.commit('actionSetFichaTecnica', '')
        this.$store.commit('actionSetEditarReferenciaPlan', false)
        this.$store.state.storeProduction.date = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        document.getElementById('fileFichaTecnica').value = ''
      }
      if (this.tpl === 'purchase') {
        self.$store.commit('actionSetReferencia', {})
        this.$store.commit('actionSetOP', '')
        this.$store.commit('actionSetOC', '')
        this.$store.commit('actionSetBases', [])
        this.$store.commit('actionSetInsumos', [])
        this.$store.commit('actionSetLotes', [])
        this.$store.commit('actionSetHoras', 0)
        this.$store.commit('actionSetCantidad', 0)
        this.$store.commit('actionSetFichaTecnica', '')
        this.$store.commit('actionSetEditarReferenciaPlan', false)
        this.$store.state.storeProduction.date = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        document.getElementById('fileFichaTecnica').value = ''
      }
      if (this.tpl === 'plan-produccion') {
        this.$store.state.dataPlanProduccion.op = null
        this.$store.state.dataPlanProduccion.date = null
        this.$store.state.dataPlanProduccion = { op: null, date: null, rowsPlanProduccion: [{ codigo: '', descripcion: '', cantidad: '', lote: '', horas: 0, ficha: null, fichaName: '', suppliesObject: {}, suppliesList: [], basesCount: 0, suppliesCount: 0 }], indexRowSupplyPlanProduction: 0 }
        document.getElementById('fileFichaTecnica').value = ''
        this.$store.state.storeProduction.fichaTecnica = ''
      }
      if (this.tpl === 'remission') {
        self.$store.commit('actionSetReferencia', {})
        this.$store.commit('actionSetOP', '')
        this.$store.commit('actionSetOC', '')
        this.$store.commit('actionSetBases', [])
        this.$store.commit('actionSetInsumos', [])
        this.$store.commit('actionSetLotes', [])
        this.$store.commit('actionSetHoras', 0)
        this.$store.commit('actionSetCantidad', 0)
        this.$store.commit('actionSetFichaTecnica', '')
        this.$store.commit('actionSetEditarReferenciaPlan', false)
        this.$store.state.storeProduction.date = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        document.getElementById('fileFichaTecnica').value = ''
      }
      if (this.tpl === 'packing') {
        self.$store.commit('actionSetReferencia', {})
        this.$store.commit('actionSetOP', '')
        this.$store.commit('actionSetOC', '')
        this.$store.commit('actionSetBases', [])
        this.$store.commit('actionSetInsumos', [])
        this.$store.commit('actionSetLotes', [])
        this.$store.commit('actionSetHoras', 0)
        this.$store.commit('actionSetCantidad', 0)
        this.$store.commit('actionSetFichaTecnica', '')
        this.$store.commit('actionSetEditarReferenciaPlan', false)
        this.$store.state.storeProduction.date = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        document.getElementById('fileFichaTecnica').value = ''
      }
    },
    is_leaders () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    async updateTrack () {
      const self = this
      var trazId = this.$store.state.storeTrack.trazToAction
      var selectedLine = this.$store.state.storeTrack.selectedLine
      // var ref = this.$store.state.storeTrack.selectedRef
      var julianDay = this.$store.state.storeTrack.julianDay
      let selectedRef = null
      let selectedOP = null
      let selectedLote = null
      var arrayData = []
      await this.$store.state.storeTrack.dataTrack.forEach(function (item, index) {
        if (item.trazId === trazId) {
          item.bases.forEach(function (item, index) {
            arrayData.push({ base: item.refBase, lote: self.$store.state.storeTrack.input.lote[index], loteLogistico: self.$store.state.storeTrack.input.loteLogistico[index], ordenProduccion: self.$store.state.storeTrack.input.ordenProduction[index], julian_day: self.$store.state.storeTrack.input.julian_day[index] })
          })
          selectedRef = item.ref
          selectedOP = item.production_order
          selectedLote = item.batch
        }
      })
      var traz = null
      var data = { codeOp: selectedOP, batch: selectedLote, ref: selectedRef, data: arrayData, trazId: trazId, line: selectedLine, julian_day: julianDay }
      await axios.post('/api/updateTraceability/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        traz = response.data.trazability
      })
      await this.$store.state.storeTrack.bases.bases.forEach(function (item, index) {
        var fotoEvidencia = document.getElementById('file-input-' + index).files[0]
        if (fotoEvidencia != null) {
          var data = new FormData()
          data.append('traceability_id', traz)
          data.append('file', fotoEvidencia)
          data.append('base_id', item.refBase)
          axios.post('/api/uploadBaseFileTraceability/', data, {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') }
          }).then(function (response) {
            console.log(response)
          }).catch(function (error) {
            console.log(error)
          })
        }
      })
      this.resetForm()
      this.$emit('closeBlock', false)
      this.$root.$emit('setDataTrack')
    },
    existRef () {
      var exist = false
      var selectedRef = this.$store.state.storeProduction.referencia
      this.$store.state.storeProduction.infoProduction.data.forEach(function (item, index) {
        if (selectedRef.ref === item.reference) {
          exist = true
        }
      })
      return exist
    },
    async updatePlan () {
      var date = this.$store.state.storeProduction.date
      var imagefile = document.getElementById('fileFichaTecnica').files[0]
      var data = new FormData()
      data.append('date', date)
      data.append('file', imagefile)
      data.append('quantity', this.$store.state.cantidadPlan)
      data.append('hours', this.$store.state.horasPlan)
      data.append('reference', this.$store.state.storeProduction.referencia.ref)
      await axios.post('/api/updateproductplan/', data, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') }
      }).then(function (response) {
        console.log(response)
      }).catch(function (error) {
        console.log(error)
      })
      this.$store.dispatch('getInfoProduction')
      this.resetForm()
    },
    resetForm: function () {
      const self = this
      if (this.tpl === 'task') {
        this.$store.commit('actionSetReferencia', {})
        this.$store.commit('actionSetBases', [])
        this.$store.commit('actionSetInsumos', [])
        this.$store.commit('actionSetLotes', [])
        this.$store.commit('actionSetHoras', 0)
        this.$store.commit('actionSetCantidad', 0)
        this.$store.commit('actionSetOP', '')
        this.$store.commit('actionSetFichaTecnica', '')
        this.$store.commit('actionSetEditarReferenciaPlan', false)
        this.$store.state.storeProduction.date = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        document.getElementById('fileFichaTecnica').value = ''
      }
      if (this.tpl === 'plan-produccion') {
        this.$store.state.dataPlanProduccion = { op: '', date: '', file: null, rowsPlanProduccion: [{ codigo: '', descripcion: '', cantidad: '', lote: '', horas: 0, ficha: null, fichaName: '', suppliesObject: {}, suppliesList: [], basesCount: 0, suppliesCount: 0 }], indexRowSupplyPlanProduction: 0 }
        document.getElementById('fileFichaTecnica').value = ''
        this.$store.state.storeProduction.fichaTecnica = ''
      }
      if (this.tpl === 'bsn') {
        this.$store.commit('actionSetReferencia', {})
        this.$store.commit('actionSetOP', '')
        this.$store.commit('actionSetBases', [])
        this.$store.commit('actionSetInsumos', [])
        this.$store.commit('actionSetLotes', [])
        this.$store.commit('actionSetHoras', 0)
        this.$store.commit('actionSetCantidad', 0)
        this.$store.commit('actionSetFichaTecnica', '')
        this.$store.commit('actionSetEditarReferenciaPlan', false)
        this.$store.state.storeProduction.date = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        document.getElementById('fileFichaTecnica').value = ''
      }
      if (this.tpl === 'team') {
        try {
          this.$store.state.storeLine.setTeam.forEach(function (item, index) {
            var avatarTeam = document.getElementById('avatar-team' + index)
            var btnTeam = document.getElementById('btn-team' + index)
            var imageAvatarTeam = document.getElementById('imageAvatarTeam-' + index)
            var nameTeam = document.getElementById('nameTeam-' + index)
            imageAvatarTeam.src = ''
            nameTeam.innerHTML = ''
            btnTeam.classList.remove('d-none')
            avatarTeam.classList.add('d-none')
          })
        } catch (Exception) {
          console.log(Exception)
        }
        self.$store.commit('actionSetSelectedLine', null)
        self.$store.commit('actionSetTeamFull', [])
        self.$store.commit('actionSetLeader', {})
        self.$store.commit('actionSetUpdateLine', false)
      }
      if (this.tpl === 'track') {
        self.$store.state.storeTrack.input.loteLogistico = []
        self.$store.state.storeTrack.input.ordenProduction = []
        self.$store.state.storeTrack.input.julian_day = []
        self.$store.state.storeTrack.input.lote = []
        self.$store.state.storeTrack.bases = []
        self.$store.state.storeTrack.selectedRef = null
        self.$store.state.storeTrack.selectedOP = null
        self.$store.state.storeTrack.selectedLine = null
        self.$store.state.storeTrack.imagesPreview = []
        self.$store.state.storeTrack.updateTrack = false
      }
      if (this.tpl === 'activity') {
        self.$store.state.storeActivity.selected_activity = null
        self.$store.state.storeActivity.selectedRef = null
        self.$store.state.storeTrack.selectedOP = null
        self.$store.state.storeActivity.production_control_id = null
        self.$store.state.storeActivity.selectedLine = null
        self.$store.state.storeActivity.edit_activity = false
        self.$store.state.storeTrack.input.loteLogistico = []
        self.$store.state.storeTrack.input.ordenProduction = []
        self.$store.state.storeTrack.input.julian_day = []
        self.$store.state.storeTrack.input.lote = []
        self.$store.state.storeTrack.bases = []
      }
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    async aperturaLinea () {
      //
      const self = this
      self.disabledBtnAdd = true
      var leaderid
      var selectedLine = (this.$store.state.storeLine.selected != null)
      var leaderSelected = (this.$store.state.storeLine.setLeader.id != null)
      if (this.is_leader()) {
        leaderid = sessionStorage.user_id
        leaderSelected = true
      } else {
        leaderid = self.$store.state.storeLine.setLeader.id
      }
      if (selectedLine) {
        if (leaderSelected) {
          await axios.post('/api/aperturalinea/', {
            line: self.$store.state.storeLine.selected,
            leader: leaderid,
            team: self.$store.state.storeLine.setTeam
          }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            if (response.data.errors !== undefined && response.data.errors.length > 0) {
              response.data.errors.forEach(function (errors) {
                self.announceToast(errors.title, errors.content, errors.time, 'danger')
              })
              self.disabledBtnAdd = false
            } else {
              if (self.is_leader()) {
                axios.get('/api/getInfoLine/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                  self.$store.commit('actionSetinfoUsers', response.data)
                })
                self.$bvToast.toast('El equipo se ha agregado con exito.', {
                  title: 'Lider',
                  variant: 'success',
                  autoHideDelay: 5000,
                  solid: true
                })
                self.resetForm()
                self.$emit('closeBlock', false)
              } else {
                axios.get('/api/getInfoLineAdmin/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                  self.$store.commit('actionSetinfoUsers', response.data)
                })
                self.$bvToast.toast('El equipo se ha agregado con exito.', {
                  title: 'Lider',
                  variant: 'success',
                  autoHideDelay: 5000,
                  solid: true
                })
              }
              self.resetForm()
              self.$store.state.storeLine.simpleUser = []
              axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                self.$store.state.storeLine.simpleUser = response.data
                // response.data.forEach(function (item, index) {
                //   var nameFull = item.first_name + ' ' + item.last_name
                //   self.$store.state.storeLine.simpleUser.push({
                //     id: item.id,
                //     name: nameFull,
                //     avatar: 'https://tinyfac.es/data/avatars/BA0CB1F2-8C79-4376-B13B-DD5FB8772537-200w.jpeg'
                //   })
                // })
              })
              self.$store.commit('actionSetLineList', [])
              axios.get('/api/getAvailableLines/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                var auxLineList = []
                response.data.json_lines.forEach(function (item, index) {
                  auxLineList.push({ value: item.id, text: item.name_line })
                })
                self.$store.commit('actionSetLineList', auxLineList)
              })
              self.disabledBtnAdd = false
            }
          })
        } else {
          this.$bvToast.toast('Porfavor seleccione un lider.', {
            title: 'Seleccione un lider',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
          self.disabledBtnAdd = false
        }
      } else {
        this.$bvToast.toast('Porfavor seleccione una línea.', {
          title: 'Seleccione una línea',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
        self.disabledBtnAdd = false
      }
    },
    validateAddTraceability (activity) {
      let validateNext = true
      if (!(this.$store.state.storeActivity.selectedOP)) {
        validateNext = false
        this.$bvToast.toast('Por favor seleccione una OP.', {
          title: 'Referencia',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
      if (!(this.$store.state.storeActivity.selectedRef)) {
        validateNext = false
        this.$bvToast.toast('Por favor seleccione una referencia.', {
          title: 'Referencia',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
      if (!(this.$store.state.storeActivity.selectedLote)) {
        validateNext = false
        this.$bvToast.toast('Por favor seleccione un lote.', {
          title: 'Referencia',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
      if (!(this.$store.state.storeActivity.start_time)) {
        validateNext = false
        this.$bvToast.toast('Por favor introduzca la hora de inicio.', {
          title: 'Hora de inicio',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
      if (activity === this.creationContinueQualityControl && !(this.$store.state.storeActivity.order_Lote_reference)) {
        validateNext = false
        this.$bvToast.toast('Por favor elija la acción que quiere realizar.', {
          title: 'Acción a realizar',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
      return validateNext
    },
    async addTraceability (activity) {
      const self = this
      self.$store.state.storeActivity.saveFilesActivities = []
      if (activity === self.creationContinueQualityControl) {
        if (this.validateAddTraceability(activity) === true) {
          const referenceSend = this.$store.state.storeActivity.selectedRef
          const action = this.$store.state.storeActivity.order_Lote_reference
          this.getDataActivityByReference(referenceSend, action)
        }
      }
    },
    validateInputsTraceability: function (principalId, takeIterate, principalError, contentPrincipalError, name, dictReceived) {
      const self = this
      let validateHasNull = false
      const dataReceived = takeIterate === 'insumos' ? self.$store.state.storeActivity.dataFake.insumos : self.$store.state.storeActivity.dataFake.bases
      // const dataReceived = self.$store.state.storeActivity.dataFake.insumos
      dataReceived.some((textItem) => {
        let messageTitle = ''
        let titleTitle = ''
        if (textItem[principalId] === null || textItem[principalId] === '') {
          validateHasNull = true
          titleTitle = principalError
          messageTitle = contentPrincipalError
          self.announceToast(titleTitle, messageTitle, 15000, 'danger')
        } else {
          for (const [key, value] of Object.entries(dictReceived)) {
            if (textItem[key] === null || textItem[key] === '' || textItem[key] === undefined) {
              validateHasNull = true
              messageTitle += `${value}, `
            }
          }
          if (validateHasNull === true) {
            titleTitle = `Campos no diligenciados en ${name}.`
            messageTitle = `Falta por diligenciar ${messageTitle.slice(0, -2)}. Por favor diligenciar.`
            if (messageTitle.includes(',')) {
              const lastComma = messageTitle.lastIndexOf(',')
              messageTitle = messageTitle.slice(0, lastComma) + ' y' + messageTitle.slice(lastComma + 1)
            }
          }
        }
        if (validateHasNull === true) {
          self.announceToast(titleTitle, messageTitle, 15000, 'danger')
          return true
        }
      })
      return validateHasNull
    },
    async addTraceabilityExecute (activity) {
      const self = this
      let pkQuality = null
      let baseInfo = []
      if (activity === self.creationContinueQualityControl) {
        const basesDictSend = { lote: 'Lote', imagesPreview: 'Foto' }
        const suppliesDictSend = { lote: 'Lote' }
        const validateHasNullBases = this.validateInputsTraceability('id_base', 'bases', 'Problema con alguna base.', 'No se encuentra alguna base diligenciada, realícelo.', 'Bases', basesDictSend)
        const validateHasNullSupply = this.validateInputsTraceability('referencia', 'insumos', 'Problema con alguna referencia en Insumos.', 'No se encuentran todas las Referencias, refrescar página.', 'Insumos', suppliesDictSend)
        if (!(validateHasNullBases || validateHasNullSupply)) {
          const formDataSend = new FormData()
          formDataSend.append('ref', self.$store.state.storeActivity.dataFake.ref)
          const arrayData = []
          self.$store.state.storeActivity.dataFake.bases.forEach(function (item) {
            arrayData.push({
              referencia: item.referencia,
              lote: item.lote,
              loteLogistico: item.loteLogistico,
              ordenProduccion: item.ordenProduction,
              dueDate: item.dueDate
            })
          })
          const selectedRef = self.$store.state.storeActivity.selectedRef.ref
          const nowDate = new Date()
          let selectedTime = `${nowDate.getHours()}:${nowDate.getMinutes()}`
          if ('time' in self.$store.state.storeActivity.dataFake) {
            selectedTime = self.$store.state.storeActivity.dataFake.time
          }
          const selectedOP = self.$store.state.storeActivity.selectedOP
          const selectedLote = self.$store.state.storeActivity.selectedLote
          await axios.post('/api/addtraceability/', { codeOp: selectedOP, batch: selectedLote, ref: selectedRef, time: selectedTime, data: arrayData, supplies: self.$store.state.storeActivity.dataFake.insumos, action: self.$store.state.storeActivity.order_Lote_reference, start_time: self.$store.state.storeActivity.start_time, hour_applied: self.$store.state.storeActivity.dataFake.hourApplied }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            if (response.data.Status) {
              self.announceToast('Error al cargar', `${response.data.Status}.`, 15000, 'danger')
            } else if (response.data.error) {
              response.data.error.forEach(function (item) {
                self.announceToast(item.title, item.content, 9000, 'danger')
              })
            } else {
              pkQuality = response.data.pk_quality
              baseInfo = response.data.base_info
              self.announceToast('Éxito en el proceso', 'Se ha creado el registro de trazabilidad correctamente.', 5000, 'success')
              self.announceToast('Procesando imágenes', 'En espera de procesamiento de las imágenes.', 10000, 'warning')
              self.$store.state.storeActivity.dataFake.bases.forEach(function (item, index) {
                const lastIndex = self.$store.state.storeActivity.dataFake.bases.length - 1
                const data = new FormData()
                data.append('file', item.imagesPreview[0])
                data.append('traceability_id', baseInfo[index])
                axios.post('/api/uploadBaseFileTraceability/', data, {
                  headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') }
                }).then(function (response) {
                  if (lastIndex === index) {
                    self.announceToast('Éxito en el proceso', 'Se han procesado las imágenes correctamente.', 10000, 'success')
                    self.$store.commit('actionSetActividadFormTabs', 0)
                    self.closeBox()
                    self.$store.commit('actionSetTrazToAction', false)
                    self.announceToast('Guardando actividad..', 'Esto puede tardar un momento', 10000, 'warning')
                    var dataActivity = { activity_code: activity, codeOp: selectedOP, reference: selectedRef, goal: 0, start_time: self.$store.state.storeActivity.start_time, batch: selectedLote, pk_quality: pkQuality }
                    axios.post('/api/startActivityControl/', dataActivity, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                      self.$store.state.storeActivity.production_control_id = response.data.production_control
                    }).then(function () {
                      axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                        self.$store.state.storeActivity.activityList = response.data.list_data
                        self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
                      }).then(function () {
                        self.announceToast('Actividad', 'Actividad guardada con éxito.', 5000, 'success')
                      })
                    })
                  }
                  self.$store.dispatch('atLeastQualityRecordFn')
                }).catch(function (error) {
                  console.log(error)
                })
              })
            }
          })
        }
      }
    },
    async itemsBatchQuality () {
      const self = this
      await axios.post('/api_quality/getBatchs/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.$store.state.storeActivity.itemsBatch = response.data.criterion
        self.$store.state.storeActivity.user_list = response.data.users
      })
    },
    startClear () {
      const self = this
      const activity = self.getCurrentActivity()
      if (activity === self.closingQualityControl) {
        if (this.validateAddTraceability(activity) === true) {
          self.itemsBatchQuality().then(function () {
            if (self.$store.state.storeActivity.itemsBatch.length > 0) {
              self.$store.state.storeActivity.processQualityBtn = 'Enviar proceso'
              self.$store.state.storeActivity.disabledBtnSend = false
              // self.getDataActivityByReference(self.$store.state.storeActivity.selectedRef, self.$store.state.storeActivity.start_time)
              self.$store.commit('actionSetActividadFormTabs', 1)
            }
          })
        }
      }
    },
    closeTab () {
      this.$store.commit('actionSetActividadFormTabs', 0)
    },
    async getDataActivityByReference (reference, action) {
      const self = this
      try {
        const data = { reference: reference, action: action }
        await axios.post('/api_quality/getReferenceInfo/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            self.$store.state.storeActivity.dataFake = response.data
            self.$store.commit('actionSetActividadFormTabs', 1)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async confirmVerificateQualityControl () {
      const self = this
      await axios.post('/api_quality/confirmVerificationQualityProcess/', { quality_id: this.dataQuality.quality_id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.announceToast('Control de calidad', 'Se ha verificado el formato correctamente.', 5000, 'success')
          self.closeBox()
          self.$emit('setDataTrackQuality')
        }
      })
    },
    async addInventoryControl () {
      /*
      Se contemplan los estados de:
      - Entrada
      - Salida
      - Devolución
      - Avería
      - Transferencia
      - Cambio
      */
      const self = this
      const typeId = self.$store.state.storeInventory.typeSupplieInventory.pk
      const suppliesCode = self.$store.state.storeInventory.codeSuppliesInventory
      const codeOp = self.$store.state.storeInventory.opSupplieInventory
      const quantity = self.$store.state.cantidadPlan
      const batch = self.$store.state.storeInventory.batchSupplieInventory
      const placeId = self.$store.state.storeInventory.placeSupplieInventory.pk
      const lowerCastType = self.$store.state.storeInventory.typeSupplieInventory.name.toLowerCase()
      if (lowerCastType === 'salida') {
        const salida = {
          pk_type: typeId,
          description: '',
          supplies: suppliesCode,
          code_op: codeOp,
          quantity: quantity,
          batch: batch,
          place_id: placeId
        }
        await axios.post('/api_inventory/createtransactions/', salida, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 4000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha creado el registro de la transacción.', 4000, 'success')
          }
        })
      } else if (lowerCastType === 'averia' || lowerCastType === 'avería') {
        const averia = {
          pk_type: typeId,
          description: '',
          supplies: suppliesCode,
          code_op: codeOp,
          quantity: quantity,
          batch: batch,
          place_id: placeId
        }
        await axios.post('/api_inventory/createtransactions/', averia, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 4000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha creado el registro de la transacción.', 4000, 'success')
          }
        })
      } else if (lowerCastType === 'devolucion' || lowerCastType === 'devolución') {
        const devolucion = {
          pk_type: typeId,
          description: '',
          supplies: suppliesCode,
          code_op: codeOp,
          quantity: quantity,
          batch: batch,
          place_id: placeId
        }
        await axios.post('/api_inventory/createtransactions/', devolucion, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 4000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha creado el registro de la transacción.', 4000, 'success')
          }
        })
      } else if (lowerCastType === 'entrada') {
        const entrada = {
          pk_type: typeId,
          description: '',
          supplies: suppliesCode,
          code_op: codeOp,
          quantity: quantity,
          batch: batch,
          place_id: placeId
        }
        await axios.post('/api_inventory/createtransactions/', entrada, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 4000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha creado el registro de la transacción.', 4000, 'success')
          }
        })
      } else if (lowerCastType === 'transferencia') {
        const originDos = self.$store.state.storeInventory.originSupplieInventory.pk
        const destinationDos = self.$store.state.storeInventory.destinationSupplieInventory.pk
        const transferencia = {
          pk_type: typeId,
          description: '',
          supplies: suppliesCode,
          code_op: codeOp,
          quantity: quantity,
          batch: batch,
          place_id: originDos,
          origin: originDos,
          destination: destinationDos
        }
        await axios.post('/api_inventory/createtransactions/', transferencia, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 4000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha creado el registro de la transacción.', 4000, 'success')
          }
        })
      } else if (lowerCastType === 'cambio') {
        const origin = self.$store.state.storeInventory.originChangeSupplieInventory
        const destination = self.$store.state.storeInventory.destinationChangeSupplieInventory
        const cambio = {
          pk_type: typeId,
          description: '',
          supplies: suppliesCode,
          code_op: null,
          quantity: quantity,
          batch: batch,
          place_id: placeId,
          origin_op: origin,
          destination_op: destination
        }
        await axios.post('/api_inventory/createtransactions/', cambio, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 4000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha creado el registro de la transacción.', 4000, 'success')
          }
        })
      } else {
        const data = {
          pk_type: null,
          description: '',
          supplies: null,
          code_op: null,
          quantity: null,
          batch: '',
          place_id: null
        }
        await axios.post('/api_inventory/createtransactions/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 4000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha creado el registro de la transacción.', 4000, 'success')
          }
        })
      }
      await axios.post('/api_inventory/sactionsGlobal/', { place: self.$store.state.storeInventory.itemTransaction }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        self.postTabsArray = response.data.data
      })
      self.$store.state.storeInventory.codeSuppliesInventory = null
      self.$store.state.storeInventory.descriptionSupplieInventory = null
      self.$store.state.storeInventory.opSupplieInventory = null
      self.$store.state.storeInventory.batchSupplieInventory = null
      self.$store.state.storeInventory.placeSupplieInventory = { pk: null, name: '' }
      self.$store.state.cantidadPlan = 0
      self.$store.state.storeInventory.typeSupplieInventory = { pk: '', name: '' }
      self.$store.state.storeInventory.originSupplieInventory = { pk: null, name: '' }
      self.$store.state.storeInventory.destinationSupplieInventory = { pk: null, name: '' }
      self.$store.state.storeInventory.originChangeSupplieInventory = null
      self.$store.state.storeInventory.destinationChangeSupplieInventory = null
    },
    async addPicking () {
      const self = this
      var codigoOt = this.$store.state.storeInventory.OrdenTransportePk
      var fechaOt = this.$store.state.storeProduction.dateBsn
      var user = sessionStorage.getItem('user_id')
      var arrayOrder = []
      self.$store.state.storeInventory.rowsFormPicking.forEach(function (item, index) {
        arrayOrder.push({ id: self.$store.state.storeInventory.rowsFormPicking[index].material, code_op: self.$store.state.storeInventory.rowsFormPicking[index].op, batch: self.$store.state.storeInventory.rowsFormPicking[index].lote, amount_of_transportation: self.$store.state.storeInventory.rowsFormPicking[index].ordenTransporte, received_amount: self.$store.state.storeInventory.rowsFormPicking[index].recibida })
      })
      let dataOrderTransportation = {
        code_ot: codigoOt,
        ot_date: fechaOt,
        user_id: user,
        supplies_list: arrayOrder
      }
      axios.post('/api_inventory/creartransportOrder/', dataOrderTransportation, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach(error => {
            self.announceToast('Error', error.title, error.time, 'danger')
          })
        } else {
          self.$refs.buttonPicking.removeAttribute('disabled')
          self.announceToast('Éxito', 'Se agrego correctamente.', 2000, 'success')
          self.resetForm()
          self.$store.state.storeInventory.rowsFormPicking = [{ material: '', descripcion: '', op: '', lote: '', ot: '', recibida: '' }]
          self.$store.state.storeInventory.pikingDescripcion.descripcions = []
          self.$store.state.storeInventory.CodigoOplotef.codigos = []
          self.$store.state.storeInventory.pikingLotes.lotes = []
          self.$store.state.storeInventory.OrdenTransportePk = ''
          self.$store.state.storeInventory.OrdenProducion = { value: '', text: '' }
          dataOrderTransportation = {}
          self.$store.state.storeInventory.inputOt = {
            material: [''],
            descripcion: [''],
            op: [''],
            lote: [''],
            ordenTransporte: [''],
            recibida: ['']
          }
        }
      })
      await this.$store.dispatch('getInfoProduction')
      self.dataPicking = []
      await axios.get('/api_inventory/viewtransportOrder/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.$store.state.storeTrack.dataPicking = response.data.data
      })
      if (this.is_leader()) {
        self.$emit('closeBlock', false)
      }
    },
    async editRemission () {
      const self = this
      var op = this.$store.state.storeProduction.codeSelect
      var referencia = this.$store.state.referenciaRemision
      var lote = this.$store.state.productionBatch
      var siesa = self.$store.state.codeSiesa
      var despacho = self.$store.state.storeProduction.dispatchStatus
      var cantidad = self.$store.state.cantidadPlan
      var lista = self.$store.state.storeProduction.packingsLists
      var id = self.$store.state.idremission
      document.getElementById('buttonDispatchedit').setAttribute('disabled', '')
      document.getElementById('buttonDispatchedit').innerText = 'Cargando...'
      await axios.post('/api_orders/EditRemission/', { codeOp: op, code_siesa: siesa, referencia: referencia, batch: lote, pk_status: despacho.value, pk_packing_list: lista, quantity: cantidad, pk: id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach(function (error) {
            document.getElementById('buttonDispatchedit').removeAttribute('disabled')
            document.getElementById('buttonDispatchedit').innerText = 'Actualizar'
            self.announceToast('Error', error.title, error.time, 'danger')
          })
        } else {
          self.announceToast('Éxito', 'Se actualizó correctamente.', 2000, 'success')
          document.getElementById('buttonDispatchedit').removeAttribute('disabled')
          document.getElementById('buttonDispatchedit').innerText = 'Actualizar'
          axios.get('/api_orders/StartRemission/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            self.startRemission = response.data.data
            self.monthOp = response.data.month
            self.yearOp = response.data.year
            self.monthOptions = response.data.monthOptions
            self.yearOptions = response.data.yearOptions
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .box-close-block {
    position: absolute;
    right: 5%;
    .btn-close {
      color: #CACAD9;
    }
  }
  .btn-sideblock-back {
    position: absolute;
    left: 5%;
    color: #CACAD9;
  }
  .box-option {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
  }

  .icon-finish {
    position: relative;
    width: 15%;
    margin: auto;
    .i-check {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #e6e4ec;
      background: #F7F7FD;
      border-radius: 50%;
    }
  }

  .table-bases, .table-insumos {
    thead {
      text-align: left;
      th {
        padding: 10px 15px;
        font-weight: 400;
      }
      .border-left-head {
        border-left: 1px solid #ccc;
      }
    }
    tbody {
      tr {
        background-color:#FFF;
        border-left: 6px solid #CFC9DD;
        border-bottom: 8px solid #F7F7FD;
      }
      td{
        padding: 8px 10px;
        border-left: 1px solid #ccc;
      }
      .column-left {
        text-align: left;
      }
      .option-divider {
        border-left: 1px solid #ccc;
        border-radius: 0;
      }
    }
  }

  .b-form-spinbutton {
    background: transparent !important;
    border: 0;
    height: 50px;
    &:focus {
      box-shadow: none;
      background: transparent;
    }
    output {
      background-color: #FFF;
      padding: 10px 4px;
      border-radius: 10px;
      box-shadow: 0px 0px 0px 1px #ccc;
    }
  }

  .horas-input {
    width: 45%;
    margin: auto;
    border: 2px solid #ccc;
    height: 50px;
    border-radius: 10px;
    text-align: center;
  }

  .box-add-file {
    border: 1px dotted #ccc;
    border-radius: 10px;
  }

  /* File input Custom */
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .div-action-page {
    font-size: 1.5vw;
  }
  .red {
  color: red;
}
.green {
  color: green;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div v-if="showProduction" class="plan-produccion px-3 py-5 px-md-5" :class="{'page': !listProduction}">
    <!-- <div class="row justify-content-end">
        <div class="col-3"><b-button class="btn-action-page py-2" @click="$bvModal.show('modal-calendar');" size="lg">Histórico de producción</b-button></div>
    </div> -->
    <div class="header-page row">
      <div class="col-9"><h3>Plan de producción</h3></div>
    </div>
    <div class="row justify-content-end mt-4 mb-4 filters" v-if="module_op === true">
      <div class="col-xl-2 text-left">
        <p class="font-weight-bold">Filtrar por OP:</p>
        <div>
          <b-form-input v-model="codeOp" @change="filterMonthOP(codeOp)" placeholder="Ingrese una OP" class="mb-3 b-form-input"></b-form-input>
        </div>
      </div>
      <div class="col-xl-2 text-left">
        <p class="font-weight-bold">Filtrar por mes:</p>
        <div>
          <b-form-select v-model="monthOp" @change="filterMonthOP(null, monthOp)" :options="options"></b-form-select>
        </div>
      </div>
      <div class="col-xl-1 allData text-left">
        <button class="btn-filter-search" @click="allData()">Refrescar</button>
      </div>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex flex-column justify-content-center" :class="{ 'align-items-start': listProduction, 'align-items-center': !listProduction }">
        <!-- Pantalla Inicio Plan de producción -->
        <div class="item-page-new" v-if="!listProduction">
          <div class="circle-icon-page d-flex justify-content-center align-items-center">
            <div>
              <img src="~@/assets/pages/addTask.png" class="img-fluid">
            </div>
          </div>
          <div class="pt-4">
            <h5 class="font-weight-bold">Cree una nueva tarea <br> en su plan de producción</h5>
          </div>
          <div class="pt-3">
            <b-button class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar tarea</b-button>
          </div>
        </div>
        <div class="item-page-list w-100">
          <!-- Producción de Mañana -->
          <list-production :state="'tomorrow'" :color="'blue'" @edit="showBlock" :data="infoTomorrow" :limit="2" :startIndex="0" :endIndex="2"/>
          <!-- Producción Actual -->
          <list-production :state="'success'" :color="'green'" @edit="showBlock" :limit="2" :data="infoProduction" :startIndex="0" :endIndex="2"/>
          <!-- Producción Pendiente -->
          <list-production v-if="(infoPendiente.data.length !== 0 && module_op === false)" :state="'warning'" :color="'yellow'" @edit="showBlock" :limit="2" :data="infoPendiente" />
          <!-- Producción critico -->
          <list-production v-if="(infoCritico.data.length !== 0 && module_op === false)" :state="'danger'" :color="'red'" @edit="showBlock" :limit="2" :data="infoCritico" />
        </div>
      </div>
    </div>
    <!-- <div v-if="module_op === false">
      <div class="sidenav-pagebig p-4" :class="{ openSidebar: openOptions}">
        <side-block
          :icon="'task-interna'"
          :tpl="'task'"
          @closeBlock="close"
        />
      </div>
    </div> -->
    <div>
      <!-- <div class="sidenav-pagebig p-4" :class="{ openSidebar: openOptions}">
        <side-block
          :icon="'task-interna'"
          :tpl="'bsn'"
          @closeBlock="close"
        />
      </div> -->
      <div class="sidenav-pagebig p-4 width-90" :class="{ openSidebar: openOptions}">
        <side-block
          :icon="'task-interna'"
          :tpl="'plan-produccion'"
          @closeBlock="close"
        />
      </div>
    </div>
    <!-- Modals -->
    <div class="modal-production">
      <b-modal ref="close-modal" id="modal-list-production" centered size="xl" :hide-footer="true" :hide-header="true" >
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div>
          <list-production v-if="listCurrent == 'tomorrow'" :state="'tomorrow'" :color="'blue'" :modal="true" @edit="showBlock" :data="infoTomorrow" :startIndex="startIndex" :endIndex="endIndex"/>
          <!-- Producción Actual -->
          <list-production v-if="listCurrent == 'success'" :state="'success'" :color="'green'" :modal="true" @edit="showBlock" :data="infoProduction" :startIndex="startIndex" :endIndex="endIndex"/>
          <!-- Producción Pendiente -->
          <list-production v-if="listCurrent == 'warning'" :state="'warning'" :color="'yellow'" :modal="true" @edit="showBlock" :data="infoPendiente" :startIndex="startIndex" :endIndex="endIndex"/>
          <!-- Producción critico -->
          <list-production v-if="listCurrent == 'danger'" :state="'danger'" :color="'red'" :modal="true" @edit="showBlock" :data="infoCritico" :startIndex="startIndex" :endIndex="endIndex"/>
          <!-- Rango - We declare the state that belongs to infoRango, as a range, we assign a color -->
          <list-production v-if="listCurrent == 'rango'" :state="'rango'" :color="'green'" :modal="true" @edit="showBlock" :data="infoRango" />
        </div>
        <div class="list-pagination" v-if="listCurrent == 'tomorrow'">
          <b-pagination :total-rows="infoTomorrow.data.length" v-model="pag" :per-page="NUM_RESULTS" pills align="center" hide-ellipsis></b-pagination>
        </div>
        <div class="list-pagination" v-if="listCurrent == 'success'">
          <b-pagination :total-rows="infoProduction.data.length" v-model="pag" :per-page="NUM_RESULTS" pills align="center" hide-ellipsis></b-pagination>
        </div>
      </b-modal>
    </div>
    <!-- This modal contains the production history calendar -->
    <div class="modal-calendario">
      <b-modal ref="modal-calendar" id="modal-calendar" centered size="s" :hide-footer="true" :hide-header="true">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="$bvModal.hide('modal-calendar')">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="row" align="center">
          <div class="col-12"><p class="font-weight-bold">Fecha histórico de producción</p><input class="form-control" v-model="fechaInicio" type="date"></div></div>
          <br>
          <div align="center">
            <b-button class="btn-action-page py-2" @click="showRange('rango', fechaInicio);modalListClose();" size="lg">Consultar</b-button>
          </div>
      </b-modal>
    </div>
    <!-- Modal de eliminar -->
    <div class="modal-delete-task">
      <b-modal ref="close-delete-modal" id="delete-task" centered :hide-footer="true" :hide-header="true" >
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="py-5">
                <div class="circle-icon-page d-flex justify-content-center align-items-center">
                  <div>
                    <img src="~@/assets/icons/trash.png" class="img-fluid">
                  </div>
                </div>
                <div class="pt-4 text-center">
                  <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar la tarea?</h5>
                  <div class="mt-4" v-if="!module_op">
                    <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="close_delete_modal()">Cancelar</b-button>
                    <b-button  size="lg" class="btn-actions btn-delete mx-3" @click="deleteRef()">Eliminar</b-button>
                  </div>
                  <div v-else>
                    <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="close_delete_modal()">Cancelar</b-button>
                    <b-button  size="lg" class="btn-actions btn-delete mx-3" @click="deleteOp()">Eliminar</b-button>
                  </div>
                </div>
            </div>
          </div>
      </b-modal>
    </div>
    <!-- Modal de actualizar -->
    <div class="modal-update-task" v-if="!module_op">
      <b-modal ref="close-modal" id="update-task" centered :hide-footer="true" :hide-header="true" >
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="py-5">
                <div class="circle-icon-page d-flex justify-content-center align-items-center">
                  <div>
                    <img src="~@/assets/icons/sync.png" class="img-fluid">
                  </div>
                </div>
                <div class="pt-4 text-center">
                  <h5 class="font-weight-bold color-bluedark">¿Está seguro de que desea agregar esta tarea a la lista de producción?</h5>
                  <div class="mt-4">
                    <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="close_modal()">Cancelar</b-button>
                    <b-button  size="lg" class="btn-actions btn-update mx-3" @click="uploadRef()">Agregar</b-button>
                  </div>
                </div>
            </div>
          </div>
      </b-modal>
    </div>
    <div class="modal-update-task" v-else>
      <b-modal ref="close-modal" id="update-task" centered :hide-footer="true" :hide-header="true" >
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="py-5">
            <div class="circle-icon-page d-flex justify-content-center align-items-center">
              <div>
                <img src="~@/assets/icons/sync.png" class="img-fluid">
              </div>
            </div>
            <div class="pt-4 text-center">
              <h5 class="font-weight-bold color-bluedark">¿Está seguro de que desea agregar esta tarea a la lista de producción?</h5>
              <div class="mt-4">
                <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="close_modal()">Cancelar</b-button>
                <b-button  size="lg" class="btn-actions btn-update mx-3" @click="uploadOp()">Agregar</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- Modal Supplies -->
    <div class="modal-supplies-production">
      <b-modal ref="close-modal-supplies-product" id="modal_supplies_product_production" centered size="xl" :hide-footer="true" :hide-header="true" >
        <div class="d-flex justify-content-end">
          <button class="btn_close_supplies_modal bg-transparent border-0" @click="closeSuppliesModal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="p-4">
          <div class="text-left">
            <div class="form-group row">
              <label for="codeOp" class="font-weight-bold col-2 col-form-label">Producto</label>
              <input v-if="getDataPlanProduccion()" type="text" class="form-control col-10" v-model="$store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].codigo.ref" id="codeProductSupply" placeholder="Código del producto" disabled>
              <input v-if="getCreatedDataPlanProduccion()" type="text" class="form-control col-10" v-model="currentTask[0].Referencias[getIndexRowSupplyPlanProduction].codigo_producto" id="codeProductSupply" placeholder="Código del producto" disabled>
            </div>
            <div class="form-group row">
              <label for="fechaOP" class="col-2 col-form-label font-weight-bold">Descripción</label>
              <input v-if="getDataPlanProduccion()" type="text" class="form-control col-10" v-model="$store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].codigo.nombre" id="descriptionProductSupply" placeholder="Descripción del producto" disabled>
              <input v-if="getCreatedDataPlanProduccion()" type="text" class="form-control col-10" v-model="currentTask[0].Referencias[getIndexRowSupplyPlanProduction].descripcion" placeholder="Descripción del producto" disabled>
            </div>
            <table id="tablePlan" class="table" style="text-align: center;">
              <thead>
                <tr>
                  <td class="col-2"><strong>Insumo</strong></td>
                  <td class="col-3"><strong>Descripción</strong></td>
                  <td class="col-2"><strong>Cantidad</strong></td>
                  <td class="col-3"><strong>Lote</strong></td>
                  <td class="col-1"><strong>Fecha vencimiento</strong></td>
                  <td v-if="getCreatedDataPlanProduccion()" class="col-1"><strong>Fecha modificación</strong></td>
                  <td v-if="getCreatedDataPlanProduccion()" class="col-1"><strong>Usuario creador</strong></td>
                  <td class="col-1" v-if="($store.state.editarReferenciaPlan ? '' : true && !getCreatedDataPlanProduccion())"><strong>Acción</strong></td>
                </tr>
              </thead>
              <tbody id="tbody" v-if="getDataPlanProduccion() && $store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].suppliesObject && !$store.state.editarReferenciaPlan">
                <tr v-for="(supply, index) in this.$store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].suppliesList" :key="index">
                  <td class="col-3 position-relative align-middle">
                    <input v-if="blockSupply(index)" class="form-control" v-model="supply.id_supply" type="text" disabled>
                    <span v-if="isPrimarySupply(supply.id_supply)" class="position-absolute primary-content d-flex justify-content-center align-items-center" title="El insumo es prioritario">
                      <i class="fa-solid fa-star color-sucess-ipro fa-sm"></i>
                    </span>
                    <VueMultiselect v-if="!blockSupply(index)"  :max-height="150" v-model="supply.id_supply" :options="getSuppliesObject()"  placeholder="Insumo" @select="calculateQuantitySupply($store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].cantidad, supply.id_supply, index)">
                    </VueMultiselect>
                  </td>
                  <td class="col-2">
                    {{ supply.description_supply }}
                  </td>
                  <td class="col-1">
                    <input class="form-control" v-model="supply.quantity" type="ndataPlanProduccionumber" min="0">
                  </td>
                  <td class="col-3">
                    <input class="form-control" v-model="supply.entry_batch" placeholder="Ingrese lote, ej: 123abc" type="text">
                  </td>
                  <td class="col-1">
                    <input class="form-control" v-if="isBaseSupply(supply.id_supply)" v-model="supply.entry_due_date" placeholder="Fecha vencimiento" type="date" :min="$store.state.storeProduction.dateTwoMonthsBefore">
                  </td>
                  <td class="">
                    <b-button v-if="blockSupply(index) == false || ($store.state.editarReferenciaPlan && blockSupply(index) == false) ? true : false" variant="link" @click="$bvModal.show('delete-supply-plan'), confirmSupplyRowDelete(index)" title="Eliminar">
                      <i class="far fa-trash-alt text-danger fa-lg"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
              <tbody id="tbody" v-if="getDataPlanProduccion() && $store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].suppliesObject && $store.state.editarReferenciaPlan">
                <tr v-for="(supply, index) in this.$store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].suppliesList" :key="index">
                  <td class="col-3 position-relative align-middle">
                    <input v-if="blockSupply(index)" class="form-control" v-model="supply.id_supply" type="text" disabled>
                    <span v-if="isPrimarySupply(supply.id_supply)" class="position-absolute primary-content d-flex justify-content-center align-items-center" title="El insumo es prioritario">
                      <i class="fa-solid fa-star color-sucess-ipro fa-sm"></i>
                    </span>
                    <VueMultiselect v-if="!blockSupply(index)"  :max-height="150" v-model="supply.id_supply" :options="getSuppliesObject()"  placeholder="Insumo" @select="calculateQuantitySupply($store.state.dataPlanProduccion.rowsPlanProduccion[getIndexRowSupplyPlanProduction].cantidad, supply.id_supply, index)">
                    </VueMultiselect>
                  </td>
                  <td class="col-2">
                    {{ supply.description_supply }}
                  </td>
                  <td class="col-1">
                    <input class="form-control" v-model="supply.quantity" type="ndataPlanProduccionumber" min="0">
                  </td>
                  <td class="col-3">
                    <input class="form-control" v-model="supply.entry_batch" placeholder="Ingrese lote, ej: 123abc" type="text">
                  </td>
                  <td class="col-1">
                    <input class="form-control" v-if="isBaseSupply(supply.id_supply)" v-model="supply.entry_due_date" placeholder="Fecha vencimiento" type="date" :min="$store.state.storeProduction.dateTwoMonthsBefore">
                  </td>
                  <td class=" col-1">
                    <b-button v-if="blockSupply(index) == false || ($store.state.editarReferenciaPlan && blockSupply(index) == false) ? true : false" variant="link" @click="$bvModal.show('delete-supply-plan'), confirmSupplyRowDelete(index)" title="Eliminar">
                      <i class="far fa-trash-alt text-danger fa-lg"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
              <tbody id="tbody" v-if="getCreatedDataPlanProduccion()">
                <tr v-for="(supply, index) in this.currentTask[0].Referencias[this.getIndexRowSupplyPlanProduction].suppliesList" :key="index">
                  <td class="col-2">
                    <span v-if="isPrimarySupply(supply.id_supply)" class="position-absolute primary-content d-flex justify-content-center align-items-center" title="El insumo es prioritario">
                      <i class="fa-solid fa-star color-sucess-ipro fa-sm"></i>
                    </span>
                    {{ supply.id_supply }}
                  </td>
                  <td class="col-3">
                    {{ supply.description_supply }}
                  </td>
                  <td class="col-1">
                    {{ supply.quantity }}
                  </td>
                  <td class="col-2">
                    {{ supply.entry_batch }}
                  </td>
                  <td class="col-1">
                    <span v-if="supply.is_base">{{ supply.entry_due_date }}</span>
                  </td>
                  <td class=" col-1">
                    {{ formattedTimeZoneDate(supply.modified_date) }}
                  </td>
                  <td class=" col-2">
                    {{ supply.creator_user }}
                  </td>
                </tr>
                <tr v-if="this.currentTask[0].Referencias[this.getIndexRowSupplyPlanProduction].suppliesList.length === 0">
                  <td colspan="7" class="text-center">No hay insumos</td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center;" v-if="!getCreatedDataPlanProduccion()">
              <b-button class="btn-action-page py-2" size="sm" id="addRow" @click="addRowSupply()">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </b-button>
            </div>
            <div class="text-center mt-4">
              <button class="btn_close_supplies_modal btn btn-action-page py-2 btn-secondary btn-lg" size="sm" @click="closeSuppliesModal()">
                Volver
              </button>
            </div>
            <!-- Modal de eliminar -->
            <div class="modal-delete-supply-plan">
              <b-modal ref="close-delete-modal" id="delete-supply-plan" centered :hide-footer="true" :hide-header="true" >
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div class="py-5">
                    <div class="circle-icon-page d-flex justify-content-center align-items-center">
                      <div>
                        <img src="~@/assets/icons/trash.png" class="img-fluid">
                      </div>
                    </div>
                    <div class="pt-4 text-center">
                      <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar esta insumo?<br></h5>
                      <div>
                        <b-button size="lg" class="btn-actions btn-cancel mx-3" @click="close_delete_modal()">Cancelar</b-button>
                        <b-button v-if="!getCreatedDataPlanProduccion()" size="lg" class="btn-actions btn-delete mx-3" @click="removeSupplyRow()">Eliminar</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-if="!module_op">
      <b-modal ref="close-sub-modal" id="detail-task" size="xl" centered :hide-footer="true" :hide-header="true" >
          <div class="d-flex justify-content-end">
            <button class="bg-transparent border-0" @click="close_sub_modal()">
              <i class="far fa-times-circle fa-2x color-grayLight"></i>
            </button>
          </div>
          <div class="px-3 mb-3">
             <h4 class="color-bluedark">Detalle de referencia</h4>
             <div class="p-4 bg-grayLight">
              <div class="row">
                <div class="col-6">
                  <div class="my-4">
                    <h6>Referencia</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].reference}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Nombre</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].name}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Cantidad</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].start_quantity}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Horas de Producción</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].hours}}
                    </div>
                  </div>
                  <h6>Ficha Técnica</h6>
                  <div class="pt-3">
                    <b-button class="btn-action-page py-2" size="lg" @click="renderPdf(currentTask[0].url)">Ver Ficha</b-button>
                  </div>
                </div>
                <div class="col-6 border-left">
                  <div class="my-4">
                    <h6>Bases</h6>
                    <div v-for="(base, i) in currentTask[0].bases" :key="i" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                      <div class="col-5">
                        <h6>{{base.reference}}</h6>
                      </div>
                      <div class="col border-left">
                        <span>{{base.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Insumos</h6>
                    <div v-for="(supplie, i) in currentTask[0].supplies" :key="supplie.id" :id="'supplie' + i + '-' + supplie.id" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                      <div class="col-4">
                        <h6>{{supplie.id}}</h6>
                      </div>
                      <div class="col border-left">
                        <span>{{supplie.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Estándar de la maquila</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].ppk_standard}}
                    </div>
                  </div>
                </div>
              </div>
             </div>
          </div>
      </b-modal>
    </div>
    <div v-else>
      <b-modal ref="close-sub-modal" id="detail-order" size="xl" centered :hide-footer="true" :hide-header="true" >
          <div class="d-flex justify-content-end">
            <button class="bg-transparent border-0" @click="close_sub_modal()">
              <i class="far fa-times-circle fa-2x color-grayLight"></i>
            </button>
          </div>
          <div class="px-3 mb-3">
             <h4 class="color-bluedark">Detalle Orden de Producción</h4>
             <div class="p-4 bg-grayLight">
              <div class="row">
                <div class="col-12">
                  <div class="my-4">
                    <h6>Número de OP</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].OP}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Fecha</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].Fecha}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Horas de Producción</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].total_horas}}
                    </div>
                  </div>
                  <!-- <div class="my-4">
                    <h6>Lotes</h6>
                    <div class="bg-white border p-2" v-for="(lote, i) in currentTask[0].batchs" :key="i">
                      {{lote}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Cod. Producto</h6>
                    <div class="bg-white border p-2" v-for="(reference, i) in currentTask[0].references" :key="i">
                      {{reference}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Descripción</h6>
                    <div class="bg-white border p-2" v-for="(name, i) in currentTask[0].names" :key="i">
                      {{name}}
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Cantidad</h6>
                    <div class="bg-white border p-2">
                      {{currentTask[0].cantidad}}
                    </div>
                  </div>
                  <div v-if="(currentTask[0].url)">
                    <h6>Orden de producción</h6>
                    <div class="pt-3" >
                      <b-button class="btn-action-page py-2" size="lg" @click="renderPdf(currentTask[0].url)">Ver Orden</b-button>
                    </div>
                  </div> -->
                </div>
                <div class="table-responsive">
                  <table class="w-100 table-list-production">
                    <thead class="border-top">
                      <tr>
                        <th style="font-weight: 600;" class="border-left-head text-center">Cod Producto</th>
                        <th style="font-weight: 600;" class="border-left-head text-center">Descrip Producto</th>
                        <th style="font-weight: 600;" class="border-left-head text-center">Lote Producto</th>
                        <th style="font-weight: 600;" class="border-left-head text-center">Cantidad Producto</th>
                        <th v-if="statusDisplayQuantityBox" style="font-weight: 600;" class="border-left-head text-center">Cantidad Maquilada (Cajas)</th>
                        <th v-if="!statusDisplayQuantityBox" style="font-weight: 600;" class="border-left-head text-center">Cantidad Maquilada</th>
                        <th style="font-weight: 600;" class="border-left-head text-center">Horas Producto</th>
                        <th style="font-weight: 600;" class="border-left-head text-center">Estándar de la maquila</th>
                        <th style="font-weight: 600;" class="border-left-head text-center">Insumos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(referencias, i) in currentTask[0].Referencias" :key="i">
                        <td class="text-center">{{ referencias.codigo_producto }}</td>
                        <td class="text-center">{{ referencias.descripcion }}</td>
                        <td class="text-center">{{ referencias.lote }}</td>
                        <td class="text-center">{{ referencias.cantidad }}</td>
                        <td class="text-center">{{ referencias.end_quantity }}</td>
                        <td class="text-center">{{ referencias.horas }}</td>
                        <td class="text-center">
                          <span v-for="(data, i) in referencias.ppk_standard_process" :key="i">
                            {{data.product_process_type_id__name}}:{{data.ppk_standard}}
                            <!-- {{ currentTask[0].Referencias }} -->
                          </span>
                        </td>
                        <td>
                          <b-button variant="link" class="d-flex justify-content-center flex-row btn-action-page my-0 py-0 px-3 text-light text-decoration-none btn-description-supplies" @click="describeSupplies(i)" title="Visualizar los insumos">
                            <div class="d-flex justify-content-center flex-column align-items-center pr-2">
                              <span>{{ referencias.basesCount }}</span>
                              <span><i class="fa-solid fa-inbox"></i></span>
                            </div>
                            <div class="px-0 bg-light divider-supplies-add-op"> </div>
                            <div class="d-flex justify-content-center flex-column align-items-center pl-2">
                              <span>{{ referencias.suppliesCount }}</span>
                              <span><i class="fa-solid fa-dolly"></i></span>
                            </div>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 border-left">
                  <!-- <div class="my-4">
                    <h6>Referencias</h6>
                    <div v-for="(referencias, i) in currentTask[0].Referencias" :key="i" class="d-flex my-2 py-2 item-list" style="flex-direction: column;">
                      <div class="my-2">
                        <h6>Cod. Producto</h6>
                        <div class="bg-white border p-2">
                          {{ referencias.codigo_producto }}
                        </div>
                      </div>
                      <div class="my-2">
                        <h6>Descrip. Producto</h6>
                        <div class="bg-white border p-2">
                          {{ referencias.descripcion }}
                        </div>
                      </div>
                      <div class="my-2">
                        <h6>Lote. Producto</h6>
                        <div class="bg-white border p-2">
                          {{ referencias.lote }}
                        </div>
                      </div>
                      <div class="my-2">
                        <h6>Cantidad. Producto</h6>
                        <div class="bg-white border p-2">
                          {{ referencias.cantidad }}
                        </div>
                      </div>
                      <div class="my-2">
                        <h6>Horas. Producto</h6>
                        <div class="bg-white border p-2">
                          {{ referencias.hora }}
                        </div>
                      </div>
                      <div class="my-2">
                        <h6>Estándar de la maquila</h6>
                        <div v-for="(data, i) in referencias.ppk_standard_process" :key="i" class="bg-white border p-2">
                          <span>{{data.product_process_type_id__name}}:</span> {{data.ppk_standard}}
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="my-4">
                    <h6>Bases</h6>
                    <div v-for="(base, i) in currentTask[0].bases" :key="i" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                      <div v-for="(b, i) in base" :key="i">
                        <div class="col-5">
                          <h6>{{b.reference}}</h6>
                        </div>
                        <div class="col border-left">
                          <span>{{b.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Insumos</h6>
                    <div v-for="(supplie, i) in currentTask[0].supplies" :key="supplie.id" :id="'supplie' + i + '-' + supplie.id" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                      <div v-for="(s, i) in supplie" :key="i">
                        <div class="p-2 border-left">
                          <h6>{{s.id}}</h6>
                          <span>{{s.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-4">
                    <h6>Estándar de la maquila</h6>
                    <div v-for="(data, i) in currentTask[0].ppk_standard_process" :key="i" class="bg-white border p-2">
                      <div v-for="(d, a) in data" :key="a">
                        <span>{{d.product_process_type_id__name}}:</span> {{d.ppk_standard}}
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
             </div>
          </div>
      </b-modal>
    </div>
        <div>
          <b-modal ref="pdf-modal" id="pdf-modal" size="xl" centered :hide-footer="true" :hide-header="true">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_pdf_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div align="center">
        <pdf :src="currentDataSheetURL" id="pdf-view" :resize="true" @loading="removeText()" :page="1">
          <template slot="loading">
            Cargando ficha técnica...
          </template>
        </pdf>
      </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import pdf from 'pdfvuer'
import SideBlock from '@/components/SideBlock.vue'
import ListProduction from '@/components/shared/ListProduction.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'plan-produccion',
  data: () => ({
    NUM_RESULTS: 10, // Numero de resultados por página
    pag: 1, // Página inicial
    indexSupplyRowDelete: '',
    currentDataSheetURL: '',
    listProduction: true,
    searchList: '',
    monthOp: null,
    codeOp: undefined,
    fechaInicio: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    filterOption: null,
    options: [
      { value: null, text: 'Seleccione un mes' },
      { value: '1', text: 'Enero' },
      { value: '2', text: 'Febrero' },
      { value: '3', text: 'Marzo' },
      { value: '4', text: 'Abril' },
      { value: '5', text: 'Mayo' },
      { value: '6', text: 'Junio' },
      { value: '7', text: 'Julio' },
      { value: '8', text: 'Agosto' },
      { value: '9', text: 'Septiembre' },
      { value: '10', text: 'Octubre' },
      { value: '11', text: 'Noviembre' },
      { value: '12', text: 'Diciembre' }
    ],
    filterOptions: [
      { value: null, text: 'Seleccione un filtro' },
      { value: '1', text: 'Filtro por OP' },
      { value: '2', text: 'Filtro por mes' },
      { value: '3', text: 'Ver todos' }
    ]
  }),
  components: {
    pdf,
    SideBlock,
    ListProduction,
    VueMultiselect
  },
  async mounted () {
    if (sessionStorage.token) {
      this.$store.dispatch('atLeastQualityRecordFn')
      this.$store.dispatch('statusModulesEnabled')
      if (sessionStorage.rol === '1') {
        this.$store.state.storePlanProduccion = this.fechaInicio
        await this.$store.dispatch('getInfoProduction')
        this.showProduction = true
      }
      if (sessionStorage.rol === '2') {
        this.$router.push({ name: 'AperturaLinea' })
      }
    }
    if (!sessionStorage.token) {
      this.$router.push({ name: 'Inicio' })
    }
  },
  computed: {
    startIndex () {
      // Calcular la lista paginada
      const startIndex = (this.pag - 1) * this.NUM_RESULTS
      return startIndex
    },
    endIndex () {
      // Calcular la lista paginada
      const startIndex = (this.pag - 1) * this.NUM_RESULTS
      const endIndex = startIndex + this.NUM_RESULTS
      return endIndex
    },
    dateProduction: {
      get () {
        return this.$store.state.storeProduction.date
      },
      set (value) {
        this.$store.state.storeProduction.date = value
      }
    },
    showProduction: {
      set (value) {
        this.$store.state.storeProduction.showProduction = value
      },
      get () {
        return this.$store.state.storeProduction.showProduction
      }
    },
    statusDisplayQuantityBox: {
      set (value) {
        this.$store.state.storeProduction.statusDisplayQuantityBox = value
      },
      get () {
        return this.$store.state.storeProduction.statusDisplayQuantityBox
      }
    },
    mandatorySupplyProductionPlan: {
      set (value) {
        this.$store.state.storeQualityModule.mandatorySupplyProductionPlan = value
      },
      get () {
        return this.$store.state.storeQualityModule.mandatorySupplyProductionPlan
      }
    },
    supplyProcessingStatus: {
      set (value) {
        this.$store.state.storeQualityModule.supplyProcessingStatus = value
      },
      get () {
        return this.$store.state.storeQualityModule.supplyProcessingStatus
      }
    },
    infoTomorrow () {
      return this.$store.state.storeProduction.infoTomorrow
    },
    infoProduction () {
      return this.$store.state.storeProduction.infoProduction
    },
    infoPendiente () {
      return this.$store.state.storeProduction.infoPendiente
    },
    infoCritico () {
      return this.$store.state.storeProduction.infoCritico
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    openOptions () {
      return this.$store.state.showOptions
    },
    listCurrent () {
      return this.$store.state.storeProduction.listProductionCurrent
    },
    currentTask () {
      return this.$store.state.storeProduction.currentTask
    },
    getIndexRowSupplyPlanProduction () {
      return this.$store.state.dataPlanProduccion.indexRowSupplyPlanProduction
    },
    getAllDataSuppliesObject () {
      return this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesObject
    }
  },
  methods: {
    getDataPlanProduccion () {
      if (this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction]) {
        return this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].codigo !== ''
      }
      return false
    },
    getCreatedDataPlanProduccion () {
      if (this.currentTask.length > 0 && !this.getDataPlanProduccion() && this.currentTask[0].Referencias && this.currentTask[0].Referencias[this.getIndexRowSupplyPlanProduction]) {
        return true
      }
      return false
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    async allData () {
      this.monthOp = null
      return await this.filterMonthOP(null, null)
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    showBlock: function () {
      localStorage.removeItem('Batchs')
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.state.editarReferenciaPlan = false
    },
    close_delete_modal () {
      this.$refs['close-delete-modal'].hide()
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
    },
    close_modal: function () {
      this.searchList = ''
      this.$refs['close-modal'].hide()
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
    },
    async deleteRef () {
      var ref = this.$store.state.storeProduction.refToDel
      var date = this.$store.state.storeProduction.refDateToDel
      var data = {
        ref: ref,
        date: date
      }
      await axios.post('/api/delreferencefromplan/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        console.log(response.data)
      }).catch(function (error) {
        console.log(error)
      })
      await this.$store.dispatch('getInfoProduction')
      this.close_delete_modal()
    },
    async deleteOp () {
      var codeOp = this.$store.state.storeProduction.opToDel
      var date = this.$store.state.storeProduction.opDateToDel
      var ref = this.$store.state.storeProduction.refToDel
      var data = {
        codeOp: codeOp,
        date: date,
        ref: ref
      }
      await axios.post('/api/delreferencefromplan/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        console.log(response.data)
      }).catch(function (error) {
        console.log(error)
      })
      await this.$store.dispatch('getInfoProduction')
      this.close_delete_modal()
    },
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    async getDataSheetURL (reference, date) {
      await axios.post('/api/getDataSheetURL/', { reference: reference, date: date }).then(function (response) {
        return response.data.url
      })
    },
    close_pdf_modal () {
      this.$refs['pdf-modal'].hide()
    },
    renderPdf (urlSent) {
      if (urlSent === '/media/undefined') {
        this.announceToast('Advertencia', 'No se encontró la URL de la ficha adjuntada', 4000, 'warning')
      } else {
        this.currentDataSheetURL = url + 'media/' + urlSent.split('/media/')[1]
        window.open(this.currentDataSheetURL, '_blank')
      }
    },
    async uploadRef () {
      var ref = this.$store.state.storeProduction.refToDel
      var date = this.$store.state.storeProduction.refDateToDel
      var data = {
        ref: ref,
        date: date
      }
      await axios.post('/api/uploadProduct/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        console.log(response.data)
      }).catch(function (error) {
        console.log(error)
      })
      await this.$store.dispatch('getInfoProduction')
      this.close_modal()
    },
    async uploadOp () {
      var codeOp = this.$store.state.storeProduction.opToDel
      var date = this.$store.state.storeProduction.opDateToDel
      var ref = this.$store.state.storeProduction.refToDel
      var data = {
        codeOp: codeOp,
        date: date,
        ref: ref
      }
      await axios.post('/api/uploadProduct/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        console.log(response.data)
      }).catch(function (error) {
        console.log(error)
      })
      await this.$store.dispatch('getInfoProduction')
      this.close_delete_modal()
    },
    async filterMonthOP (codeOp, monthOp) {
      const self = this
      if (codeOp != null) {
        await axios.post('/api/getproduction/', { codeOp: codeOp }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.commit('actionSetInfoProduction', response.data.infoProduction)
          self.$store.commit('actionSetInfoTomorrow', response.data.infoTomorrow)
          self.$store.commit('actionSetInfoPendiente', response.data.infoPendiente)
          self.$store.commit('actionSetInfoCritico', response.data.infoTomorrow)
          self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
          self.codeOp = ''
        }).catch(function (error) {
          alert(error)
        })
      } else if (monthOp != null) {
        await axios.post('/api/getproduction/', { monthOp: monthOp }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.commit('actionSetInfoProduction', response.data.infoProduction)
          self.$store.commit('actionSetInfoTomorrow', response.data.infoTomorrow)
          self.$store.commit('actionSetInfoPendiente', response.data.infoPendiente)
          self.$store.commit('actionSetInfoCritico', response.data.infoTomorrow)
          self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
        }).catch(function (error) {
          alert(error)
        })
      } else {
        await axios.get('/api/getproduction/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.commit('actionSetInfoProduction', response.data.infoProduction)
          self.$store.commit('actionSetInfoTomorrow', response.data.infoTomorrow)
          self.$store.commit('actionSetInfoPendiente', response.data.infoPendiente)
          self.$store.commit('actionSetInfoCritico', response.data.infoTomorrow)
          self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
        }).catch(function (error) {
          alert(error)
        })
      }
    },
    showRange (state) {
      this.historicalProduction()
      this.$store.commit('actionListCurrent', state)
      this.$bvModal.show('modal-list-production')
    },
    modalListClose: function () {
      this.$bvModal.hide('modal-calendar')
    },
    calculateQuantitySupply (quantityReference, supplyId, index) {
      const supply = this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesObject[supplyId]
      if (supply.required_quantity !== 0 && supply.production_quantity !== 0) {
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesList[index].quantity = Math.ceil((supply.required_quantity / supply.production_quantity) * quantityReference)
      }
      if (supply.required_quantity === 0 || supply.production_quantity === 0) {
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesList[index].quantity = Math.ceil(quantityReference)
      }
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesList[index].description_supply = supply.description_supply
      this.calculateCountBasesAndSupplies(this.getIndexRowSupplyPlanProduction)
    },
    addRowSupply () {
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesList.push({ id_supply: '', description_supply: '', quantity: '', entry_batch: '', entry_due_date: null })
    },
    confirmSupplyRowDelete (index) {
      this.indexSupplyRowDelete = index
    },
    removeSupplyRow () {
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesList.splice(this.indexSupplyRowDelete, 1)
      this.calculateCountBasesAndSupplies(this.getIndexRowSupplyPlanProduction)
      this.close_delete_modal()
    },
    calculateCountBasesAndSupplies (position) {
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].basesCount = this.calculateCountSupplies(position, true)
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].suppliesCount = this.calculateCountSupplies(position, false)
    },
    calculateCountSupplies (position, isBase = false) {
      return this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].suppliesList.filter(
        x => this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].suppliesObject[x?.id_supply]?.is_base === isBase
      ).length
    },
    getSuppliesObject () {
      return Object.keys(this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesObject)
    },
    blockSupply (index) {
      return Object.prototype.hasOwnProperty.call(this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesList[index], 'creator_user')
    },
    setQuantitySupply (quantity, index) {
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesList[index].quantity = quantity
    },
    closeSuppliesModal () {
      this.$root.$emit('bv::hide::modal', 'modal_supplies_product_production')
    },
    isBaseSupply (supplyId) {
      if (this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesObject[supplyId]) {
        return this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesObject[supplyId].is_base
      }
      return null
    },
    isPrimarySupply (supplyId) {
      if (this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesObject[supplyId]) {
        return this.$store.state.dataPlanProduccion.rowsPlanProduccion[this.getIndexRowSupplyPlanProduction].suppliesObject[supplyId].is_primary
      }
      return null
    },
    describeSupplies (index) {
      this.$store.state.dataPlanProduccion.indexRowSupplyPlanProduction = index
      this.$root.$emit('bv::show::modal', 'modal_supplies_product_production')
    },
    formattedTimeZoneDate (timezoneDate) {
      if (timezoneDate) {
        const [datePart] = timezoneDate.split('T')
        return datePart
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
  .width-90 {
    width: 90%;
  }
  .date-production {
    font-size: 1.2rem;
  }
  .header-list-production {
    .header-column-left {
      width: 60%;
    }
    .header-column-right {
      width: 40%;
    }
  }
  .hours-production {
    background-color: #ECECFB;
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid #DBDBEA;
  }
  .modal-content {
    border-radius: 30px;
    border: 0;
  }
  #modal-list-production, #detail-task {
    margin-left: 40px;
  }
  #search-list {
    .input-search {
      border-radius: 8px 0 0 8px !important;
    }
    button {
      background-color: #03185A;
      border-radius: 0 8px 8px 0;
      &:hover, &:focus, &:active {
        background-color: #03185A !important;
        color: #FFF;
      }
    }
  }
  .item-list {
    border-left: 6px solid #CCC;
    padding-left: 6px;
  }
  @media (max-width: 991.98px) {
    #modal-list-production, #detail-task #modal-list-detail-production {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin: 3%;
      }
    }
  }
  .allData {
    margin-top: 2rem;
  }
  @media (max-width: 834px) {
    .table-list-production tbody td {
      padding: 0px 0px;
      border-left: 1px solid #ccc;
      font-size: 10px;
    }
    .table-list-production thead {
      text-align: left;
      font-size: 10px;
    }
    .header-list-production {
      background: #fbfbfb;
      padding: 15px 10px;
      border-left-color: #00b7ff;
      font-size: 10px;
    }
    .icon-list {
      color: #FFF;
      border-radius: 50%;
      padding: 6px 6px 0px 6px;
      width: 30px;
      height: 30px;
      display: inline-block;
      text-align: center;
    }
  }
  @media (max-width: 500px) {
    .font-weight-bold {
      font-weight: 700!important;
      font-size: 1rem;
    }
  }
  .modal-supplies-production {
    z-index: 9999;
  }
  .table-list-production {
    .btn-description-supplies {
      font-size: smaller;
    }
  }
  .primary-content {
    left: -7px;
    top: 0;
    bottom: 0;
  }
</style>

<template>
  <main>
    <div class="box-option mx-4">
      <div class="col-md-12 py-4">
        <fieldset v-for="(item, i) in massiveData" :key="i">
          <legend>{{ item.name_line }}</legend>
          <div style="overflow-x: auto;">
            <div class="d-flex row_data_activitity" v-for="(subitem, r) in item.rowsData" :key="r">
              <div class="col-12 d-flex justify-content-center">
                <div class="form-group">
                  <label for="horainicial">Hora inicial:</label>
                  <input v-model="subitem.startTime" class="form-control" type="time">
                </div>
                <div class="form-group">
                  <label for="horafinal">Hora final:</label>
                  <input v-model="subitem.finalHour" class="form-control" type="time">
                </div>
                <div class="form-group" style="width: 300px;">
                  <label for="actividad">Actividad:</label>
                  <multiselect class="select_wrapper" style="width: 300px;" v-model="subitem.activity"
                    :show-labels="false" :options="actividades" @select="validateActivity(subitem.activity, i, r)"
                    placeholder="Actividad" :custom-label="nameWithLang"></multiselect>
                </div>
                <div class="form-group" style="width: 200px;">
                  <label for="Op">Op:</label>
                  <multiselect class="select_wrapper" :class="{'bg-multiselect-warning': nonAddingProductQuantity(subitem.op, subitem.reference)}" v-model="subitem.op" :show-labels="false" :options="op"
                    @select="getProductOrderProduction(subitem.op, subitem.activity, i, r)" placeholder="Op"
                    style="width: 200px;"></multiselect>
                </div>
                <div class="form-group" style="width: 250px;">
                  <label for="referenciaLote">Referencia - lote:</label>
                  <multiselect class="select_wrapper" :class="{'bg-multiselect-warning': nonAddingProductQuantity(subitem.op, subitem.reference)}" v-model="subitem.reference" :show-labels="false" :options="subitem.list_references" @select="validateSeveralProcessProduct(subitem.reference, subitem.activity, i, r), getBatchOrderProduction(subitem.reference, i, r)" placeholder="Referencia" label="text" style="width: 250px;"></multiselect>
                </div>
                <div class="form-group" style="width: 200px;">
                  <label for="lote">Lote:</label>
                  <input class="form-control" :class="{'bg-multiselect-warning': nonAddingProductQuantity(subitem.op, subitem.reference)}" style="width: 200px;" type="text" v-model="subitem.batch" disabled>
                </div>
                <div class="form-group" style="width: 200px;">
                  <label for="proceso">Proceso: </label>
                  <multiselect class="select_wrapper" style="width: 200px;" v-if="subitem.actividad100 && subitem.typeprocess" v-model="subitem.process" :show-labels="false" :options="subitem.list_process" placeholder="Proceso" label="text"></multiselect>
                </div>
                <div class="form-group" style="width: 200px;">
                  <label for="cantidad">Cantidad <span v-if="statusDisplayQuantityBox">(En cajas)</span>: </label>
                  <input v-if="subitem.actividad100" v-model="subitem.amount" class="form-control" :class="{'bg-multiselect-warning': nonComplyingProductQuantity(subitem.op, subitem.reference)}" style="width: 200px;" type="number" @blur="validarInput(i, r)">
                  <span v-if="statusDisplayQuantityBox && subitem.amount > 0 && subitem.reference.display_quantity_box != undefined" class="d-block text-success">{{ subitem.reference.display_quantity_box * subitem.amount }} unidades</span>
                  <span v-if="nonComplyingProductQuantity(subitem.op, subitem.reference)" class="d-block text-warning">{{ nonComplyingProductQuantityInfo(subitem.op, subitem.reference) }}</span>
                </div>
                <div class="form-group" style="width: 700px;">
                  <label for="cantidad">Operarios:</label>
                  <div class="d-flex flex-row justify-content-center align-items-center mb-2">
                    <label for="cantidad" class="col-2">Líder:</label>
                    <multiselect class="select_wrapper" v-model="subitem.leader" :options="simpleUser" :multiple="false" :show-labels="false" label="name" placeholder="Líder">
                    </multiselect>
                  </div>
                  <multiselect class="select_wrapper" v-model="subitem.team" :options="simpleUser" :multiple="true" track-by="id" :show-labels="false" label="name" placeholder="Operarios">
                  </multiselect>
                </div>
                <div class="form-group">
                  <label for="nombre">Acción:</label>
                  <td class="d-flex justify-content-center">
                    <b-button variant="link" @click="addRow(subitem, i, r)">
                      <i class="fa-regular fa-copy fa-lg" style="color: #39C2A1;"></i>
                    </b-button>
                    <b-button variant="link" @click="$bvModal.show('delete-activity-masterfoods'), confirmDelete(i, r)">
                      <i class="far fa-trash-alt text-danger fa-lg"></i>
                    </b-button>
                  </td>
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center;">
            <b-button class="btn-action-page py-2" size="sm" id="addRow" @click="createRow(i, item.line)">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </b-button>
          </div>
        </fieldset>
      </div>
      <b-button class="btn-action-page py-2 mt-1 mb-5" size="lg" id="addActivityMbutton"
        @click="addActivity()">Agregar</b-button>
    </div>
    <!-- Modal de eliminar -->
    <div class="modal-delete-activity-masterfoods">
      <b-modal ref="close-delete-modal" id="delete-activity-masterfoods" centered :hide-footer="true"
        :hide-header="true">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="py-5">
            <div class="circle-icon-page d-flex justify-content-center align-items-center">
              <div>
                <img src="~@/assets/icons/trash.png" class="img-fluid">
              </div>
            </div>
            <div class="pt-4 text-center">
              <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar esta actividad?<br>
              </h5>
              <div>
                <b-button size="lg" class="btn-actions btn-cancel mx-3"
                  @click="close_delete_modal()">Cancelar</b-button>
                <b-button size="lg" class="btn-actions btn-delete mx-3" @click="removeRow()">Eliminar</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </main>
</template>

<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url

export default {
  name: 'FormMarsfood',
  components: {
    Multiselect
  },
  data () {
    return {
      massive_activities: false,
      module_op: false,
      esValido: true,
      indexDelete: '',
      indexRow: '',
      indexLine: '',
      actividades: [],
      op: [],
      massiveData: [],
      nonComplyingProductByProductionOrder: {},
      nonAddingProductByProductionOrder: []
    }
  },
  computed: {
    activityList: {
      set (value) {
        this.$store.state.storeActivity.activityList = value
      },
      get () {
        return this.$store.state.storeActivity.activityList
      }
    },
    statusDisplayQuantityBox: {
      set (value) {
        this.$store.state.storeProduction.statusDisplayQuantityBox = value
      },
      get () {
        return this.$store.state.storeProduction.statusDisplayQuantityBox
      }
    },
    simpleUser () {
      return this.$store.state.storeLine.simpleUser
    }
  },
  methods: {
    close_detail_modal () {
      this.$refs['close-detail-modal'].hide()
    },
    close_delete_modal () {
      this.$refs['close-delete-modal'].hide()
    },
    confirmDelete (linePosition, rowPosition) {
      this.indexLine = linePosition
      this.indexDelete = rowPosition
    },
    closeBlock () {
      this.$store.commit('actionBlockOptions', false)
      this.$store.commit('actionOrvelay', false)
    },
    validarInput (linePosition, rowPosition) {
      const regex = /^\d+$/
      const esValido = regex.test(this.massiveData[linePosition].rowsData[rowPosition].amount.trim())
      this.esValido = esValido
      if (this.esValido === false) {
        this.announceToast('Cantidad', 'Ingrese una cantidad valida', 5000, 'warning')
      }
    },
    nameWithLang ({ name, activitycode }) {
      return `${activitycode} — ${name}`
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    async getBatchOrderProduction (referenceValue, linePosition, rowPosition) {
      this.massiveData[linePosition].rowsData[rowPosition].batch = ''
      if (referenceValue.lote) {
        this.massiveData[linePosition].rowsData[rowPosition].batch = referenceValue.lote
      } else {
        this.massiveData[linePosition].rowsData[rowPosition].batch = ''
      }
    },
    async getProductOrderProduction (value, actividad, linePosition, rowPosition) {
      if (actividad?.activitycode) {
        await axios.post('/api_orders/getReferencesByOp/', { OP: value }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
          this.massiveData[linePosition].rowsData[rowPosition].reference = null
          this.massiveData[linePosition].rowsData[rowPosition].batch = null
          this.massiveData[linePosition].rowsData[rowPosition].process = null
          this.massiveData[linePosition].rowsData[rowPosition].list_process = []
          const references = response.data.map(element => ({
            ref: element.product_code__reference,
            text: element.product_code__reference + ' - ' + element.batch,
            lote: element.batch,
            display_quantity_box: element.display_quantity_box
          }))
          this.massiveData[linePosition].rowsData[rowPosition].list_references = references
        }).catch(function (error) {
          console.error(error)
        })
      } else {
        this.massiveData[linePosition].rowsData[rowPosition].op = null
        this.massiveData[linePosition].rowsData[rowPosition].reference = null
        this.massiveData[linePosition].rowsData[rowPosition].batch = null
        this.massiveData[linePosition].rowsData[rowPosition].process = null
        this.massiveData[linePosition].rowsData[rowPosition].list_process = []
        this.massiveData[linePosition].rowsData[rowPosition].list_references = []
        this.announceToast('Referencia', 'Ingrese una actividad', 5000, 'warning')
      }
    },
    async createRow (linePosition, lineId) {
      const startHour = moment().format('HH:mm')
      const endHour = moment(startHour, 'HH:mm').add(1, 'hour').format('HH:mm')
      const lengthRows = this.massiveData[linePosition].rowsData.length
      let leaderInfo = null
      let teamList = []
      if (lengthRows === 0) {
        leaderInfo = this.massiveData[linePosition].leader
        teamList = this.massiveData[linePosition].team
      }
      if (lengthRows !== 0) {
        leaderInfo = this.massiveData[linePosition].rowsData[lengthRows - 1].leader
        teamList = this.massiveData[linePosition].rowsData[lengthRows - 1].team
      }
      this.massiveData[linePosition].rowsData.push({ linea_id: lineId, startTime: startHour, finalHour: endHour, activity: null, op: null, reference: null, batch: null, process: null, amount: 0, actividad100: false, typeprocess: false, list_process: [], list_references: [], leader: leaderInfo, team: teamList })
    },
    async addRow (data, linePosition, rowPosition) {
      const self = this
      const newRow = { ...data, copia: true }
      this.massiveData[linePosition].rowsData.splice(rowPosition + 1, 0, newRow)
      if (newRow.activity?.activitycode === 100) {
        this.massiveData[linePosition].rowsData[rowPosition + 1].actividad100 = true
        if (newRow.reference !== null) {
          await axios.post('/api/getProcessToReference/', { reference: newRow.reference?.ref }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            if (response.data.error !== undefined && response.data.error.length > 0) {
              response.data.error.forEach(function (error) {
                self.announceToast(error.title, error.content, error.time, 'danger')
              })
            } else {
              if (response.data.typeProcessProductStatus === true) {
                self.massiveData[linePosition].rowsData[rowPosition + 1].typeprocess = true
                self.massiveData[linePosition].rowsData[rowPosition + 1].list_process = response.data.list_process
              } else {
                self.massiveData[linePosition].rowsData[rowPosition + 1].typeprocess = false
                self.massiveData[linePosition].rowsData[rowPosition + 1].list_process = []
              }
            }
          })
          await axios.post('/api_orders/getReferencesByOp/', { OP: newRow.op }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
            const references = response.data.map(element => ({
              ref: element.product_code__reference,
              text: element.product_code__reference + ' - ' + element.batch,
              lote: element.batch
            }))
            this.massiveData[linePosition].rowsData[rowPosition + 1].list_references = references
          }).catch(function (error) {
            console.error(error)
          })
        }
      } else {
        this.massiveData[linePosition].rowsData[rowPosition + 1].list_process = []
        this.massiveData[linePosition].rowsData[rowPosition + 1].list_references = []
        this.massiveData[linePosition].rowsData[rowPosition + 1].actividad100 = false
        this.massiveData[linePosition].rowsData[rowPosition + 1].typeprocess = false
      }
    },
    removeRow () {
      this.massiveData[this.indexLine].rowsData.splice(this.indexDelete, 1)
      this.close_delete_modal()
    },
    validateHour (horafinal, linePosition, rowPosition) {
      if (this.massiveData[linePosition].rowsData[rowPosition].startTime >= horafinal) {
        this.announceToast('HoraFinal', 'Ingrese una hora final mayor que la hora inicial', 5000, 'warning')
      }
    },
    validateActivity (codeActivity, linePosition, rowPosition) {
      if (codeActivity.activitycode === 100) {
        this.massiveData[linePosition].rowsData[rowPosition].actividad100 = true
      } else {
        this.massiveData[linePosition].rowsData[rowPosition].actividad100 = false
      }
      this.massiveData[linePosition].rowsData[rowPosition].list_process = []
      this.massiveData[linePosition].rowsData[rowPosition].list_references = []
      this.massiveData[linePosition].rowsData[rowPosition].op = null
      this.massiveData[linePosition].rowsData[rowPosition].reference = null
      this.massiveData[linePosition].rowsData[rowPosition].batch = null
      this.massiveData[linePosition].rowsData[rowPosition].process = null
    },
    async validateSeveralProcessProduct (value, codeActivity, i, r) {
      const self = this
      if (codeActivity?.activitycode === 100) {
        if (value !== null) {
          await axios.post('/api/getProcessToReference/', { reference: value.ref }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            if (response.data.error !== undefined && response.data.error.length > 0) {
              response.data.error.forEach(function (error) {
                self.announceToast(error.title, error.content, error.time, 'danger')
              })
            } else {
              if (response.data.typeProcessProductStatus === true) {
                self.massiveData[i].rowsData[r].typeprocess = true
                self.massiveData[i].rowsData[r].list_process = response.data.list_process
              } else {
                self.massiveData[i].rowsData[r].typeprocess = false
                self.massiveData[i].rowsData[r].list_process = []
              }
            }
          })
        } else {
          self.massiveData[i].rowsData[r].typeprocess = false
        }
      } else {
        self.massiveData[i].rowsData[r].typeprocess = false
        self.massiveData[i].rowsData[r].list_process = []
      }
    },
    addActivity () {
      const self = this
      self.nonComplyingProductByProductionOrder = {}
      self.nonAddingProductByProductionOrder = []
      document.getElementById('addActivityMbutton').innerText = 'Cargando...'
      document.getElementById('addActivityMbutton').setAttribute('disabled', '')
      axios.post('/api/ActivitiesMarsfood/', { dataActivities: this.massiveData }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then((response) => {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach((error) => {
              self.announceToast(error.title, error.content, 10000, 'warning')
            })
            if (response.data.nonComplyingProductByProductionOrder !== undefined) {
              self.nonComplyingProductByProductionOrder = response.data.nonComplyingProductByProductionOrder
            }
            if (response.data.nonAddingProductByProductionOrder !== undefined) {
              self.nonAddingProductByProductionOrder = response.data.nonAddingProductByProductionOrder
            }
            document.getElementById('addActivityMbutton').removeAttribute('disabled')
            document.getElementById('addActivityMbutton').innerText = 'Agregar'
          } else {
            self.announceToast('Exito', response.data.exito, 5000, 'success')
            self.getActivities()
            self.getActivitesMarsfood()
            self.closeBlock()
            document.getElementById('addActivityMbutton').removeAttribute('disabled')
            document.getElementById('addActivityMbutton').innerText = 'Agregar'
          }
        })
        .catch(function (error) {
          console.log(error)
          document.getElementById('addActivityMbutton').removeAttribute('disabled')
          document.getElementById('addActivityMbutton').innerText = 'Agregar'
        })
    },
    getActivitesMarsfood () {
      axios.get('/api/ActivitiesMarsfood/')
        .then((response) => {
          this.massive_activities = response.data.massive_activities
          this.module_op = response.data.module_op
          this.massiveData = response.data.lines
          // response.data.lines.forEach((element, i) => {
          //   this.massiveData.push({ line: element.name_line, line_id: element.line, rowsData: [] })
          // })
          this.actividades = response.data.activities
          this.op = response.data.worplan_orders
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    nonComplyingProductQuantity: function (op, referenceBatch) {
      if (referenceBatch !== null) {
        return Object.keys(this.nonComplyingProductByProductionOrder).includes(`${op}||${referenceBatch.ref}||${referenceBatch.lote}`)
      }
      return false
    },
    nonAddingProductQuantity: function (op, referenceBatch) {
      if (referenceBatch !== null) {
        return this.nonAddingProductByProductionOrder.includes(`${op}||${referenceBatch.ref}||${referenceBatch.lote}`)
      }
      return false
    },
    nonComplyingProductQuantityInfo: function (op, referenceBatch) {
      if (referenceBatch !== null && Object.keys(this.nonComplyingProductByProductionOrder).includes(`${op}||${referenceBatch.ref}||${referenceBatch.lote}`)) {
        const data = this.nonComplyingProductByProductionOrder[`${op}||${referenceBatch.ref}||${referenceBatch.lote}`]
        return `Cantidad programada: ${data.start_quantity} // Cantidad actual: ${data.quantity}`
      }
      return ''
    },
    async getActivities () {
      await axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        this.activityList = response.data.list_data
        self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  async mounted () {
    if ((sessionStorage.token && sessionStorage.rol === '1') || sessionStorage.rol === '2' || sessionStorage.rol === '4' || sessionStorage.rol === '5') {
      const self = this
      this.getActivitesMarsfood()
      this.$store.state.storeLine.simpleUser = []
      await axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.$store.state.storeLine.simpleUser = response.data
        // response.data.forEach(function (item, index) {
        //   var nameFull = item.first_name + ' ' + item.last_name
        //   self.simpleUser.push({
        //     id: item.id,
        //     name: nameFull,
        //     avatar: '~@/assets/icons/user.png'
        //   })
        // })
      })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
  }
}
</script>

<style scoped>

fieldset {
  border: 2px solid #ccc;
  border-radius: 5px;
  border-left: 0;
  border-right: 0;
  padding: 20px 0;
  margin: 20px 0;
}

legend {
  background-color: #fff;
  padding: 0 10px;
}

.row_data_activitity {
  flex-direction: column;
  width: fit-content;
  min-width: 100%;
  margin-bottom: 19px;
}

.row_data_activitity > div {
  gap: 15px;
}

/* https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors */
.select_wrapper >>> .multiselect__content-wrapper {
  position: relative;
  width: auto;
}

.bg-multiselect-warning,
.bg-multiselect-warning >>> .multiselect__tags,
.bg-multiselect-warning >>> .multiselect__single {
  background-color: #ffc107 !important;
  color: #000 !important;
  font-weight: 500;
}

</style>

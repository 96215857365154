<template>
  <div class="form-actividad">
    <div class="wrapper-tab">
      <!-- Tab 1 -->
      <div class="row box-option mx-2 my-4 item-tab" v-if="getActivateSelectedTab === 0">
        <div class="col-md-6 py-4">
          <div v-if="!getEditActivity" class="text-left">
            <!-- Actividad -->
            <p class="font-weight-bold">Actividad</p>
            <b-form-select v-model="selected_activity" @change="validateData(selectedRef)" :options="activities_list" class="mb-3">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccione una actividad</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div v-if="!getEditActivity" class="text-left" style="display: none" ref="selReference">
            <!-- Número de OP -->
            <p class="font-weight-bold">Número de OP</p>
            <multiselect class="mb-3" v-model="selectedOP" :options="refLineList"
              @select="getProductOrderProduction(selectedOP)"
              placeholder="Seleccione un numero de op" >
            </multiselect>
          </div>
          <div v-if="!getEditActivity" class="text-left" style="display: none" ref="selProductOp">
            <!-- Referencias por OP -->
            <p class="font-weight-bold">Referencias - lote</p>
            <multiselect class="mb-3" v-model="selectedRef" :options="refReferencesList"
              @select="validateSeveralProcessProduct(selectedRef),getBatchOrderProduction(selectedRef)" label="text"
              placeholder="Seleccione una referencia">
            </multiselect>
          </div>
          <div v-if="!getEditActivity" class="text-left" style="display: none" ref="selReferenceLote">
            <p class="font-weight-bold">Lote</p>
            <input class="form-control mb-3" type="text" v-model="selectedLote" disabled>
            <!-- <b-form-select v-model="selectedLote" :options="refLoteList" class="mb-3">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccione un Lote</b-form-select-option>
              </template>
            </b-form-select> -->
          </div>
          <div v-if="!getEditActivity" class="text-left">
              <p class="font-weight-bold">Hora de inicio</p>
              <div class="form-group row">
                <div class="col-10">
                  <input class="form-control" type="time" v-model="$store.state.storeActivity.start_time" id="example-time-input" @change="saveStart_time()">
                </div>
              </div>
          </div>
          <div v-if="getTypeProcessProduct && !getEditActivity" class="text-left">
            <!-- Actividad -->
            <p class="font-weight-bold">Tipo de proceso</p>
            <b-form-select v-model="$store.state.storeActivity.pkTypeProcessProduct" :options="producto_process_list" @change="setMeta(valueReferenceToProcess, $store.state.storeActivity.pkTypeProcessProduct)" class="mb-3">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccione un proceso</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div v-if="getEditActivity" class="text-left py-3 px-0 col-12">
            <p class="font-weight-bold">Hora final</p>
            <div class="form-group row">
              <div class="col-10">
                <input class="form-control" type="time" v-model="$store.state.storeActivity.end_time" id="example-time-input">
              </div>
            </div>
          </div>
          <div v-if="getEditActivity && currentActivity === '100'" class="text-left py-3 px-0 col-12">
              <p v-if="statusDisplayQuantityBox" class="font-weight-bold">Producción (Cajas)</p>
              <p v-if="!statusDisplayQuantityBox" class="font-weight-bold">Producción</p>
              <b-form-input  class="custom-input m-0" v-model="produccion"></b-form-input>
          </div>
          <div v-if="getEditActivity && currentActivity === '100'" class="text-left py-3 px-0 col-12">
              <p class="font-weight-bold">Unidades de desperdicio</p>
              <b-form-input  class="custom-input m-0" v-model="wasteProduction"></b-form-input>
          </div>
          <!-- Meta -->
          <div v-if="!getEditActivity && !getActivateSecondBtn" class="text-left px-0 col-12" ref="meta" id="meta" style="display: None">
              <p v-if="statusDisplayQuantityBox" class="font-weight-bold">Meta (Cajas)</p>
              <p v-if="!statusDisplayQuantityBox" class="font-weight-bold">Meta</p>
              <b-form-input  class="meta-input custom-input m-0 w-25" v-model="meta" disabled></b-form-input>
          </div>
          <div class="text-left" v-if="statusQualityModule === true && getCurrentActivity() === creationContinueQualityControl">
            <!-- ¿Nueva orden o lote? -->
            <p class="font-weight-bold">¿Nueva orden, nuevo lote o cambio de referencia?</p>
            <b-form-select v-model="selectedOrderLote" @change="validateNewOrderLote(selectedOrderLote)" :options="[{text: 'Nuevo lote', value: 'N-L'}, {text: 'Nueva orden', value: 'N-O'}, {text: 'Cambio de referencia', value: 'C-R'}]" class="mb-3">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <!-- Nota de detalle -->
          <div class="text-left py-3 px-0 col-12" ref="note-content" id="note-content">
            <p class="font-weight-bold">Nota de detalle</p>
            <b-form-textarea rows="4"  class="note-content-input custom-input m-0 w-100 h-auto" v-model="noteContentInput" placeholder="Escriba el detalle de esta actividad."></b-form-textarea>
          </div>
        </div>
        <div v-if="!getEditActivity" class="col-md-6 py-4 border-custom-left d-flex align-items-center justify-content-center">
          <div class="text-center" v-if="!getActivateSecondBtn">
            <b-button class="btn-start-time" :class="{ 'disabled btn-lock' : dis }" @click="startProductionControlBSN();"><i class="icon-play fas fa-play fa-3x"></i></b-button>
            <p ref="btnIniciar" class="font-weight-bold my-3">Iniciar Actividad</p>
          </div>
        </div>
        <div v-if="getEditActivity" class="col-md-6 py-4 border-custom-left d-flex align-items-center justify-content-center">
          <div class="text-center" v-if="!getActivateSecondBtn">
            <b-button class="btn-start-time" :class="{ 'disabled btn-lock' : dis }" @click="verificateEndTime()"><i class="icon-play fas fa-play fa-3x"></i></b-button>
            <p ref="btnFinalizar" class="font-weight-bold my-3">Finalizar Actividad</p>
          </div>
        </div>
      </div>
      <!-- Tab 2 -->
      <div class="row box-option mx-2 my-4 mx-0 item-tab" v-if="getActivateSelectedTab === 1">
        <!-- Actividad closingQualityControl (102) --------------------------->
        <div v-if="statusQualityModule === true && currentActivity === closingQualityControl">
          <div class="col-md-12 py-4 pr-4">
            <div class="row text-left rounded">
              <!-- OP -->
              <div class="col-3">
                <p class="font-weight-bold">Orden de Producción</p>
                <b-form-input  class="custom-input m-0 w-100" v-model="$store.state.storeActivity.selectedOP" disabled></b-form-input>
              </div>
              <!-- Referencia -->
              <div class="col-4">
                <p class="font-weight-bold">Referencia</p>
                <b-form-input  class="custom-input m-0 w-100" v-model="$store.state.storeActivity.selectedRef.ref" disabled></b-form-input>
              </div>
              <!-- Lote -->
              <div class="col-2">
                <p class="font-weight-bold">Lote</p>
                <b-form-input  class="custom-input m-0 w-100" v-model="$store.state.storeActivity.selectedLote" disabled></b-form-input>
              </div>
            </div>
            <!-- Despeje de línea -->
            <div class="pt-4">
              <div class="py-3">
                <div class="h4 text-center font-app">Despeje de línea</div>
                <div class="h5 text-center font-app">¿Todo en orden?</div>
              </div>
            <div>
              <div class="container-fluid p-0">
                <div class="row wrapper-activity mb-2 align-items-center" v-for="(q,i) in $store.state.storeActivity.itemsBatch" :key="i">
                  <div class="col item-activity">
                    <p class="d-flex align-items-start text-left"><span class="badge badge-secondary number-activity mt-2">{{i + 1}}</span> <span class="ml-2">{{q.description}}</span></p>
                  </div>
                  <div class="col-3 actions-activity p-0">
                    <div class="input-actions-activity">
                        <b-input-group>
                          <!-- Group Izq -->
                          <b-input-group-prepend>
                            <b-button v-if="q.status == 'c'" size="sm" @click="changeStatus('nc', i, 0)"><i class="fas fa-caret-left"></i></b-button>
                          </b-input-group-prepend>
                          <!-- Group Center -->
                          <b-form-input v-model="q.status" type="text" class="text-white btn-lock" :class="{ 'bg-red': q.status == 'nc', 'bg-indigo': q.status == 'c' }" ></b-form-input>
                          <!-- Group Der -->
                          <b-input-group-append>
                            <b-button v-if="q.status == 'nc'" size="sm" @click="changeStatus('c', i, 0)"><i class="fas fa-caret-right"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <!-- ¿Quién va a verificar el despeje? -->
            <div class="pt-4">
              <div class="h4 text-left font-app">¿Quién va a verificar el despeje?</div>
              <div class="row pt-4">
                <div class="col">
                  <b-form-select v-model="selectedVerificate" @change="validateVericaterActivity(selectedVerificate)" :options="$store.state.storeActivity.user_list" class="mb-3">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Seleccione un usuario</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
                <div class="col-md-5">
                  <b-button class="btn-action-page py-2" size="lg" @click="addLineBatch()" :disabled = "$store.state.storeActivity.disabledBtnSend === true">{{$store.state.storeActivity.processQualityBtn}}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Actividad creationContinueQualityControl (101) --------------------------->
        <div v-if="statusQualityModule === true && currentActivity === creationContinueQualityControl">
          <div class="col-md-12 py-4 pr-4">
            <div class="col-12 text-left d-flex">
              <div class="col-3 mb-2" style="font-size:15px" @click="goBackTab0()"><i class="fa-solid fa-arrow-left"></i></div>
            </div>
            <div class="col-12 d-flex flex-wrap">
              <!-- Referencias -->
              <div class="col-12 text-left d-flex flex-row">
                <div class="col-6">
                  <p class="font-weight-bold">Referencia</p>
                  <div class="col-12 p-0">
                    <b-input class="custom-input w-100" v-model="$store.state.storeActivity.dataFake.ref" disabled></b-input>
                  </div>
                </div>
                <div class="col-3">
                  <p class="font-weight-bold">Hora</p>
                  <div class="col-12 p-0">
                    <b-form-input type="time" v-model="$store.state.storeActivity.dataFake.time"></b-form-input>
                  </div>
                </div>
                <div class="col-2">
                  <p class="font-weight-bold">Día juliano</p>
                  <div class="col-12 p-0">
                    <b-form-input type="number" v-model="$store.state.storeActivity.dataFake.julianDay" disabled></b-form-input>
                  </div>
                </div>
              </div>
              <hr>
              <!-- Bases -->
              <div class="text-left col-12 mt-4">
                <table class="w-100 table-bases">
                    <thead>
                      <tr>
                        <th><p class="font-weight-bold m-0">Bases</p></th>
                        <th><span>Lote (LT)</span></th>
                        <th><span>Lote logístico (PA)</span></th>
                        <th><span>Orden Producción</span></th>
                        <th><span>Fecha Vencimiento</span></th>
                        <th><span>Foto</span></th>
                      </tr>
                    </thead>
                    <!-- NEW -->
                    <tbody>
                      <tr v-for="(item, index) in $store.state.storeActivity.dataFake.bases" :key="index">
                        <td><strong>{{item.referencia}}</strong></td>
                        <td class="text-center">
                          <b-form-input :id="'input_base_lote_'+index" v-model="item.lote"></b-form-input>
                        </td>
                        <td class="text-center">
                          <b-form-input :id="'input_base_lotLogistico_'+index" v-model="item.loteLogistico"></b-form-input>
                        </td>
                        <td class="text-center">
                          <b-form-input :id="'input_base_orden_'+index" v-model="item.ordenProduction"></b-form-input>
                        </td>
                        <td class="text-center">
                          <b-form-input :id="'input_base_due_date_'+index" v-model="item.dueDate" type="date"></b-form-input>
                        </td>
                        <td>
                          <div class="image-upload text-center">
                            <label :for="'file-input-' + index" :id="'file-label-' + index" >
                              <div ref="icon" :data-key="index" v-if="getImagesPreview(index) == undefined">
                                <i class="fas fa-camera fa-2x color-grayLight"></i>
                              </div>
                              <img :src="getImagesPreview(index)" :id="'foto-' + index" v-if="getImagesPreview(index) != undefined" class="imagedisplay">
                            </label>
                            <input :id="'file-input-' + index" @change="previewFiles($event, index)" class="d-none" type="file" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <hr class="divider-block">
              <!-- Insumos -->
              <div class="text-left col-12 mt-4" v-if="$store.state.storeActivity.dataFake.insumos.length > 0">
                <table class="w-100 table-insumos">
                  <thead>
                    <tr>
                      <th class="col-1"><p class="font-weight-bold m-0">Insumos</p></th>
                      <th><span>Nombre</span></th>
                      <th class="col-md-2"><span>Lote</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in $store.state.storeActivity.dataFake.insumos" :key="index">
                      <td><strong>{{item.referencia}}</strong></td>
                      <td class="text-center">
                        <b-form-input v-model="item.nombre" disabled></b-form-input>
                      </td>
                      <td class="text-center">
                        <b-form-input v-model="item.lote"></b-form-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'FormActividad',
  props: {
    icon: String,
    data: Array,
    extraData: Array,
    closeBox: Function
  },
  components: {
    Multiselect
  },
  data () {
    return {
      continueProcessEndTime: false,
      displaySetReference: this.getEditActivity && this.displaySetReference === 'none' ? 'none' : 'inherit',
      getTypeProcessProduct: false,
      dis: false,
      produccion: '0',
      wasteProduction: '0',
      start_time: '00:00',
      meta: null,
      activities_data: [],
      activities_list: [],
      showNoteList: [],
      needNoteList: [],
      showNoteContent: null,
      needNoteContent: null,
      noteContentInput: '',
      producto_process_list: [],
      valueReferenceToProcess: null,
      refLineList: [],
      refLoteList: [],
      refReferencesList: [],
      selectedOrderLote: null,
      selectedVerificate: null,
      // selected_reference: null,
      // selected_codereference: null,
      // selectedLote: null,
      referencias: null,
      dataTrack: [],
      input: {
        lote: [],
        loteLogistico: [],
        ordenProduction: [],
        julian_day: []
      },
      files: [],
      isVisibleJulianDay: false,
      isVisibleHourApplied: false
    }
  },
  async mounted () {
    const self = this
    if (sessionStorage.token) {
      if (sessionStorage.rol !== 1) {
        await axios.get('/api/getRefListProductionControl/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          response.data.forEach(function (item, index) {
            self.refLineList.push(item)
          })
        }).catch(function (error) {
          alert(error)
        })
      }
      await this.$store.dispatch('getInfoProduction')
      await axios.get('/api/getActivities/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.activities.forEach(function (item, index) {
          self.activities_list.push({ value: item.activity_code, text: item.activity_code + ' - ' + item.name })
          self.activities_data.push({ code: item.activity_code, name: item.name })
        })
        self.showNoteList = response.data.show_note_list
        self.needNoteList = response.data.need_note_list
      }).catch(function (error) {
        alert(error)
      })
      if (this.activityList.length > 0) {
        self.start_time = this.activityList[this.activityList.length - 1].time_end
        self.$store.state.storeActivity.start_time = this.activityList[this.activityList.length - 1].time_start
      }
    }
  },
  computed: {
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    statusDisplayQuantityBox: {
      set (value) {
        this.$store.state.storeProduction.statusDisplayQuantityBox = value
      },
      get () {
        return this.$store.state.storeProduction.statusDisplayQuantityBox
      }
    },
    currentActivity: {
      set (value) {
        this.$store.state.storeActivity.current_activity = value
      },
      get () {
        return this.$store.state.storeActivity.current_activity
      }
    },
    showChart: {
      set (value) {
        this.$store.state.storeActivity.showChart = value
      },
      get () {
        return this.$store.state.storeActivity.showChart
      }
    },
    statusQualityModule: {
      set (value) {
        this.$store.state.storeQualityModule.statusQualityModule = value
      },
      get () {
        return this.$store.state.storeQualityModule.statusQualityModule
      }
    },
    creationContinueQualityControl: {
      set (value) {
        this.$store.state.storeQualityModule.creationContinueQualityControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.creationContinueQualityControl
      }
    },
    actionProcessControl: {
      set (value) {
        this.$store.state.storeQualityModule.actionProcessControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.actionProcessControl
      }
    },
    closingQualityControl: {
      set (value) {
        this.$store.state.storeQualityModule.closingQualityControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.closingQualityControl
      }
    },
    actionSamplingProcessControl: {
      set (value) {
        this.$store.state.storeQualityModule.actionSamplingProcessControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.actionSamplingProcessControl
      }
    },
    activityList: {
      set (value) {
        this.$store.state.storeActivity.activityList = value
      },
      get () {
        return this.$store.state.storeActivity.activityList
      }
    },
    getEditActivity () {
      const self = this
      return self.$store.state.storeActivity.edit_activity
    },
    selected_activity: {
      set (value) {
        this.$store.state.storeActivity.selected_activity = value
      },
      get () {
        return this.$store.state.storeActivity.selected_activity
      }
    },
    selectedRef: {
      set (value) {
        this.$store.state.storeActivity.selectedRef = value
      },
      get (value) {
        return this.$store.state.storeActivity.selectedRef
      }
    },
    selectedOP: {
      set (value) {
        this.$store.state.storeActivity.selectedOP = value
      },
      get (value) {
        return this.$store.state.storeActivity.selectedOP
      }
    },
    selectedLote: {
      set (value) {
        this.$store.state.storeActivity.selectedLote = value
      },
      get (value) {
        return this.$store.state.storeActivity.selectedLote
      }
    },
    bases: {
      set (value) {
        this.$store.state.storeTrack.bases = value
      },
      get () {
        return this.$store.state.storeTrack.bases
      }
    },
    selectedLine: {
      set (value) {
        this.$store.state.storeActivity.selectedLine = value
      },
      get () {
        return this.$store.state.storeActivity.selectedLine
      }
    },
    getLineListWithTeam () {
      return this.$store.state.lineListWithTeam
    },
    getActivateSecondBtn () {
      return this.$store.state.storeActivity.activateSecondBtn
    },
    getActivateSelectedTab () {
      return this.$store.state.storeActivity.actividadFormTabs
    },
    getOrderLote () {
      return this.$store.state.storeActivity.order_Lote
    }
  },
  methods: {
    showAlertValueRecalculate (idElement) {
      if (idElement === 'julianDay') {
        this.isVisibleJulianDay = !this.isVisibleJulianDay
      }
      if (idElement === 'hourApplied') {
        this.isVisibleHourApplied = !this.isVisibleHourApplied
      }
    },
    async getBatchOrderProduction (referenceValue) {
      this.$refs.selReferenceLote.style.display = ''
      this.selectedLote = null
      if (referenceValue.lote) {
        this.selectedLote = referenceValue.lote
      } else {
        this.selectedLote = null
      }
    },
    async getProductOrderProduction (value) {
      const self = this
      this.refReferencesList = []
      await axios.post('/api_orders/getReferencesByOp/', { OP: value }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        response.data.forEach((item, index) => {
          this.refReferencesList.push({ ref: item.product_code__reference, lote: item.batch, text: item.product_code__reference + ' - ' + item.batch })
        })
        self.$refs.selProductOp.style.display = ''
      }).catch(function (error) {
        console.error(error)
      })
    },
    getCurrentActivity () {
      return this.$store.state.storeActivity.current_activity
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    closeBlock: function () {
      this.$store.commit('actionBlockOptions', false)
      this.$store.commit('actionOrvelay', false)
    },
    async validateData (value) {
      const self = this
      self.$store.state.storeActivity.pkTypeProcessProduct = null
      self.valueReferenceToProcess = null
      self.producto_process_list = []
      self.getTypeProcessProduct = false
      self.selectedRef = null
      self.selectedOP = null
      self.showNoteContent = null
      self.needNoteContent = null
      var operatorsQuantity = null
      this.$store.commit('actionSetActivateSecondBtn', false) // QUiza eliminar
      this.$store.commit('actionSetCurrentActivity', 0) // QUiza eliminar
      await axios.get('/api/getOperatorsQuantity/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error) {
          self.announceToast(response.data.error.title, response.data.error.content, 10000, 'danger')
          self.selected_activity = null
        } else {
          operatorsQuantity = response.data.operators_quantity
        }
      })
      if (self.selected_activity === 100) {
        await this.$store.state.storeProduction.infoProduction.data.forEach(function (item, index) {
          if (value === item.reference) {
            var standard = item.ppk_standard
            self.meta = Math.round((standard * operatorsQuantity))
          }
          if (value === item.code_op) {
            var standardOP = item.ppk_standard
            self.meta = Math.round((standardOP * operatorsQuantity))
          }
        })
        self.$refs.meta.style.display = ''
        // self.$refs.selReference.style.display = ''
        // if (self.module_op === true) {
        //   self.$refs.selReferenceLote.style.display = ''
        //   self.$refs.selProductOp.style.display = ''
        // }
      } else {
        self.$refs.meta.style.display = 'None'
        self.$refs.selReference.style.display = ''
        if (self.module_op === true) {
          self.$refs.selReferenceLote.style.display = 'None'
          self.$refs.selProductOp.style.display = 'None'
        }
      }
      if (self.showNoteList.includes(self.selected_activity)) {
        self.showNoteContent = true
      }
      if (self.needNoteList.includes(self.selected_activity)) {
        self.needNoteContent = true
      }
      let continueProcess = true
      await axios.post('/api_quality/validateRecordsQuality/', { codeActivity: self.selected_activity }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        continueProcess = response.data
        self.statusQualityModule = continueProcess.status_module
      }).then(function () {
        if (continueProcess.error !== undefined) {
          self.selected_activity = null
          continueProcess.error.forEach(function (item) {
            self.$store.state.storeQualityModule = item
            self.$bvModal.show(item.type)
          })
        } else {
          if (continueProcess.continue !== true) {
            self.announceToast(continueProcess.error_type.title, continueProcess.error_type.content, 10000, 'danger')
            self.selected_activity = null
          }
        }
      }).then(function () {
        if (self.selected_activity === 100) {
          self.$store.state.storeProduction.infoProduction.data.forEach(function (item, index) {
            if (value === item.reference) {
              var standard = item.ppk_standard
              self.meta = Math.round((standard * operatorsQuantity))
            }
          })
          self.$refs.meta.style.display = ''
          self.$refs.selReference.style.display = ''
        } else if (self.$store.state.storeQualityModule.statusQualityModule === true) {
          self.$store.commit('actionSetCurrentActivity', self.selected_activity)
          if ((self.getCurrentActivity() === self.$store.state.storeQualityModule.creationContinueQualityControl || self.getCurrentActivity() === self.$store.state.storeQualityModule.closingQualityControl)) {
            self.displaySetReference = 'inherit'
            self.$store.commit('actionSetActivateSecondBtn', true)
          }
          self.$refs.meta.style.display = 'None'
          self.$refs.selReference.style.display = ''
        } else {
          self.$refs.meta.style.display = 'None'
          self.$refs.selReference.style.display = ''
        }
      })
    },
    async validateSeveralProcessProduct (value) {
      const self = this
      if (value.ref) {
        self.$store.state.storeActivity.pkTypeProcessProduct = null
        self.valueReferenceToProcess = null
        self.producto_process_list = []
        self.getTypeProcessProduct = false
        if (self.selected_activity === 100) {
          self.getTypeProcessProduct = true
          await axios.post('/api/getProcessToReference/', { reference: value.ref }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            if (response.data.error !== undefined && response.data.error.length > 0) {
              response.data.error.forEach(function (error) {
                self.announceToast(error.title, error.content, error.time, 'danger')
              })
            } else {
              if (response.data.typeProcessProductStatus === true) {
                self.valueReferenceToProcess = value
                self.producto_process_list = response.data.list_process
                self.meta = '...'
              } else {
                self.producto_process_list = []
                self.setMeta(value)
              }
            }
          })
        }
      }
    },
    async setMeta (value, ownReference = false) {
      const self = this
      var operatorsQuantity = null
      await axios.get('/api/getOperatorsQuantity/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        operatorsQuantity = response.data.user_id__working_capacity__sum
      }).catch(function (error) {
        alert(error)
      })
      await axios.post('/api/getPpkStandard/', { pk: ownReference, reference: value }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          if (response.data.value === undefined) {
            self.announceToast('Recarga la página', 'Error al recibir el estándar de la referencia', 9000, 'danger')
          } else {
            var standard = response.data.value
            self.meta = Math.round(standard * operatorsQuantity)
          }
        }
      })
    },
    showCumplimiento () {
      this.$store.state.storeActivity.showCumplimiento = true
    },
    async updateProduction (pos, production) {
      const self = this
      var productionId = this.$store.state.storeActivity.production_control_id
      var productiontoday = this.produccion
      const wasteProduction = this.wasteProduction
      var finalhour = this.$store.state.storeActivity.end_time
      var nota = this.noteContentInput
      this.start_time = this.$store.state.storeActivity.end_time
      var startTime = this.$store.state.storeActivity.start_time
      var data = {
        production_control_id: productionId,
        final_hour: finalhour,
        production: productiontoday,
        waste_production: wasteProduction,
        note_content_input: nota
      }
      let processCorrectToQualityControl = false
      let error = false

      if (this.currentActivity === '100') {
        const regex = /^(?!0)\S+$/
        if (!regex.test(this.produccion)) {
          this.$bvToast.toast('Verifique que la cantidad ingresada no contenga espacios en blanco y que el primer valor no sea 0.', {
            title: 'Advertencia',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
          error = true
        }
        if (
          (parseInt(startTime.split(':')[0]) > parseInt(finalhour.split(':')[0])) ||
          (
            (parseInt(startTime.split(':')[0]) === parseInt(finalhour.split(':')[0])) && (parseInt(startTime.split(':')[1]) > parseInt(finalhour.split(':')[1]))
          )
        ) {
          this.$bvToast.toast('La hora final debe ser mayor a la inicial.', {
            title: 'Hora incorrecta',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
          error = true
        }
      }
      if (!error) {
        this.$refs.btnFinalizar.textContent = 'Cargando...'
        this.dis = true
        await axios.post('/api/endActivityControl/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach((error) => {
              self.announceToast(error.title, error.content, 10000, 'warning')
            })
            self.$refs.btnFinalizar.textContent = 'Finalizar Actividad'
            self.dis = false
          }
          if (response.data.errors === undefined) {
            if (self.currentActivity === String(self.actionProcessControl)) {
              processCorrectToQualityControl = true
            }
            axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
              self.activityList = response.data.list_data
              self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
            }).catch(function (error) {
              alert(error)
            })
            if (self.currentActivity === 100) {
              axios.get('/api/getHowAreWeDoing/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                self.deberia_llevar = response.data.deberia_llevar
                self.tiempo_transcurrido = response.data.tiempo_transcurrido
              })
            }
            self.$store.state.storeActivity.selected_activity = null
            self.$store.state.storeActivity.selectedRef = null
            self.$store.state.storeActivity.production_control_id = null
            self.$store.state.storeActivity.selectedLine = null
            self.$store.state.storeActivity.edit_activity = false
            self.$bvToast.toast('Actividad guardada con exíto', {
              title: 'Actividad',
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })
            self.produccion = 0
            self.wasteProduction = 0
            self.noteContentInput = ''
            self.$refs.btnFinalizar.textContent = 'Iniciar Actividad'
            self.dis = false
            self.loadScroll()
            self.closeBlock()
          }
        }).catch(function (error) {
          alert(error)
        })
      }
      // if (this.currentActivity === '100') {
      //   const regex = /^(?!0)\S+$/
      //   if (!regex.test(this.produccion)) {
      //     this.$bvToast.toast('Verifique que la cantidad ingresada no contenga espacios en blanco y que el primer valor no sea 0.', {
      //       title: 'Advertencia',
      //       variant: 'danger',
      //       autoHideDelay: 5000,
      //       solid: true
      //     })
      //   } else if (
      //     (parseInt(startTime.split(':')[0]) < parseInt(finalhour.split(':')[0])) ||
      //     ((
      //       parseInt(startTime.split(':')[0]) === parseInt(finalhour.split(':')[0])) && (parseInt(startTime.split(':')[1]) < parseInt(finalhour.split(':')[1])
      //     )
      //     )) {
      //     this.$refs.btnFinalizar.textContent = 'Cargando...'
      //     this.dis = true
      //     await axios.post('/api/endActivityControl/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //       if (response.data.errors !== undefined && response.data.errors.length > 0) {
      //         response.data.errors.forEach((error) => {
      //           self.announceToast(error.title, error.content, 10000, 'warning')
      //         })
      //         this.$refs.btnFinalizar.textContent = 'Finalizar Actividad'
      //       }
      //       if (response.data.errors !== undefined && response.data.errors.length === 0) {
      //         processCorrectToQualityControl = true
      //         axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //           self.activityList = response.data.list_data
      //         }).catch(function (error) {
      //           alert(error)
      //         })
      //         if (self.$store.state.storeActivity.selected_activity === 100) {
      //           axios.get('/api/getHowAreWeDoing/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //             self.deberia_llevar = response.data.deberia_llevar
      //             self.tiempo_transcurrido = response.data.tiempo_transcurrido
      //           })
      //         }
      //         self.$store.state.storeActivity.selected_activity = null
      //         self.$store.state.storeActivity.selectedRef = null
      //         self.$store.state.storeActivity.production_control_id = null
      //         self.$store.state.storeActivity.selectedLine = null
      //         self.$store.state.storeActivity.edit_activity = false
      //         this.$bvToast.toast('Actividad guardada con exíto', {
      //           title: 'Actividad',
      //           variant: 'success',
      //           autoHideDelay: 5000,
      //           solid: true
      //         })
      //         this.produccion = 0
      //         this.wasteProduction = 0
      //         this.noteContentInput = ''
      //         this.$refs.btnFinalizar.textContent = 'Iniciar Actividad'
      //         this.dis = false
      //         this.loadScroll()
      //         this.closeBlock()
      //       }
      //     }).catch(function (error) {
      //       alert(error)
      //     })
      //   }
      // } else {
      //   if ((parseInt(startTime.split(':')[0])) < parseInt(finalhour.split(':')[0])) {
      //     this.$refs.btnFinalizar.textContent = 'Cargando...'
      //     this.dis = true
      //     await axios.post('/api/endActivityControl/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //     }).catch(function (error) {
      //       alert(error)
      //     })
      //     await axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //       self.activityList = response.data.list_data
      //     }).catch(function (error) {
      //       alert(error)
      //     })
      //     if (self.$store.state.storeActivity.selected_activity === 100) {
      //       await axios.get('/api/getHowAreWeDoing/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //         self.deberia_llevar = response.data.deberia_llevar
      //         self.tiempo_transcurrido = response.data.tiempo_transcurrido
      //       })
      //     }
      //     self.$store.state.storeActivity.selected_activity = null
      //     self.$store.state.storeActivity.selectedRef = null
      //     self.$store.state.storeActivity.production_control_id = null
      //     self.$store.state.storeActivity.selectedLine = null
      //     self.$store.state.storeActivity.edit_activity = false
      //     self.$bvToast.toast('Actividad guardada con exíto', {
      //       title: 'Actividad',
      //       variant: 'success',
      //       autoHideDelay: 5000,
      //       solid: true
      //     })
      //     self.produccion = 0
      //     self.wasteProduction = 0
      //     this.noteContentInput = ''
      //     this.$refs.btnFinalizar.textContent = 'Iniciar Actividad'
      //     this.dis = false
      //     self.closeBlock()
      //     self.loadScroll()
      //   } else if ((parseInt(startTime.split(':')[0])) === parseInt(finalhour.split(':')[0])) {
      //     if ((parseInt(startTime.split(':')[1])) < parseInt(finalhour.split(':')[1])) {
      //       this.$refs.btnFinalizar.textContent = 'Cargando...'
      //       this.dis = true
      //       await axios.post('/api/endActivityControl/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //       }).catch(function (error) {
      //         alert(error)
      //       })
      //       await axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //         self.activityList = response.data.list_data
      //       }).catch(function (error) {
      //         alert(error)
      //       })
      //       if (self.$store.state.storeActivity.selected_activity === 100) {
      //         await axios.get('/api/getHowAreWeDoing/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //           self.deberia_llevar = response.data.deberia_llevar
      //           self.tiempo_transcurrido = response.data.tiempo_transcurrido
      //         })
      //       }
      //       self.$store.state.storeActivity.selected_activity = null
      //       self.$store.state.storeActivity.selectedRef = null
      //       self.$store.state.storeActivity.production_control_id = null
      //       self.$store.state.storeActivity.selectedLine = null
      //       self.$store.state.storeActivity.edit_activity = false
      //       self.$bvToast.toast('Actividad guardada con exíto', {
      //         title: 'Actividad',
      //         variant: 'success',
      //         autoHideDelay: 5000,
      //         solid: true
      //       })
      //       self.produccion = 0
      //       self.wasteProduction = 0
      //       this.noteContentInput = ''
      //       this.$refs.btnFinalizar.textContent = 'Iniciar Actividad'
      //       this.dis = false
      //       self.closeBlock()
      //       self.loadScroll()
      //     }
      //   }
      // }
      self.$store.dispatch('statusModulesEnabled').then(function () {
        if (processCorrectToQualityControl === true && self.statusQualityModule === true) {
          self.getDataCriterionEvaluate().then(function () {
            self.$bvModal.show('modal-quality-product')
          })
        }
      })
    },
    async getDataCriterionEvaluate () {
      const self = this
      await axios.post('/api_quality/getDataCriterionEvaluate/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.criterion !== undefined) {
          self.$store.state.storeQualityModule.dataFakeQualityProduct = response.data.criterion
        }
      })
    },
    async startProductionControlBSN () {
      const self = this
      if (this.selected_activity == null) {
        this.$bvToast.toast('No has seleccionado una actividad.', {
          title: 'Actividad no seleccionada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (this.selectedOP == null) {
        this.$bvToast.toast('No has seleccionado una OP.', {
          title: 'OP no seleccionada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (this.selectedRef == null) {
        this.$bvToast.toast('No has seleccionado una Referencia.', {
          title: 'Referencia no seleccionada',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else if (this.selectedLote == null) {
        this.$bvToast.toast('No has seleccionado un lote.', {
          title: 'Lote no seleccionado',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      // } else if (this.start_time === '-') {
      //   this.$bvToast.toast('No has seleccionado un lote.', {
      //     title: 'Lote no seleccionado',
      //     variant: 'danger',
      //     autoHideDelay: 5000,
      //     solid: true
      //   })
      } else if (self.getTypeProcessProduct === true && self.$store.state.storeActivity.pkTypeProcessProduct === null) {
        self.announceToast('Tipo de proceso faltante', 'No has seleccionado el tipo de proceso para la referencia.', 9000, 'danger')
      } else {
        self.$refs.btnIniciar.textContent = 'Cargando...'
        self.dis = true
        var data = { activity_code: this.selected_activity, codeOp: this.selectedOP, goal: this.meta, start_time: this.start_time, batch: this.selectedLote, note_content_input: this.noteContentInput, reference: this.selectedRef.ref }
        if (self.$store.state.storeActivity.pkTypeProcessProduct !== null) {
          data.pkTypeProcessProduct = self.$store.state.storeActivity.pkTypeProcessProduct
        }
        await axios.post('/api/startActivityControl/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach((error) => {
              self.announceToast(error.title, error.content, 10000, 'warning')
            })
            self.$refs.btnIniciar.textContent = 'Iniciar Actividad'
            self.dis = false
          }
          if (response.data.errors === undefined) {
            self.$store.state.storeActivity.production_control_id = response.data.production_control
            self.selected_activity = null
            self.selectedOP = null
            self.selectedLote = null
            self.selectedRef = null
            self.noteContentInput = null
            self.refLoteList = []
            self.refReferencesList = []
            self.getTypeProcessProduct = false
            axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
              self.activityList = response.data.list_data
              self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
            }).catch(function (error) {
              alert(error)
            })
            self.$refs.meta.style.display = 'None'
            self.$refs.selReference.style.display = 'None'
            self.$refs.selReferenceLote.style.display = 'None'
            self.$refs.selProductOp.style.display = 'None'
            self.$store.state.storeActivity.selectedRef = null
            self.$store.state.storeActivity.selectedLine = null
            self.$refs.btnIniciar.textContent = 'Finalizar Actividad'
            self.dis = false
            self.closeBlock()
            self.loadScroll()
          }
        }).catch(function (error) {
          console.log(error)
        })
      }
    },
    loadScroll () {
      this.$root.$emit('overlayBodyMethod')
    },
    async getLastTime () {
      if (this.activityList.length > 0) {
        this.start_time = this.activityList[this.activityList.length - 1].time_start
        this.$store.state.storeActivity.start_time = this.activityList[this.activityList.length - 1].time_start
      }
    },
    async getInitialWitnessValidation () {
      const self = this
      if (self.currentActivity === '100' && self.statusQualityModule === true) {
        await axios.get('/api_quality/validateInitialWitness/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
            self.continueProcessEndTime = false
          } else {
            self.continueProcessEndTime = true
          }
        })
      } else {
        self.continueProcessEndTime = true
      }
    },
    verificateEndTime () {
      const self = this
      self.getInitialWitnessValidation().then(function () {
        if (self.continueProcessEndTime === true) {
          self.getLastTime().then(function () {
            if (self.$store.state.storeActivity.end_time > self.$store.state.storeActivity.start_time) {
              self.startTime()
              self.updateProduction()
            } else {
              self.$store.state.storeActivity.end_time = self.$store.state.storeActivity.start_time
              self.$bvToast.toast('La hora final debe ser mayor a la inicial.', {
                title: 'Hora incorrecta',
                variant: 'danger',
                autoHideDelay: 5000,
                solid: true
              })
            }
          })
        }
      })
    },
    startTime: function () {
      if (this.selectedRef != null) {
        var tempActivitys = this.$store.state.storeActivity.activitys
        var currentTime = moment().hours() + ' : ' + moment().minutes()
        tempActivitys.push({
          reference: this.selectedRef,
          activity: this.selected_activity,
          meta: this.meta,
          time_start: currentTime
        })
        this.$store.commit('actionSetActivity', tempActivitys)
      }
    },
    changeStatus: function (status, posActivity, posArray) {
      const self = this
      const linkArray = self.$store.state.storeActivity.itemsBatch[posActivity]
      this.$set(linkArray, 'status', status)
    },
    validateNewOrderLote: function (value) {
      this.$store.commit('actionSetActivityOrderLoteReference', value)
    },
    async addLineBatch () {
      const self = this
      let pkQuality = null
      const validateCriterionOk = {}
      let continueProcess = true
      if (this.$store.state.storeActivity.validater_line_batch === null) {
        continueProcess = false
        self.announceToast('Verificador de despeje', 'No ha asignado un verificador de despeje. Por favor hacerlo.', 6000, 'danger')
      }
      try {
        this.$store.state.storeActivity.itemsBatch.forEach(function (item) {
          if (item.status.toLowerCase() === 'nc') {
            continueProcess = false
            self.announceToast('Criterios de despeje', 'Todos los criterios deben encontrarse en Cumplido para seguir.', 6000, 'danger')
            throw validateCriterionOk
          }
        })
      } catch (e) {}
      if (continueProcess === true) {
        self.$store.state.storeActivity.processQualityBtn = 'Enviando...'
        self.$store.state.storeActivity.disabledBtnSend = true
        await axios.post('/api_quality/addLineBatch/', { criterion: self.$store.state.storeActivity.itemsBatch, validater: self.$store.state.storeActivity.validater_line_batch }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          const dataReceived = response.data
          if (dataReceived.error !== undefined) {
            if (dataReceived.error.length === 0) {
              pkQuality = response.data.pk_quality
              self.validateNewOrderLote(null)
              self.validateVericaterActivity(null)
              self.$store.state.storeActivity.itemsBatch = []
              self.announceToast('Despeje de Línea', response.data.message, 5000, 'success')
              self.closeBlock()
              self.$store.commit('actionSetActividadFormTabs', 0)
              self.announceToast('Guardando actividad..', 'Esto puede tardar un momento', 10000, 'warning')
              const selectedRef = self.$store.state.storeActivity.selectedRef.ref
              const selectedOP = self.$store.state.storeActivity.selectedOP
              const selectedLote = self.$store.state.storeActivity.selectedLote
              var dataActivity = { activity_code: self.closingQualityControl, codeOp: selectedOP, reference: selectedRef, goal: 0, start_time: self.$store.state.storeActivity.start_time, pk_quality: pkQuality, batch: selectedLote }
              axios.post('/api/startActivityControl/', dataActivity, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                self.$store.state.storeActivity.production_control_id = response.data.production_control
              }).then(function () {
                axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
                  self.$store.state.storeActivity.activityList = response.data.list_data
                  self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
                }).then(function () {
                  self.announceToast('Actividad', 'Actividad guardada con éxito. - -', 5000, 'success')
                  self.$store.state.storeActivity.selectedRef = null
                  self.dis = false
                  self.$store.state.storeActivity.edit_activity = true
                  self.$store.state.storeActivity.activateSecondBtn = false
                  if (self.$refs.btnFinalizar !== undefined) {
                    self.$refs.btnFinalizar.textContent = 'Iniciar Actividad'
                  }
                  self.selectedVerificate = null
                  self.closeBlock()
                  self.loadScroll()
                })
              })
            } else {
              self.$store.state.storeActivity.disabledBtnSend = false
              self.$store.state.storeActivity.processQualityBtn = 'Enviar proceso'
              dataReceived.error.forEach(function (error) {
                self.announceToast(error.title, error.content, error.time, 'danger')
              })
            }
          } else if (dataReceived.Status) {
            self.$store.state.storeActivity.disabledBtnSend = false
            self.$store.state.storeActivity.processQualityBtn = 'Enviar proceso'
            self.announceToast('Error detectado', dataReceived.Status, 10000, 'danger')
          }
        })
      }
    },
    validateVericaterActivity: function (value) {
      this.$store.commit('actionSetValidaterLineBatch', value)
    },
    saveStart_time: function () {
      if (this.start_time < this.$store.state.storeActivity.start_time) {
        this.start_time = this.$store.state.storeActivity.start_time
      } else {
        this.$store.state.storeActivity.start_time = this.start_time
        this.announceToast('Error en la hora de inicio', 'Debe ingresar una hora mayor a las horas de inicio anteriores.', 5000, 'danger')
      }
    },
    getImagesPreview: function (index) {
      const self = this
      if (self.$store.state.storeActivity.saveFilesActivities.length > 0) {
        return self.$store.state.storeActivity.saveFilesActivities[index]
      } else {
        self.$store.state.storeActivity.dataFake.bases.forEach(function (element, index) {
          self.$store.state.storeActivity.saveFilesActivities[index] = undefined
        })
        return undefined
      }
    },
    async validateReadImage (index, thisPreviewFiles, file) {
      const self = this
      await axios.post('/api_readimage/validateFunction/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.value_return) {
          self.getReconize(index, thisPreviewFiles, file)
        }
      })
    },
    async previewFiles (event, index) {
      const thisPreviewFiles = this
      thisPreviewFiles.files[index] = event.target.files
      thisPreviewFiles.$store.state.storeActivity.dataFake.bases[index].imagesPreview = event.target.files
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        thisPreviewFiles.$store.state.storeActivity.dataFake.bases[index].imagesPreview = null
        thisPreviewFiles.$store.state.storeActivity.dataFake.bases[index].url_image = undefined
        return
      }
      await thisPreviewFiles.createImage(files[0], index, thisPreviewFiles)
    },
    createImage: function (file, index, thisPreviewFiles) {
      const reader = new FileReader()
      const self = this
      reader.onload = (e) => {
        self.$set(self.$store.state.storeActivity.dataFake.bases[index], 'url_image', e.target.result)
        self.$store.state.storeActivity.saveFilesActivities.splice(index, 1, self.$store.state.storeActivity.dataFake.bases[index].url_image)
      }
      reader.readAsDataURL(file)
      this.validateReadImage(index, thisPreviewFiles, file)
    },
    async getReconize (index, thisPreviewFiles, image = false) {
      thisPreviewFiles.$bvToast.toast('Se esta procesando la imagen', {
        title: 'Trazabilidad',
        variant: 'warning',
        autoHideDelay: 5000,
        solid: true
      })
      const self = this
      let parentIndexinputs
      this.varRecognize = image
      const fileImage = document.getElementById('file-input-' + index)
      const formDataImage = new FormData()
      formDataImage.append('imageFile', fileImage.files[0])
      await axios.post('/api_readimage/getImage/', formDataImage, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        parentIndexinputs = self.$store.state.storeActivity.dataFake.bases[index]
        if (!(response.data.Referencia !== undefined && response.data.Referencia !== parentIndexinputs.referencia)) {
          self.$bvToast.toast('No concuerda la referencia ' + self.$store.state.storeActivity.dataFake.bases[index].referencia + ' de la foto que ha tomado con la línea que ha elegido', {
            title: 'Trazabilidad',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
        } else {
          // const valueLote = response.data.Lote !== undefined ? (response.data.Lote) : (parentIndexinputs.lote)
          // const valueLoteLogistico = response.data.Lote_Logistico !== undefined ? (response.data.Lote_Logistico) : (parentIndexinputs.loteLogistico)
          // const valueOrden = response.data.Orden !== undefined ? (response.data.Orden) : (parentIndexinputs.ordenProduction)
          if (self.$store.state.storeActivity.dataFake.bases[index].referencia !== undefined) {
            self.$bvToast.toast('Se ha procesado la imagen con exíto', {
              title: 'Trazabilidad',
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })
          } else {
            self.$bvToast.toast('Se ha procesado la imagen con exíto, pero no se ha podido reconocer la referencia.', {
              title: 'Trazabilidad',
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })
          }
          // self.$store.state.storeActivity.dataFake.bases[index].lote = valueLote
          // document.getElementById(`input_base_lote_${index}`).value = valueLote
          // self.$store.state.storeActivity.dataFake.bases[index].loteLogistico = valueLoteLogistico
          // document.getElementById(`input_base_lotLogistico_${index}`).value = valueLoteLogistico
          // self.$store.state.storeActivity.dataFake.bases[index].ordenProduction = valueOrden
          // document.getElementById(`input_base_orden_${index}`).value = valueOrden
        }
      })
    },
    goBackTab0 () {
      const self = this
      this.$store.commit('actionSetActividadFormTabs', 0)
      this.selectedOrderLote = this.$store.state.storeActivity.order_Lote_reference
      this.$nextTick(() => {
        self.$refs.selReference.style.display = ''
        self.$refs.selProductOp.style.display = ''
        self.$refs.selReferenceLote.style.display = ''
        if (self.meta !== null) {
          self.$refs.meta.style.display = ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .box-simple {
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .image-upload>input {
    display: none;
  }
  //This class allows us to use the z-index to hide the icon
  .imagedisplay {
    height: auto;
    width: 100px;
    border: 1px solid #bbb;
    position: static;
    z-index: 3;
  }
  .file {
    position: absolute;
    z-index: 1;
  }
  .btn-start-time {
    height: 120px;
    width: 120px;
    background-color: #00B285;
    border-radius: 50%;
    border: 0;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25) inset;
    .icon-play {
      color: #2D977C;
    }
  }
  .custom-input {
    border: 1px solid #363D8A;
    border-radius: 8px;
    height: 45px;
    text-align: left;
  }
  .input-actions-activity {
    .input-group {
      align-items: center;
    }
    input {
      height: 35px;
      text-transform: uppercase;
    }
  }
  .input-group-prepend, .input-group-append {
    width: 2rem;
  }
  .mini-alert {
    font-size: 0.7rem;
    box-sizing: border-box;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>

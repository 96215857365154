<template>
  <div class="page actividad px-3 py-5 px-md-5">
    <div class="d-flex justify-content-between align-items-center">
      <div class="header-page col-lg" :class="{'header-page col-lg': getAtLeastQualityRecord === true, 'header-page col-md-8': getAtLeastQualityRecord === false}">
          <h3>Actividad</h3>
      </div>
      <div v-if="(is_leader() || is_rol4() || is_rol5())" :class="{'col-xl-4 col-lg-6 pl-3 d-flex align-items-center justify-content-between': getAtLeastQualityRecord === true, 'col-md-4 col-lg-3': getAtLeastQualityRecord === false}">
        <div class="wrapper-option-quality" v-if="getAtLeastQualityRecord === true">
          <img src="~@/assets/icons/quality.png" class="img-fluid d-block m-auto" alt="time">
          <b-button size="lg" class="how-doing" @click="getDataToQualitySample(null, 'actionSamplingProcessControl')">Calidad de muestreo</b-button>
        </div>
        <div class="wrapper-option-time">
          <img src="~@/assets/icons/clock.png" class="img-fluid d-block m-auto" alt="time">
          <b-button size="lg" class="how-doing" @click="openComoVamos()">Cómo vamos</b-button>
        </div>
      </div>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listActivity, 'align-items-center': !listActivity }">
        <!-- Pantalla Inicio Plan de producción -->
        <div class="item-page-new" v-if="!listActivity">
          <div class="circle-icon-page d-flex justify-content-center align-items-center">
            <div>
              <img src="~@/assets/pages/activity.png" class="img-fluid">
            </div>
          </div>
          <div class="pt-4">
            <h5 class="font-weight-bold">Cree una nueva actividad</h5>
          </div>
          <div class="pt-3">
            <b-button class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar actividad</b-button>
          </div>
          <div class="pt-3">
            <b-button v-if="!is_leader()" class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar actividad</b-button>
          </div>
        </div>
        <div class="item-page-list w-100" v-if="listActivity">
          <div>
            <div class="col-sm d-flex justify-content-start">
              <span class="icon-list icon-blue-dark"><i class="fas fa-cogs fa-1x"></i></span> <span
                class="mx-3 h4">Producción</span>
            </div>
            <div class="row justify-content-end mt-4 mb-4 filters" v-if="!is_leader()">
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por actividad:</p>
                <div>
                  <b-form-input v-model="filtros.code" @change="search(filtros)" placeholder="Ingrese un codigo"
                    class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por OP:</p>
                <div>
                  <b-form-input v-model="filtros.Op" @change="search(filtros)" placeholder="Ingrese una OP"
                    class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por Referencia:</p>
                <div>
                  <b-form-input v-model="filtros.reference" @change="search(filtros)" placeholder="Ingrese una Referencia"
                    class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por línea:</p>
                <div>
                  <b-form-select v-model="filtros.line_id" @change="search(filtros)" :options="getLineList" class="mb-3">
                    <template #first>
                      <b-form-select-option value="">Todas las líneas</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por día:</p>
                <div>
                  <b-form-input type="date" v-model="filtros.date" @change="search(filtros)" placeholder="Seleccione una fecha"
                    class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 allData text-left">
                <button class="btn-filter-search w-100" style="border-width: 2px; font-weight: 500;" @click="allData()">Borrar filtros</button>
              </div>
            </div>
            <div class="table-responsive">
              <!-- -------------------------------------- -->
              <table class="table table-list list-activity">
                <thead>
                  <tr>
                    <th style="font-weight: 600;" class="font-weight">Línea</th>
                    <th style="font-weight: 600;" class="font-weight">OP</th>
                    <th style="font-weight: 600;" class="font-weight">Referencia</th>
                    <th style="font-weight: 600;" class="font-weight">Actividad</th>
                    <th style="font-weight: 600;" class="time-start text-center bg-indigo text-white font-weight"><i class="fas fa-stopwatch text-white"></i> <br> Tiempo de inicio</th>
                    <th style="font-weight: 600;" class="time-start text-center bg-red text-white font-weight"><i class="fas fa-stopwatch text-white"></i> <br> Tiempo de fin</th>
                    <th style="font-weight: 600;" class="time-start text-center bg-blue text-white font-weight"><i class="fas fa-stopwatch text-white"></i> <br> Tiempo total</th>
                    <th style="font-weight: 600;" class="font-weight">Meta</th>
                    <th style="font-weight: 600;" class="font-weight">Proceso</th>
                    <th v-if="statusDisplayQuantityBox" style="font-weight: 600;" class="font-weight">Producción (Cajas)</th>
                    <th v-if="!statusDisplayQuantityBox" style="font-weight: 600;" class="font-weight">Producción</th>
                    <th style="font-weight: 600;" class="font-weight"><span class="h5">%</span></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in activityList" :key="i" :class="{'success' : getPercentage(item.meta, item.production) >= 100, 'warning' : getPercentage(item.meta, item.production) <= 99 && getPercentage(item.meta, item.production) >= 60, 'danger' : getPercentage(item.meta, item.production) <= 59 && getPercentage(item.meta, item.production) >= 1 }">
                    <td><strong>{{item.line_name}}</strong></td>
                    <td><strong>{{item.codeOp}}</strong></td>
                    <td><strong>{{item.reference}}</strong></td>
                    <td><strong>{{item.activity}}</strong></td>
                    <td><span>{{item.time_start | time}}</span></td>
                    <td><span v-if="item.time_end">{{item.time_end}}</span><b-button variant="link" @click="stopTime(i);" v-if="!item.time_end"><i class="fas fa-stop-circle fa-2x color-red"></i></b-button></td>
                    <td><span>{{ item.time }}  min</span></td>
                    <td><span>{{item.meta}}</span></td>
                    <td><span>{{item.process_product}}</span></td>
                    <td><span>{{item.production}}</span></td>
                    <td><span class="font-weight-bold" :class="{'color-green' : getPercentage(item.meta, item.production) >= 100, 'color-yellow' : getPercentage(item.meta, item.production) <= 99 && getPercentage(item.meta, item.production) >= 60, 'color-red' : getPercentage(item.meta, item.production) <= 59 && getPercentage(item.meta, item.production) >= 1 }">{{item.percentage}}</span></td>
                    <td>
                      <b-button v-if="(is_leader() || is_rol4() || is_rol5()) && item.time_end === '-'" variant="link" @click="setProductionControl(item.pc_id);editActivity(true, item.activity, item.time_start);" key="fa_edit_leader_info_activity">
                        <i class="far fa-edit text-success" ></i>
                      </b-button>
                      <b-button v-else-if="(is_leader() || is_rol4() || is_rol5()) && item.time_end !== '-'" variant="link" @click="showInfoRecord(item)" key="fa_eye_info_activity">
                        <i class="far fa-eye text-dark"></i>
                      </b-button>
                      <b-button v-else-if="is_admin()" variant="link" @click="showInfoRecord(item)" key="fa_edit_info_activity">
                        <i class="far fa-edit text-success"></i>
                      </b-button>
                      <b-button v-if="actionSamplingProcessControl !== false && showButtonSamplingQuality(item.activity) === true && actionSamplingProcessControlRolAction.includes(getNumRol)" variant="link" @click="getDataToQualitySample(item.control_activity_id, 'actionSamplingProcessControl')">
                        <span class="sampling-activity-button position-relative">
                          <i class="fa-solid fa-box-open color-green"></i>
                          <i class="fa-solid fa-check"></i>
                        </span>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span v-if="massive_activities">
              <div class="my-3" v-if="showButton && (is_leader() || is_rol4() || is_admin()) && validateButton()">
                <b-button id="addActivitybutton" class="btn-action-page py-2" @click="showBlock(), validateLines()" size="lg">Agregar nueva actividad</b-button>
              </div>
            </span>
            <span v-else>
              <div class="my-3" v-if="showButton && (is_leader() || is_rol4()) && validateButton()">
                <b-button id="addActivitybutton" class="btn-action-page py-2" @click="showBlock(), validateLines()" size="lg">Agregar nueva actividad</b-button>
              </div>
            </span>
            <div class="my-3" v-if="is_admin()">
              <b-button class="btn-action-page py-2" @click="descargarReporte()" size="lg">Descargar Reporte</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="massive_activities" class="sidenav-pagebig100 p-4" :class="{ openSidebar: openOptions}">
      <side-block
        :icon="'activity-interna'"
        :tpl="'marsfood'"
        @closeBlock="close"
        :btnAdd="getActivateSecondBtn"
        :data="bindActivity"
        :btnBack="true"
      />
    </div>
    <div v-else class="sidenav-page p-4" :class="{ openSidebar: openOptions}">
      <side-block
        :icon="'activity-interna'"
        :tpl="'activity'"
        @closeBlock="close"
        :btnAdd="getActivateSecondBtn"
        :data="bindActivity"
        :btnBack="true"
      />
    </div>
    <!-- Modals -->
    <div>
      <!-- modal-time -------------------------------------------------------------------------------------------->
      <b-modal id="modal-time" size="xl" no-close-on-backdrop centered :hide-footer="true" :hide-header="true" >
          <div class="px my">
            <div class="d-flex align-items-center justify-content-center pb-1">
              <div class="mr-4">
                <img src="~@/assets/icons/clockfull.png" class="img-fluid d-block m-auto clockfull" alt="time">
              </div>
              <div class="h3 text-center color-bluedark font-weight-bold">Revisemos cómo vamos</div>
            </div>
            <div class="d-flex text-center align-items-stretch  justify-content-center col">
              <div class="col-8 px-4">
                <h5 class="color-grayLight mb-5">Tiempo transcurrido</h5>
                <div class="time-production text-center d-flex align-items-center justify-content-center">
                  <div class="hours">
                    <div class="board-time">
                      <div class="time-number">
                        {{ tiempo_transcurrido.split(':')[0] }}
                      </div>
                    </div>
                    <p>Hora</p>
                  </div>
                  <div class="divider-time px-2">:</div>
                  <div class="minutes">
                    <div class="board-time">
                      <div class="time-number">
                        {{ tiempo_transcurrido.split(':')[1] }}
                      </div>
                    </div>
                    <p>Minutos</p>
                  </div>
                </div>
              </div>
              <div class="col-8 border-left-table-time">
                <h5 class="color-grayLight mb-4">Deberías de llevar</h5>
                <div class="text-meta pt-4 pb-3 display-3">
                    {{ deberia_llevar }}
                </div>
                <p>En produción</p>
              </div>
            </div>
            <div class="text-center mt-3">
              <b-button class="btn-tracking" @click="$bvModal.hide('modal-time')">Continuar</b-button>
            </div>
          </div>
      </b-modal>
      <!-- modal-timer -------------------------------------------------------------------------------------------->
      <b-modal id="modal-timer" v-model="openTimer" size="xl" no-close-on-backdrop centered :hide-footer="true" :hide-header="true" >
            <div class="px-3 my-5">
            <div class="d-flex text-center align-items-stretch  justify-content-center col">
              <div class="px-4">
                <h5 class="color-grayLight mb-5">Tiempo transcurrido</h5>
                <div class="time-production text-center d-flex align-items-center justify-content-center">
                  <div class="hours">
                    <div class="board-time">
                      <div class="time-number">
                        {{clockHr}}
                      </div>
                    </div>
                    <p>Hora</p>
                  </div>
                  <div class="divider-time px-3">:</div>
                  <div class="minutes">
                    <div class="board-time">
                      <div class="time-number">
                        {{clockMin}}
                      </div>
                    </div>
                    <p>Minutos</p>
                  </div>
                  <div class="divider-time px-3">:</div>
                  <div class="seconds">
                    <div class="board-time">
                      <div class="time-number">
                        {{clockSec}}
                      </div>
                    </div>
                    <p>Segundos</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <b-button class="btn-tracking bg-red text-white" @click="closeTimer(false,1)">Finalizar</b-button>
            </div>
          </div>
      </b-modal>
      <!-- modal ALERT ------------------------------------------------------------->
      <div class="wrapper-modal-alert-process">
          <b-modal ref="close-modal" id="modal-alert-traceability" centered size="lg" :hide-footer="true" :hide-header="true" >
              <div class="d-flex justify-content-end">
                <button class="bg-transparent border-0" @click="$bvModal.hide('modal-alert-traceability')">
                  <i class="far fa-times-circle fa-2x color-grayLight"></i>
                </button>
              </div>
              <div class="py-3">
                <div class="h4 text-center font-app">
                  <span class="align-middle"><i class="fas fa-exclamation-circle color-yellow fa-1x"></i></span>
                  <span class="mx-3 align-middle color-yellow">
                    <!-- Tienes procesos de calidad sin verificar -->
                    {{$store.state.storeQualityModule.title}}
                  </span>
                </div>
              </div>
              <div class="p-4">
                <p class="h5 text-center">
                  {{$store.state.storeQualityModule.content}}
                  <!-- Por favor verifica que la persona encargada haya hecho la respectiva validación. Puedes revisar tus procesos pendientes dirigiendote al módulo de calidad. -->
                </p>
              </div>
              <div class="mt-4">
                <b-button class="btn-action-page py-2" @click="$bvModal.hide('modal-alert-traceability')" size="lg">Aceptar</b-button>
              </div>
          </b-modal>
        </div>
      <!-- modal-quality  -------------------------------------------------------------------------------------------->
      <b-modal id="modal-quality" size="lg" no-close-on-backdrop centered :hide-footer="true" :hide-header="true" >
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="$bvModal.hide('modal-quality')">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 my-1 font-app">
          <div class="wrapper-content-modal">
              <div class="px-4">
                <h5 class="color-bluedark mb-5 text-center">Control de calidad para el muestreo de producto terminado</h5>
                <div class="form-quality container-fluid">
                  <div class="row bg-grayLight py-4 rounded">
                    <!-- Referencia -->
                    <div class="col-3">
                      <p class="font-weight-bold">Orden de Producción</p>
                      <b-form-input  class="custom-input m-0 w-100" v-model="qualityOP" disabled></b-form-input>
                    </div>
                    <!-- Referencia -->
                    <div class="col-4">
                      <p class="font-weight-bold">Referencia</p>
                      <b-form-input  class="custom-input m-0 w-100" v-model="qualityRef" disabled></b-form-input>
                    </div>
                    <!-- Lote -->
                    <div class="col-2">
                      <p class="font-weight-bold">Lote</p>
                      <b-form-input  class="custom-input m-0 w-100" v-model="qualityBatch" disabled></b-form-input>
                    </div>
                    <!-- Hora -->
                    <div class="col-3">
                      <div class="text-left">
                          <p class="font-weight-bold">Hora</p>
                          <b-form-input  class="custom-input m-0 w-100" v-model="getProductSamplingHour" disabled></b-form-input>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4">
                  <div class="row bg-grayLight py-4 rounded">
                    <div class="col-5">
                      <!-- Cantidad unidades por estiba -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Cantidad unidades por estiba</p>
                          <b-form-input v-model="qualityQuantityUnitStowage" class="custom-input m-0 w-100" type="number"></b-form-input>
                      </div>
                      <!-- Cantidad unidades de muestra -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Cantidad unidades de muestra</p>
                          <b-form-input type="number" class="custom-input m-0 w-100" v-model="qualityQuantityUnitSample" ></b-form-input>
                      </div>
                      <!-- Cantidades no conformes -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Cantidades no conformes</p>
                          <b-form-input  class="custom-input m-0 w-100 border text-center" type="number" v-model="qualityQuantityNotOk"></b-form-input>
                      </div>
                      <!-- Cantidades no conformes -->
                      <div class="text-left mb-4">
                        <p class="font-weight-bold mb-1">Estado de inspección</p>
                          <!-- <b-form-input  class="custom-input m-0 w-100 border text-left" v-model="qualityStatus"></b-form-input> -->
                        <div class="input-actions-activity">
                          <b-form-spinbutton id="" :formatter-fn="(val) => labelFormatterEvalutionOrganoleptic(qualityStatus, val)" v-model="qualityStatus" min="0" max="1" wrap :class="{'bg-danger': qualityStatus === 0, 'bg-success': qualityStatus === 1}">
                            <template v-slot:decrement>
                              <span class="btn-quality-product"><i class="fas fa-caret-left fa-2x"></i></span>
                            </template>
                            <template v-slot:increment >
                              <span class="btn-quality-product"><i class="fas fa-caret-right fa-2x"></i></span>
                            </template>
                          </b-form-spinbutton>
                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                      <!-- Evaluación organoléptica -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Evaluación organoléptica</p>
                          <div class="wrapper-list-form-quality">
                            <ul class="list-unstyled list-form-quality">
                              <li class="item-list-form-quality bg-white mb-2 px-3 d-flex align-items-center justify-content-between border" v-for="(item, index) in dataCriterionEvaluationOrganoleptic" :key="index">
                                <span class="item-text">{{item.description_text}}</span>
                                <span class="item-action ml-2">
                                  <b-form-checkbox :id="'item-checkbox-' + index" v-model="item.status" :name="'item-checkbox-' + index" size="lg"></b-form-checkbox>
                                </span>
                              </li>
                            </ul>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <hr class="my-4">
          <div class="text-center">
            <b-button class="btn-confirm" @click="validateDataToQualitySample()">Confirmar</b-button>
          </div>
        </div>
      </b-modal>
      <!-- modal-quality-Products  -------------------------------------------------------------------------------------------->
      <!-- Process Control Quality Modal -->
      <b-modal id="modal-quality-product" size="lg" no-close-on-backdrop centered :hide-footer="true" :hide-header="true" >
        <div class="d-flex justify-content-end">
        </div>
        <div class="px-3 my-5 font-app">
          <div class="wrapper-content-modal">
            <div class="px-4">
              <h5 class="color-bluedark mb-5 text-center">Criterio de evaluación del producto terminado <br> ¿Cómo estuvo la calidad de tus productos?</h5>
              <div class="form-quality-product container-fluid">
                <div class="py-4">
                  <div class="container-fluid p-0">
                    <div class="row wrapper-activity mb-2 align-items-center" v-for="(q,i) in getCriterionProcessQuality" :key="i">
                      <div class="col item-activity">
                        <p class="d-flex align-items-start m-0"><span class="ml-2">{{q.description}}</span></p>
                      </div>
                      <div class="col-4 actions-activity">
                        <div class="input-actions-activity">
                            <b-form-spinbutton :id="'input-item-activity-' + i" @change="changeStatus($event, i)" :formatter-fn="labelFormatter" v-model="q.status" min="0" max="2" wrap :class="{'bg-danger': q.status == 0, 'bg-success': q.status == 1, 'bg-light': q.status == 2}">
                              <template v-slot:decrement>
                                <span class="btn-quality-product"><i class="fas fa-caret-left fa-2x"></i></span>
                              </template>
                              <template v-slot:increment >
                                <span class="btn-quality-product"><i class="fas fa-caret-right fa-2x"></i></span>
                              </template>
                            </b-form-spinbutton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4">
          <div class="text-center">
            <b-button class="btn-confirm" @click="validateDataCriterionEvaluate()">Finalizar</b-button>
          </div>
        </div>
      </b-modal>
      <!-- modal Edit Info Activity  -------------------------------------------------------------------------------------------->
      <b-modal id="modal-info-activity" size="lg" centered :hide-footer="true" :hide-header="true" >
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="$bvModal.hide('modal-info-activity')">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 py-2 my-2 font-app">
          <div class="wrapper-content-modal">
              <div class="px-4">
                <h3 class="color-bluedark mb-5 text-center">Información sobre la actividad</h3>
                <div class="form-quality container-fluid">
                  <div class="row bg-grayLight py-2 rounded justify-content-center">
                    <!-- Línea -->
                    <div class="col-4 mb-2">
                      <p class="font-weight-bold">Línea</p>
                      <b-form-input  class="custom-input m-0 w-100" disabled v-model="moreInfoActivity.lineSelected"></b-form-input>
                    </div>
                    <div class="col-4 mb-2">
                      <p class="font-weight-bold">Código OP</p>
                      <b-form-input  class="custom-input m-0 w-100" disabled v-model="moreInfoActivity.codeOp"></b-form-input>
                    </div>
                    <!-- Actividad -->
                    <div class="col-4 mb-2">
                      <p class="font-weight-bold">Actividad</p>
                      <!-- <b-form-select v-model="moreInfoActivity.activitySelected" @change="validateData(moreInfoActivity.activitySelected)" :options="activities_list" class="mb-3">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Seleccione una actividad </b-form-select-option>
                        </template>
                      </b-form-select> -->
                      <b-form-input type="number" class="custom-input m-0 w-100" v-model="moreInfoActivity.activitySelected" disabled></b-form-input>
                      <!-- <b-form-select v-model="moreInfoActivity.activitySelected" @change="validateData(moreInfoActivity.activitySelected)" :options="activities_list" class="mb-3" :disabled="!is_admin()">
                          <template #first>
                            <b-form-select-option :value="null" disabled>Seleccione una actividad </b-form-select-option>
                          </template>
                        </b-form-select> -->
                    </div>
                    <div class="col-3 mb-2">
                      <p class="font-weight-bold">Referencia</p>
                      <multiselect v-model="moreInfoActivity.refenceSelected" :show-labels="false" :options="list_references || []" placeholder="Referencia" @select="getBatchOrderProduction(moreInfoActivity.codeOp, moreInfoActivity.refenceSelected), calculate_pkStandard(moreInfoActivity.refenceSelected, false)" :disabled="!is_admin()">
                      </multiselect>
                      <!-- <b-form-input  class="custom-input m-0 w-100" disabled v-model="moreInfoActivity.refenceSelected"></b-form-input> -->
                    </div>
                    <div class="col-3 mb-2" v-if="moreInfoActivity.disabled_production">
                      <p class="font-weight-bold">Proceso</p>
                      <multiselect v-model="moreInfoActivity.proceso" :show-labels="false" label="text" :options="list_procesos || []" placeholder="Proceso" :disabled="!is_admin()" @select="calculate_pkStandard(moreInfoActivity.refenceSelected, moreInfoActivity.proceso)">
                      </multiselect>
                    </div>
                    <!-- Lote -->
                    <div class="col-3 mb-2">
                      <p class="font-weight-bold">Lote</p>
                      <multiselect v-model="moreInfoActivity.batchText" :show-labels="false" :options="list_lotes || []" placeholder="Lote" :disabled="!is_admin()">
                      </multiselect>
                      <!-- <b-form-input class="custom-input m-0 w-100" v-model="moreInfoActivity.batchText" :disabled="moreInfoActivity.disabled_production"></b-form-input> -->
                    </div>
                  </div>
                  <hr class="my-4">
                  <div class="row bg-grayLight py-2 rounded">
                    <div class="row col-12 d-flex justify-content-center">
                      <!-- Hora de inicio -->
                      <div class="col-2">
                        <p class="font-weight-bold text-center text-center">Hora de inicio</p>
                        <b-form-input type="time" class="custom-input m-0 w-100" v-model="moreInfoActivity.startTime" @change="calculate_pkStandard(moreInfoActivity.refenceSelected, moreInfoActivity.proceso)" :disabled="!is_admin()"></b-form-input>
                      </div>
                      <!-- Hora de fin -->
                      <div class="col-2">
                        <p class="font-weight-bold text-center">Hora de fin</p>
                        <b-form-input type="time" class="custom-input m-0 w-100" v-model="moreInfoActivity.endTime" @change="calculate_pkStandard(moreInfoActivity.refenceSelected, moreInfoActivity.proceso)" :disabled="!is_admin()"></b-form-input>
                      </div>
                      <!-- Tiempo total -->
                      <div class="col-2">
                        <p class="font-weight-bold text-center">Tiempo total</p>
                        <b-form-input class="custom-input m-0 w-100" v-model="moreInfoActivity.totalTime" disabled></b-form-input>
                      </div>
                    </div>
                    <div class="row col-12 d-flex justify-content-center mt-4" v-if="moreInfoActivity.activitySelected == 100">
                      <!-- Meta -->
                      <div class="col-2">
                        <p class="font-weight-bold text-center">Meta</p>
                        <b-form-input type="number" class="custom-input m-0 w-100" v-model="moreInfoActivity.metaQuantity" disabled></b-form-input>
                      </div>
                      <!-- Produccion -->
                      <div class="col-2">
                        <p class="font-weight-bold text-center">Producción</p>
                        <b-form-input type="number" class="custom-input m-0 w-100"  v-model="moreInfoActivity.productionQuantity" :disabled="moreInfoActivity.activitySelected != 100 || !is_admin()" @change="calculate_pkStandard(moreInfoActivity.refenceSelected, moreInfoActivity.proceso)"></b-form-input>
                      </div>
                      <!-- Unidades de  Desperdicio -->
                      <div class="col-2">
                        <p class="font-weight-bold text-center">Unidades Desperdicio</p>
                        <b-form-input type="number" class="custom-input m-0 w-100"  v-model="moreInfoActivity.wasteProductionQuantity" :disabled="moreInfoActivity.activitySelected != 100 || !is_admin()"></b-form-input>
                      </div>
                      <!-- Porcentaje de cantidad -->
                      <div class="col-2">
                        <p class="font-weight-bold text-center">%</p>
                        <b-form-input  class="custom-input m-0 w-100" v-model="moreInfoActivity.percentageQuantity" disabled></b-form-input>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4">
                  <div class="col-12 justify-content-center">
                    <div class="col-12 mb-3">
                      <h5 class="text-center">Cantidad de operarios: {{ moreInfoActivity.operatorsNumber }}</h5>
                    </div>
                    <!-- <div class="row col-12 d-flex justify-content-center">
                      Per operator
                      <div class="col-3 mb-2">
                        <b-form-input  class="custom-input m-0 w-100" v-model="moreInfoActivity.operatorsNumber" disabled></b-form-input>
                      </div>
                    </div> -->
                  </div>
                  <hr class="my-4" v-if="dataQualityControl">
                  <div class="col-12 justify-content-center" v-if="dataQualityControl">
                    <div class="col-12 mb-3">
                      <h5 class="text-center">Información del proceso de calidad</h5>
                    </div>
                    <div class="row col-12 d-flex justify-content-center">
                      <div class="col-3">
                        <!-- Process Control -->
                        <p class="font-weight-bold text-center">Controles de proceso</p>
                        <b-form-input  class="custom-input m-0 w-100" v-model="dataQualityControl.process_control" disabled></b-form-input>
                      </div>
                      <div class="col-3">
                        <!-- Product Sampling -->
                        <p class="font-weight-bold text-center">Muestreos de calidad</p>
                        <b-form-input  class="custom-input m-0 w-100" v-model="dataQualityControl.product_sampling" disabled></b-form-input>
                      </div>
                      <div class="col-3">
                        <!-- Line Clearance -->
                        <p class="font-weight-bold text-center">Despejes de línea</p>
                        <b-form-input  class="custom-input m-0 w-100" v-model="dataQualityControl.line_clearance" disabled></b-form-input>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4" v-if="is_admin()">
                  <div class="row py-2 rounded d-flex flex-row" v-if="is_admin()">
                    <div class="d-flex flex-row col-7">
                      <!-- Responsable -->
                      <p class="font-weight-bold col-3">Usuario responsable</p>
                      <b-form-input  class="custom-input m-0 w-100 col-9" v-model="moreInfoActivity.responsibleUser" disabled></b-form-input>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <hr class="my-4" v-if="is_admin()">
          <div class="text-center content-btn-activity" v-if="is_admin()">
            <b-button class="btn-confirm bg-save" @click="saveInfoRecordActivity()">Guardar</b-button>
            <b-button v-if="confirmDeleteInfoRecordActivity === false" class="btn-confirm bg-to-confirm" @click="confirmDeleteInfoRecordActivity = true">Eliminar</b-button>
            <b-button v-else-if="confirmDeleteInfoRecordActivity === true" class="btn-confirm bg-danger" @click="deleteInfoRecordActivity()">Confirmar</b-button>
          </div>
        </div>
      </b-modal>
      <!-- modal Quality Control - Creation Record -------------------------------------------------------------------------------------------->
      <b-modal id="modal-quality-control-create-record" size="lg" centered :hide-footer="true" :hide-header="true" >
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="$bvModal.hide('modal-quality-control-create-record')">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 py-2 my-2 font-app">
          <div class="wrapper-content-modal">
            <div class="px-4">
              <h3 class="color-bluedark text-center">Formato de calidad</h3>
              <hr>
              <h5 class="color-bluedark my-3 text-center">No hay un formato de calidad abierto, por lo que se deberá crear uno para continuar</h5>
            </div>
          </div>
          <div class="text-center content-btn-activity">
            <b-button class="btn-confirm bg-save" @click="getDataToQualitySample(qualityControlActivity, 'actionSamplingProcessControl')">Crear y continuar</b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import dataActivitys from '../../public/activity.json'
import SideBlock from '@/components/SideBlock.vue'
import moment from 'moment'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  components: {
    SideBlock,
    Multiselect
  },
  data: () => ({
    listActivity: true,
    // activities_list: [],
    list_references: [],
    list_procesos: [],
    list_lotes: [],
    massive_activities: '',
    lines_mf: '',
    filtros: {
      line_id: '',
      Op: '',
      code: '',
      reference: '',
      date: null
    },
    bindActivity: null,
    produccion: 0,
    deberia_llevar: 0,
    showButton: false,
    ok: true,
    tiempo_transcurrido: '00:00',
    currentProduction: {
      pos: 0,
      timeHours: 0,
      timeMinutes: 0,
      meta: 0,
      production: 0
    },
    clockSec: '',
    clockMin: '',
    clockHr: '',
    counter: 0,
    interval: null,
    dataCriterionEvaluationOrganoleptic: [],
    qualityControlActivity: null,
    qualityOP: null,
    qualityRef: null,
    qualityBatch: null,
    qualityHour: null,
    qualityQuantityUnitStowage: null,
    qualityQuantityUnitSample: null,
    qualityQuantityNotOk: null,
    qualityStatus: 0,
    qualityCreateRecordStatus: false,
    dataQualityControl: false,
    itemCheckbox: null,
    valueQualityProduct: 0,
    typeStatusActivity: ['RECHAZADO', 'ACEPTADO', 'N.A.'],
    typeStatusEvalutionOrganoleptic: ['Rechazado', 'Aprobado'],
    moreInfoActivity: { pcId: null, lineSelected: null, refenceSelected: null, activitySelected: null, batchText: '', startTime: null, endTime: null, totalTime: null, metaQuantity: null, processProductValue: null, productionQuantity: null, wasteProductionQuantity: null, percentageQuantity: null, responsibleUser: null, responsibleUserId: null, disabled_production: false, codeOp: null, proceso: null },
    confirmDeleteInfoRecordActivity: false
  }),
  computed: {
    getProductSamplingHour: {
      get () {
        return this.qualityHour ? this.qualityHour.toString().slice(0, 5) : ''
      }
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    statusDisplayQuantityBox: {
      set (value) {
        this.$store.state.storeProduction.statusDisplayQuantityBox = value
      },
      get () {
        return this.$store.state.storeProduction.statusDisplayQuantityBox
      }
    },
    showChart: {
      set (value) {
        this.$store.state.storeActivity.showChart = value
      },
      get () {
        return this.$store.state.storeActivity.showChart
      }
    },
    getCriterionProcessQuality: {
      set (value) {
        this.$store.state.storeQualityModule.dataFakeQualityProduct = value
      },
      get () {
        return this.$store.state.storeQualityModule.dataFakeQualityProduct
      }
    },
    creationContinueQualityControl: {
      set (value) {
        this.$store.state.storeQualityModule.creationContinueQualityControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.creationContinueQualityControl
      }
    },
    actionProcessControl: {
      set (value) {
        this.$store.state.storeQualityModule.actionProcessControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.actionProcessControl
      }
    },
    closingQualityControl: {
      set (value) {
        this.$store.state.storeQualityModule.closingQualityControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.closingQualityControl
      }
    },
    actionSamplingProcessControl: {
      set (value) {
        this.$store.state.storeQualityModule.actionSamplingProcessControl = value
      },
      get () {
        return this.$store.state.storeQualityModule.actionSamplingProcessControl
      }
    },
    actionSamplingProcessControlRolAction: {
      set (value) {
        this.$store.state.storeQualityModule.actionSamplingProcessControlRolAction = value
      },
      get () {
        return this.$store.state.storeQualityModule.actionSamplingProcessControlRolAction
      }
    },
    getAtLeastQualityRecord () {
      return this.actionSamplingProcessControl === false && this.$store.state.storeQualityModule.atLeastQualityRecord
    },
    activityList: {
      set (value) {
        this.$store.state.storeActivity.activityList = value
      },
      get () {
        return this.$store.state.storeActivity.activityList
      }
    },
    selected_activity: {
      set (value) {
        this.$store.state.storeActivity.selected_activity = value
      },
      get () {
        return this.$store.state.storeActivity.selected_activity
      }
    },
    selectedRef: {
      set (value) {
        this.$store.state.storeActivity.selectedRef = value
      },
      get (value) {
        return this.$store.state.storeActivity.selectedRef
      }
    },
    getLineList () {
      return this.$store.state.lineListWithTeam
    },
    openOptions () {
      return this.$store.state.showOptions
    },
    globalCurrentProduction () {
      return this.$store.state.storeActivity.percentage
    },
    openTimer: {
      get () {
        return this.$store.state.storeActivity.openTimer
      },
      set (value) {
        this.$store.state.storeActivity.openTimer = value
      }
    },
    getActivateSecondBtn () {
      return this.$store.state.storeActivity.activateSecondBtn
    },
    getNumRol () {
      return Number(sessionStorage.rol)
    }
  },
  async mounted () {
    var self = this
    if (sessionStorage.token && (sessionStorage.rol === '1' || sessionStorage.rol === '2' || sessionStorage.rol === '4' || sessionStorage.rol === '5')) {
      this.$store.dispatch('atLeastQualityRecordFn')
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('getLineListWithTeam')
      this.$store.commit('actionSetActivity', dataActivitys)
      this.bindActivity = this.$store.state.storeActivity.activitys
      this.$root.$on('bv::modal::show', function (bvEvent, modalId) {
        if (modalId === 'modal-timer') {
          self.timer()
        }
      })
      this.verificatePendingProcessControl()
      await axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.activityList = response.data.list_data
        self.filtros.date = response.data.used_date
        self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
        self.showButton = true
      })
      // await this.$store.dispatch('getInfoProduction')
      // await axios.get('/api/getActivities/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //   response.data.activities.forEach(function (item, index) {
      //     self.activities_list.push({ value: item.activity_code, text: item.activity_code + ' - ' + item.name, show_note: true })
      //   })
      // })
      if (this.activityList.length > 0) {
        self.start_time = this.activityList[this.activityList.length - 1].time_end
        self.$store.state.storeActivity.start_time = this.activityList[this.activityList.length - 1].time_start
      }
      axios.get('/api/ActivitiesMarsfood/')
        .then((response) => {
          this.massive_activities = response.data.massive_activities
          this.lines_mf = response.data.lines
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
  },
  methods: {
    hideNumberSpanMeta (index) {
      if (this.activityList[index].show_meta === false) {
        this.activityList[index].show_meta = true
      } else {
        this.activityList[index].show_meta = false
      }
    },
    async allData () {
      var self = this
      if ((sessionStorage.token && sessionStorage.rol === '1') || sessionStorage.rol === '2' || sessionStorage.rol === '4' || sessionStorage.rol === '5') {
        this.$store.dispatch('getLineListWithTeam')
        this.$store.commit('actionSetActivity', dataActivitys)
        this.bindActivity = this.$store.state.storeActivity.activitys
        this.$root.$on('bv::modal::show', function (bvEvent, modalId) {
          if (modalId === 'modal-timer') {
            self.timer()
          }
        })
        await axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.activityList = response.data.list_data
          self.filtros.date = response.data.used_date
          self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
          self.showButton = true
        })
        self.filtros.code = ''
        self.filtros.Op = ''
        self.filtros.reference = ''
        self.filtros.line_id = ''
      } else {
        this.$router.push({ name: 'Inicio' })
      }
      if (sessionStorage.token) {
        this.$store.dispatch('statusModulesEnabled')
        this.$store.dispatch('atLeastQualityRecordFn')
      }
    },
    validateData (data) {
      const self = this
      if (data === 100) {
        self.moreInfoActivity.disabled_production = false
      } else {
        self.moreInfoActivity.disabled_production = true
      }
    },
    editActivity (value, activity, startTime) {
      this.$store.state.storeActivity.edit_activity = value
      this.$store.state.storeActivity.current_activity = activity
      this.$store.state.storeActivity.start_time = startTime
      this.$store.state.storeActivity.end_time = startTime
      this.showBlock()
    },
    async getBatchOrderProduction (opValue, referenceValue) {
      await axios.post('/api_orders/getBatchOrderProduction/', { op_value: opValue, reference_value: referenceValue }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        this.list_lotes = []
        this.moreInfoActivity.batchText = null
        response.data.forEach((item, index) => {
          this.list_lotes.push(item.batch)
        })
      }).catch(function (error) {
        console.error(error)
      })
    },
    async calculate_pkStandard (reference, pkProcess) {
      const self = this
      var operatorsQuantity = null
      if (pkProcess !== false) {
        await axios.post('/api/getOperatorsQuantityAdmin/', { user_id: self.moreInfoActivity.responsibleUserId }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            operatorsQuantity = response.data.user_id__working_capacity__sum
          }
        })
        await axios.post('/api/getPpkStandard/', { pk: pkProcess.pk, reference: reference }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            if (response.data.value === undefined) {
              self.announceToast('Recarga la página', 'Error al recibir el estándar de la referencia', 9000, 'danger')
            } else {
              var standard = response.data.value
              // self.moreInfoActivity.metaQuantity = Math.round(standard * operatorsQuantity)
              self.moreInfoActivity.metaQuantity = Math.round(((self.calcHours(self.moreInfoActivity.startTime, self.moreInfoActivity.endTime, 'getMinutes') * standard) / 60) * operatorsQuantity)
              if (self.moreInfoActivity.metaQuantity && self.moreInfoActivity.productionQuantity) {
                self.moreInfoActivity.percentageQuantity = Math.ceil((self.moreInfoActivity.productionQuantity * 100) / self.moreInfoActivity.metaQuantity)
              }
            }
          }
        })
      } else {
        self.moreInfoActivity.proceso = null
      }
    },
    showInfoRecord (item) {
      const self = this
      if (parseInt(item.activity) === 100) {
        self.moreInfoActivity.disabled_production = true
        self.moreInfoActivity.proceso = { pk: item.process_product_id, value: item.process_product_id, text: item.process_product }
        this.list_procesos = []
        axios.post('/api/getProcessToReference/', { reference: item.reference }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach((error) => {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            if (response.data.typeProcessProductStatus === true) {
              this.list_procesos = response.data.list_process
            }
          }
        })
      } else {
        self.moreInfoActivity.disabled_production = false
      }
      self.moreInfoActivity.pcId = item.pc_id
      self.moreInfoActivity.lineSelected = item.line_name
      self.moreInfoActivity.refenceSelected = item.reference
      self.moreInfoActivity.codeOp = item.codeOp
      axios.post('/api_orders/getReferencesByOp/', { OP: item.codeOp }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        this.list_references = []
        response.data.forEach((item, index) => {
          this.list_references.push(item.product_code__reference)
        })
      }).catch(function (error) {
        console.error(error)
      })
      axios.post('/api_orders/getBatchOrderProduction/', { op_value: item.codeOp, reference_value: item.reference }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        this.list_lotes = []
        response.data.forEach((item, index) => {
          this.list_lotes.push(item.batch)
        })
      }).catch(function (error) {
        console.error(error)
      })
      axios.post('/api_quality/getDataQualityActivity/', { activity: item.control_activity_id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        self.dataQualityControl = response.data
      }).catch(function (error) {
        console.error(error)
      })
      self.moreInfoActivity.activitySelected = parseInt(item.activity)
      self.moreInfoActivity.batchText = item.batch
      self.moreInfoActivity.startTime = item.time_start
      self.moreInfoActivity.endTime = item.time_end
      if (this.moreInfoActivity.startTime === undefined || this.moreInfoActivity.endTime === undefined || this.moreInfoActivity.startTime === '-' || this.moreInfoActivity.startTime === '' || this.moreInfoActivity.endTime === '-' || this.moreInfoActivity.endTime === '') {
        self.moreInfoActivity.totalTime = '- min'
      } else {
        self.moreInfoActivity.totalTime = this.calcHours(this.moreInfoActivity.startTime, this.moreInfoActivity.endTime)
      }
      self.moreInfoActivity.metaQuantity = item.meta
      self.moreInfoActivity.processProductValue = item.process_product_value
      self.moreInfoActivity.operatorsNumber = item.operators_number
      self.moreInfoActivity.productionQuantity = item.production
      self.moreInfoActivity.wasteProductionQuantity = item.waste_production
      self.moreInfoActivity.percentageQuantity = item.percentage
      self.moreInfoActivity.responsibleUser = item.user_record
      self.moreInfoActivity.responsibleUserId = item.user_id
      // self.moreInfoActivity.show_type_input = item.show_type_input
      self.$bvModal.show('modal-info-activity')
    },
    resetValuesInfoRecordActivity () {
      this.moreInfoActivity = { pcId: null, lineSelected: null, refenceSelected: null, codeOp: null, activitySelected: null, batchText: '', startTime: null, endTime: null, totalTime: null, metaQuantity: null, processProductValue: null, productionQuantity: null, wasteProductionQuantity: null, operatorsNumber: 0, percentageQuantity: null, responsibleUser: null, responsibleUserId: null, disabled_production: false }
      this.confirmDeleteInfoRecordActivity = false
    },
    async saveInfoRecordActivity () {
      const self = this
      await axios.post('/api/saveInfoRecordActivity/', self.moreInfoActivity, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            self.activityList = response.data.list_data
            self.filtros.date = response.data.used_date
            self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
            self.showButton = true
          })
          self.resetValuesInfoRecordActivity()
          self.$bvModal.hide('modal-info-activity')
        }
      }).catch(function (error) {
        alert(error)
      })
    },
    async deleteInfoRecordActivity () {
      const self = this
      await axios.post('/api/deleteInfoRecordActivity/', self.moreInfoActivity, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          axios.post('/api/getProductionControl/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            self.activityList = response.data.list_data
            self.filtros.date = response.data.used_date
            self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
            self.showButton = true
          })
          self.resetValuesInfoRecordActivity()
          self.$bvModal.hide('modal-info-activity')
          self.announceToast('Registro eliminado', 'Se ha eliminado satisfactoriamente el registro.', '10000', 'success')
        }
      }).catch(function (error) {
        alert(error)
      })
    },
    async recalculateProductionActivity () {
      const self = this
      if (this.moreInfoActivity.startTime === undefined || this.moreInfoActivity.endTime === undefined || this.moreInfoActivity.startTime === '' || this.moreInfoActivity.startTime === '-' || this.moreInfoActivity.endTime === '-' || this.moreInfoActivity.endTime === '') {
        this.moreInfoActivity.totalTime = '- min'
        this.moreInfoActivity.percentageQuantity = '-'
      } else if (this.moreInfoActivity.startTime <= this.moreInfoActivity.endTime) {
        this.moreInfoActivity.totalTime = this.calcHours(this.moreInfoActivity.startTime, this.moreInfoActivity.endTime)
        if (this.moreInfoActivity.disabled_production === false) {
          let operatorsQuantity = null
          await axios.post('/api/getOperatorsQuantityAdmin/', { user_id: self.moreInfoActivity.responsibleUserId }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            if (response.data.error !== undefined && response.data.error.length > 0) {
              response.data.error.forEach(function (error) {
                self.announceToast(error.title, error.content, error.time, 'danger')
              })
            } else {
              operatorsQuantity = response.data.user_id__working_capacity__sum
              self.moreInfoActivity.metaQuantity = Math.round(((self.calcHours(self.moreInfoActivity.startTime, self.moreInfoActivity.endTime, 'getMinutes') * self.moreInfoActivity.processProductValue) / 60) * operatorsQuantity)
              if (self.moreInfoActivity.metaQuantity && self.moreInfoActivity.productionQuantity) {
                self.moreInfoActivity.percentageQuantity = Math.ceil((self.moreInfoActivity.productionQuantity * 100) / self.moreInfoActivity.metaQuantity)
              }
            }
          }).catch(function (error) {
            alert(error)
          })
        }
      }
    },
    async search (filtros) {
      const self = this
      await axios.post('/api/getProductionControl/', { line_id: filtros.line_id, code: filtros.code, OP: filtros.Op, reference: filtros.reference, date: filtros.date }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.activityList = response.data.list_data
        self.filtros.date = response.data.used_date
        self.$store.commit('actionSetStatusDisplayQuantityBox', response.data.statusDisplayQuantityBox)
      })
    },
    showBlock: function () {
      if (this.activityList && this.activityList.length > 0) {
        if (this.activityList[this.activityList.length - 1].time_end !== undefined) {
          this.$store.state.storeActivity.start_time = this.activityList[this.activityList.length - 1].time_end
          this.start_time = this.activityList[this.activityList.length - 1].time_end
        } else if (this.activityList[this.activityList.length - 1].time_end !== undefined) {
          this.$store.state.storeActivity.start_time = this.activityList[this.activityList.length - 1].time_start
          this.start_time = this.activityList[this.activityList.length - 1].time_start
        }
      }
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    close: function (value) {
      this.$store.commit('actionSetCurrentActivity', 0)
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetActivateSecondBtn', false)
      this.$store.commit('actionSetActividadFormTabs', 0)
    },
    getMinutes () {
      if (parseInt(this.$store.state.storeActivity.clockMins) <= 9) {
        return ('0' + String(parseInt(this.$store.state.storeActivity.clockMins)))
      } else {
        return this.$store.state.storeActivity.clockMins
      }
    },
    getHours () {
      if (parseInt(this.$store.state.storeActivity.clockHr > 9)) {
        return this.$store.state.storeActivity.clockHr
      } else {
        return '0' + this.$store.state.storeActivity.clockHr
      }
    },
    validateButton () {
      var result = false
      try {
        if (this.activityList[this.activityList.length - 1].time_end !== '-') {
          result = true
        } else {
          result = false
        }
      } catch (e) {
        result = true
      }
      return result
    },
    calcHours (start, end, point) {
      if (start && end) {
        const cleanStart = start.replace(/ /g, '')
        const cleanEnd = end.replace(/ /g, '')
        const startTime = moment.duration(cleanStart, 'hh:mm')
        const endTime = moment.duration(cleanEnd, 'hh:mm')
        const diff = endTime.subtract(startTime)
        if (point === 'modal') {
          return {
            hourModal: diff.hours(),
            minutesModal: diff.minutes()
          }
        } else if (point === 'getMinutes') {
          return diff.asMinutes()
        } else {
          return diff.asMinutes() + ' min'
        }
      } else {
        return '--'
      }
    },
    descargarReporte () {
      const self = this
      axios.post('/api_reports/getControlProductionReport/', { line: self.filtros.line_id, code: self.filtros.code, OP: self.filtros.Op, reference: self.filtros.reference, fechaInicial: self.filtros.date, fechaFinal: self.filtros.date }).then(function (response) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Reporte actividades de ' + self.filtros.date.slice(0, 10).replace(/-/g, '-') + '.csv'
        link.click()
      })
    },
    setProductionControl (value) {
      this.$store.state.storeActivity.production_control_id = value
    },
    async openComoVamos () {
      const self = this
      if (this.activityList[this.activityList.length - 1] !== undefined && this.activityList[this.activityList.length - 1].time_end === '-' && this.activityList[this.activityList.length - 1].activity === '100') {
        await axios.get('/api/getHowAreWeDoing/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.deberia_llevar = response.data.goal
          self.tiempo_transcurrido = response.data.time
          self.$bvModal.show('modal-time')
        })
      } else {
        this.$bvToast.toast('No hay actividades en proceso.', {
          title: 'No hay actividades',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
    },
    timeMin: function () {
      if (this.produccion !== 0) {
        this.produccion--
      }
    },
    timeMax: function () {
      this.produccion++
    },
    setProduction: function (meta, production) {
      if (production % 1 === 0) {
        const result = Math.ceil((production * 100) / meta)
        this.$store.commit('actionSetProduction', result)
        this.showChart = !this.showChart
      } else {
        this.$bvToast.toast('La producción ingresada es incorrecta.', {
          title: 'Producción incorrecta',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
    },
    getPercentage: function (meta, production) {
      if (meta && production) {
        const result = Math.ceil((production * 100) / meta)
        return result
      } else {
        return '--'
      }
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    is_admin () {
      if (sessionStorage.rol === '1') {
        return true
      } else {
        return false
      }
    },
    is_rol4 () {
      if (sessionStorage.rol === '4') {
        return true
      } else {
        return false
      }
    },
    is_rol5 () {
      if (sessionStorage.rol === '5') {
        return true
      } else {
        return false
      }
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    timer: function () {
      var time = moment().hour(0).minute(0).second(this.counter++)
      this.clockSec = time.format('ss')
      this.clockMin = time.format('mm')
      this.clockHr = time.format('HH')
      this.interval = setTimeout(this.timer, 1000)
    },
    async validateDataCriterionEvaluate () {
      const self = this
      let validateHasNull = false
      const dataReceived = self.$store.state.storeQualityModule.dataFakeQualityProduct
      dataReceived.forEach((item) => {
        if (item.status === 0) {
          validateHasNull = true
        }
      })
      if (validateHasNull === true) {
        self.announceToast('Criterios de evaluación', `El estado para guardar los criterios no puede ser '${self.typeStatusActivity[0]}'.`, 5000, 'danger')
      } else {
        axios.post('/api_quality/saveDataCriterionEvaluate/', { criterionEvaluate: self.$store.state.storeQualityModule.dataFakeQualityProduct }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            self.$store.state.storeQualityModule.dataFakeQualityProduct = []
            self.announceToast('Criterios de evaluación', 'Se ha finalizado la actividad correctamente', 5000, 'success')
            self.$bvModal.hide('modal-quality-product')
            self.verificatePendingProcessControl()
          }
        })
      }
    },
    async getDataCriterionEvaluate () {
      const self = this
      await axios.post('/api_quality/getDataCriterionEvaluate/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.criterion !== undefined) {
          self.$store.state.storeQualityModule.dataFakeQualityProduct = response.data.criterion
        }
      })
    },
    async verificatePendingProcessControl () {
      const self = this
      await axios.post('/api_quality/verificatePendingProcessControl/', { activityProcessControl: self.actionProcessControl }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data !== false) {
          self.getDataCriterionEvaluate().then(function () {
            self.$bvModal.show('modal-quality-product')
          })
          self.announceToast('Control de proceso', response.data, 20000, 'success')
        }
      })
    },
    async getDataToQualitySample (controlActivityId = null, process = null) {
      const self = this
      self.$bvModal.hide('modal-quality-control-create-record')
      await axios.post('/api_quality/getDataToQualitySample/', { process: process, controlActivityId: controlActivityId, createRecordStatus: self.qualityCreateRecordStatus }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.qualityControlActivity = controlActivityId
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
          self.qualityCreateRecordStatus = false
        } else if (response.data.createRecordStatus === true) {
          self.qualityCreateRecordStatus = true
          self.$bvModal.show('modal-quality-control-create-record')
        } else {
          self.qualityCreateRecordStatus = false
          self.qualityOP = response.data.op
          self.qualityRef = response.data.reference
          self.qualityBatch = response.data.batch
          self.qualityHour = response.data.hour
          self.qualityQuantityUnitStowage = response.data.quantity_x_stowage
          self.qualityQuantityUnitSample = response.data.quantity_to_sample
          self.qualityStatus = response.data.inspection_status
          self.dataCriterionEvaluationOrganoleptic = response.data.records_criterion
          self.$bvModal.show('modal-quality')
        }
      })
    },
    changeStatus: function (event, posActivity) {
      this.$store.state.storeQualityModule.dataFakeQualityProduct[posActivity].status = event
      const element = document.getElementById('input-item-activity-' + posActivity)
      const classElement = event === 1 ? 'bg-success' : event === 0 ? 'bg-danger' : 'bg-light'
      element.classList.remove('bg-success', 'bg-danger', 'bg-light')
      element.classList.add(classElement)
    },
    labelFormatter: function (value) {
      return this.typeStatusActivity[value]
    },
    labelFormatterEvalutionOrganoleptic: function (value) {
      return this.typeStatusEvalutionOrganoleptic[value]
    },
    async validateDataToQualitySample () {
      const self = this
      let validateHasNull = false
      let otherEvaluations = 0
      let evaluation100 = false
      self.dataCriterionEvaluationOrganoleptic.forEach((item) => {
        if (item.pk === 100 && item.status === true) {
          evaluation100 = true
        } else if (item.pk !== 100 && item.status === true) {
          ++otherEvaluations
        }
      })
      if (otherEvaluations > 0) {
        if (evaluation100 === true) {
          self.announceToast('Muestreo de Producto', 'Recuerde que la evaluación 100 se marca cuando todo está correcto.', 5000, 'danger')
          validateHasNull = true
        }
      } else if (evaluation100 === false) {
        self.announceToast('Muestreo de Producto', 'Debes seleccionar al menos una evaluación.', 5000, 'danger')
        validateHasNull = true
      }
      if (self.qualityQuantityUnitStowage === null || self.qualityQuantityUnitStowage === '') {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'Debe especificar las cantidades de unidades por estiba.', 5000, 'danger')
      }
      if (self.qualityQuantityUnitSample === null || self.qualityQuantityUnitSample === '') {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'Debe especificar las cantidades que se tomarán como muestra.', 5000, 'danger')
      }
      if (self.qualityQuantityNotOk === null || self.qualityQuantityNotOk === '') {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'Debe especificar las cantidades No Conformes que encuentró.', 5000, 'danger')
      }
      if (self.qualityStatus !== 1) {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'El Estado de Inspección debe encontrarse en Aprobado.', 5000, 'danger')
      }
      if (validateHasNull === false) {
        await axios.post('/api_quality/saveDataToQualitySample/', { criterion_evaluate: self.dataCriterionEvaluationOrganoleptic, unit_sample: self.qualityQuantityUnitSample, unit_stowage: self.qualityQuantityUnitStowage, quantity_not_ok: self.qualityQuantityNotOk, inspection_status: self.qualityStatus, hour: self.qualityHour, control_activity_id: self.qualityControlActivity }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            self.announceToast('Muestreo de Producto', 'Se ha guardado el muestreo del producto correctamente.', 5000, 'success')
            self.$bvModal.hide('modal-quality')
          }
        })
      }
    },
    validateLines () {
      if (this.massive_activities === true && this.lines_mf.length === 0) {
        this.announceToast('Lineas', 'No hay lineas activas', 5000, 'warning')
      }
    },
    showButtonSamplingQuality (activity) {
      return this.actionSamplingProcessControl.toString() === activity.toString()
    }
  },
  filters: {
    time (value) {
      if (value < 10) {
        return '0' + value
      } else {
        return value
      }
    },
    parseInt (value) {
      return parseInt(value)
    }
  }
}
</script>

<style lang="scss">
  #modal-quality .custom-input.textarea-logistic-batch {
    resize: none;
    background: #e9ecef;
    padding: 2%;
    height: fit-content;
    min-height: 45px;
    span {
      border-bottom: 1px solid #8e8e8e;
      padding-bottom: 5px;
      &:last-child {
        border: 0;
      }
    }
  }
  .how-doing {
    background-color: #F4F4FD;
    border: 1px solid #03185A;
    color: #03185A;
    border-radius: 15px;
    font-weight: bold;
  }
  #modal-stoptime, #modal-time, #modal-timer, #modal-quality, #modal-quality-product, #modal-info-activity {
    margin-left: 40px;
    .border-left-table-time {
      border-left: 1px solid #ccc;
    }
  }
  .text-meta {
    font-weight: bold;
    font-family: 'Barlow';
    color: #03185A;
  }
  .time-production {
    .time-number{
      font-size: 3.5rem;
      font-family: 'Barlow';
      font-weight: bold;
      padding: 10px;
    }
    .divider-time {
      font-size: 2rem;
    }
    .board-time {
      border: 1px solid #DBDBEA;
      border-radius: 10px;
      background-image: linear-gradient(#FFF, #e5e5e5);
      padding: 0 10px;
    }
  }
  .input-production {
    font-size: 2rem;
    height: 70px !important;
  }
  .btn-tracking, .btn-confirm {
    background-color: #DBDBEA;
    border: 0;
    color: #1F336C;
    font-weight: 600;
    font-size: 1.3rem;
    border-radius: 10px;
    padding: 10px 30px;
  }
  .clockfull {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 50%;
    height: 100px;
    width: 95px;
  }
  #modal-quality {
    padding-top: 20px;
    padding-bottom: 20px;
    .modal-dialog {
      width: 67%;
    }
    .custom-input, .custom-select {
      border: 0;
      height: 45px;
      border-radius: 5px;
    }
    .list-form-quality {
      .item-list-form-quality {
        border: 0;
        height: 45px;
        border-radius: 5px;
      }
      .custom-checkbox {
        display: inline-block;
      }
      .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        background-color: #00C875;
        border-radius: 5px;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      appearance: none;
      -moz-appearance: textfield;
    }
    .input-actions-activity {
      output {
        color: #FFF;
        font-weight: 500;
      }
      .bg-success output {
        background-color: var(--success);
      }
      .bg-danger output {
        background-color: var(--danger);
      }
      .bg-light output {
        background-color: var(--light);
        color: black !important;
      }
    }
  }
  #modal-quality-product {
    padding-top: 20px;
    padding-bottom: 20px;
    .input-actions-activity .bg-success output {
      background-color: var(--success);
    }
    .input-actions-activity .bg-danger output {
      background-color: var(--danger);
    }
    .input-actions-activity .bg-light output {
      background-color: var(--light);
      color: black !important;
    }
    .input-actions-activity {
      .input-group {
        align-items: center;
      }
      input {
        height: 35px;
        text-transform: uppercase;
      }
      .btn-quality-product {
        color: #CFC9DD !important;
      }
      .b-form-spinbutton {
        output {
          color: #FFF;
          height: 30px;
          padding: 3.3px;
          font-weight: 500;
          &.bg-light {
            color: #333;
          }
        }
      }
    }
  }
  @media (max-width: 991.98px) {
    #modal-time {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin: 6%;
      }
    }
  }
  #modal-info-activity {
    padding-top: 20px;
    padding-bottom: 20px;
    .modal-dialog {
      width: 86%;
    }
    .content-btn-activity {
      .btn-confirm {
        box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
      }
      .bg-save {
        color: white;
        background-color: #0bbb91;
      }
      .bg-danger {
        color: white;
      }
      .bg-to-confirm {
        color: white;
        background-color: #f24456!important;
      }
      display: flex;
      justify-content: center;
      gap:20px
    }
    .custom-input, .custom-select {
      border: 0;
      height: 45px;
      border-radius: 5px;
    }
    .list-form-quality {
      .item-list-form-quality {
        border: 0;
        height: 45px;
        border-radius: 5px;
      }
      .custom-checkbox {
        display: inline-block;
      }
      .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        background-color: #00C875;
        border-radius: 5px;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      appearance: none;
      -moz-appearance: textfield;
    }
    .input-actions-activity {
      output {
        color: #FFF;
        font-weight: 500;
      }
      .bg-success output {
        background-color: var(--success);
      }
      .bg-danger output {
        background-color: var(--danger);
      }
      .bg-light output {
        background-color: var(--light);
        color: black !important;
      }
    }
  }
  .change-meta-display {
    cursor: pointer;
    user-select: none;
  }
  .hidden-span {
    display: none;
  }
  .sampling-activity-button {
    svg:first-of-type {
      font-size:1.3em;
      color:#39C2A1;
    }
    svg:last-of-type{
      top: -0.3em;
      position: absolute;
      right: -0.3em;
      color: #343a40;
      font-size: 1.3em;
    }
  }
</style>

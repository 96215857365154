<template>
  <div class="page control-calidad px-3 py-5 px-md-5">
    <div class="d-flex justify-content-between align-items-center">
      <div class="header-page col-lg">
        <h3>Control de calidad</h3>
      </div>
    </div>
    <div class="body-page py-4">
      <div class="col-2">
        <span class="icon-list icon-blue-dark"><i class="fas fa-cogs fa-1x"></i></span> <span
          class="h4">{{ this.$store.state.storeQualityModule.dateInfo }}</span>
      </div>
      <div class="row justify-content-end my-0" v-if="rolAdmin === true">
        <div class="col-2 text-left">
          <p class="font-weight-bold">Filtrar por línea:</p>
          <div>
            <b-form-select class="mb-3" v-model="filterLine" @change="setDataTrackQuality(filterLine, filterReference)" :options="optionsLine">
              <template #first>
                <b-form-select-option :value="null">Todas</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-2 text-left">
          <p class="font-weight-bold">Filtrar por referencia:</p>
          <div>
            <b-form-select class="mb-3" v-model="filterReference" @change="setDataTrackQuality(filterLine, filterReference)" :options="optionsReference">
              <template #first>
                <b-form-select-option :value="null">Todas</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-1.5 allData text-left">
          <button type="button" class="btn-filter-search" id="changeDateToProcess" @click="modificateDateToWork()">
            Filtrar por fecha
          </button>
        </div>
      </div>
      <div class="sub-page sub-page-start d-flex justify-content-center">
        <div class="item-page-list w-100">
          <div>
            <!-- Procesos verificados -->
            <list-quality :state="'success'" :color="'green'" @openSingleProcess="openSingleProcess" @modalAllRecordsQuality="modalAllRecordsQuality" @viewReferencia="viewReferencia" :data="dataQualityVerificated" :limit="2" :lenQualityRecords="lenQualityVerificated" />
            <!-- Procesos Pendientes -->
            <list-quality :state="'info'" :color="'yellow'" @openSingleProcess="openSingleProcess" @modalAllRecordsQuality="modalAllRecordsQuality" @viewReferencia="viewReferencia" :data="dataQualityNoVerificated" :limit="2" :lenQualityRecords="lenQualityNoVerificated" />
          </div>
        </div>
        <!-- modal ALL show-process ------------------------------------------------------- -->
        <div class="wrapper-modal-all-show-process">
          <b-modal ref="close-modal" id="modal-show-all-process" centered size="lg" :hide-footer="true" :hide-header="true" >
              <div class="d-flex justify-content-end">
                <button class="bg-transparent border-0" @click="$bvModal.hide('modal-show-all-process')">
                  <i class="far fa-times-circle fa-2x color-grayLight"></i>
                </button>
              </div>
              <div class="py-3">
                <div class="h3 text-center font-app">Procesos de calidad</div>
              </div>
              <div class="pb-5">
                <div class="table-responsive">
                  <table id="myTableQuality" class="table table-list list-quality" style="width:88vw">
                    <tbody>
                      <tr class="subhead-titles">
                        <td>Línea</td>
                        <td>Orden</td>
                        <td>Referencia</td>
                        <td>Lote</td>
                        <td>Hora inicio</td>
                        <td>Hora fin</td>
                        <td>Estado</td>
                        <td></td>
                      </tr>
                      <tr v-for="(q,i) in getDataShowAllRecords()" :key="i" :class="[q.asValidator == false ? dataShowAllRecords.state:'warning']">
                        <td><strong>{{q.linea}}</strong></td>
                        <td><strong>{{q.orden}}</strong></td>
                        <td>{{q.referencia}}</td>
                        <td>{{q.lote}}</td>
                        <td> {{q.hora_inicio }} </td>
                        <td> {{q.hora_fin }} </td>
                        <td> {{q.estado}} </td>
                        <td>
                          <button v-if="dataShowAllRecords.state == 'success'" class="btn py-0 px-2" @click="openSingleProcess({ dataSend: q, estado: dataShowAllRecords.state })"><i class="far fa-eye"></i></button>
                          <button v-if="dataShowAllRecords.state == 'info' && q.asValidator == false" class="btn py-0 px-1 text-success" @click="openSingleProcess({ dataSend: q, estado: dataShowAllRecords.state })"><i class="far fa-edit"></i></button>
                          <button v-if="q.asValidator == true" class="btn py-0 px-1 text-success" @click="openSingleProcess({ dataSend: q, estado: dataShowAllRecords.state })">
                            <i class="far fa-square"></i> <i class="fas fa-arrow-right"></i> <i class="far fa-check-square"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="list-pagination">
                  <b-pagination :per-page="perPage"   v-model="currentPage" :total-rows="dataShowAllRecords.data.length" aria-controls="myTableQuality" pills align="center" hide-ellipsis first-number last-number></b-pagination>
                </div>
              </div>
          </b-modal>
        </div>
        <!-- modal SINGLE show-process ---------------------------------------------------- -->
        <div class="wrapper-modal-single-show-process">
          <b-modal ref="close-modal" id="modal-show-single-process" centered size="lg" :hide-footer="true" :hide-header="true" >
              <div class="d-flex justify-content-end">
                <button class="bg-transparent border-0" @click="$bvModal.hide('modal-show-single-process')">
                  <i class="far fa-times-circle fa-2x color-grayLight"></i>
                </button>
              </div>
              <div class="py-3">
                <div class="h3 text-center font-app">Proceso de calidad</div>
              </div>
              <div class="p-4 bg-grayLight">
                <div class="col-12 d-flex align-items-center">
                  <div class="col-4 mb-4 p-0">
                    <h6>Referencia:</h6>
                    <div class="bg-white p-2 text-center">{{ openSingleData.referencia }}</div>
                  </div>
                  <div v-if="openSingleData.asValidator === false" class="col-4 d-flex align-items-center">
                    <b-button class="btn-action-page py-2 " size="lg" @click="getDataSupplies()">Ver insumos/bases</b-button>
                  </div>
                  <div v-if="openSingleData.asValidator === false" class="col-4 d-flex align-items-center justify-content-center">
                    <b-button @click="editInitialWitness" id="btn_file_input_initial_witness" class="d-flex justify-content-center align-items-center flex-row col-12">
                      <div ref="icon" class="d-flex align-items-center justify-content-center">
                        <i class="fas fa-camera fa-2x color-grayLight"></i>
                      </div>
                      <span class="d-flex justify-content-center align-items-center m-0 col-9">Testigo inicial</span>
                    </b-button>
                  </div>
                </div>
                <div class="table-responsive" v-if="openSingleData.procedure != null">
                      <table class="table list-process table-bordered">
                        <thead>
                          <tr>
                            <th>Actividad</th>
                            <th>Hora</th>
                            <th>Persona que realiza</th>
                            <th>Persona que verifica</th>
                            <th>Estado</th>
                            <th v-if="stateprocess != 'success'"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(record,i) in openSingleData.procedure" :key="i">
                            <td class="col-2" v-bind:class="{ 'main-content-gray': record.color_content === 'gray' }">{{ record.activity }}</td>
                            <td v-bind:class="{ 'content-gray': record.color_content === 'gray' }">{{ record.hour }}</td>
                            <td class="text-center" v-bind:class="{ 'content-gray': record.color_content === 'gray' }">{{ record.user_creator }}</td>

                            <td v-if="!record.subtitle" class="col-3 text-center">{{ record.user_validator }}</td>
                            <td v-if="!record.subtitle" class="text-center">{{ record.status }}</td>
                            <td colspan="2" v-if="record.subtitle" class="text-center" v-bind:class="{ 'content-gray': record.color_content === 'gray' }">{{ record.subtitle }}</td>

                            <td v-if="stateprocess != 'success'"  align="center" class="col-xs-1 text-center" v-bind:class="{ 'content-gray': record.color_content === 'gray' }">
                              <button v-if="record.asValidator == true" class="btn py-0 px-1" align="center" @click="editProcess(record)">
                                <i class="far fa-square"></i> <i class="fas fa-arrow-right"></i> <i class="far fa-check-square"></i>
                              </button>
                              <button v-else-if="(record.asValidator != true) && rolAdmin === true && record.color_content === 'white'" class="btn py-0 px-1" @click="editProcess(record)"><i class="far fa-edit"></i></button>
                              <button v-else-if="((record.asValidator == false && rolAdmin === false && record.status.toLowerCase() === 'verificado') || (record.asValidator == undefined)) && record.color_content === 'white'" class="btn py-0 px-1" @click="editProcess(record)"><i class="far fa-eye"></i></button>
                              <button v-else-if="record.asValidator == false && rolAdmin === false && record.status.toLowerCase() === 'no verificado' && record.color_content === 'white'" class="btn py-0 px-1" @click="editProcess(record)"><i class="far fa-paper-plane"></i></button>

                              <button v-else-if="record.color_content !== 'white'" class="btn py-0 px-1 text-light" @click="manageOutLineProcess(record)"><i class="far fa-eye"></i></button>
                            </td>
                            <td v-else-if="stateprocess == 'success'" v-bind:class="{ 'content-gray': record.color_content === 'gray' }">
                              <button v-if="record.color_content === 'white'" class="btn py-0 px-1" v-bind:class="record.color_content === 'white'? 'text-dark' : 'text-light'" @click="editProcess(record)"><i class="far fa-eye"></i></button>
                              <button v-else-if="record.color_content !== 'white'" class="btn py-0 px-1 text-light" @click="manageOutLineProcess(record)"><i class="far fa-eye"></i></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </div>
                <div class="bg-white p-2 text-center font-app py-0 px-2" v-if="openSingleData.procedure === null">
                  No hay procedimientos aún <br> <i class="far fa-eye-slash"></i>
                </div>
              </div>
              <div v-if="openSingleData.asValidator === false" class="py-4 d-flex justify-content-center container-btn-detail-quality">
                <b-button class="btn-action-page py-2 " size="lg" @click="getDataForVerificateProcess()">{{ openSingleData.verificateProcess }}</b-button>
                <b-button v-if="openSingleData.changeHourBtn" class="btn-action-page py-2 " size="lg" @click="showModificateDateDetailQuality()">{{ openSingleData.changeHourMessage }}</b-button>
              </div>
          </b-modal>
        </div>
        <!-- modal EDIT process ----------------------------------------------------------- -->
        <div class="wrapper-modal-edit-process">
          <b-modal ref="close-modal" id="modal-edit-process" centered size="lg" :hide-footer="true" :hide-header="true">
              <div class="d-flex justify-content-end">
                <button class="bg-transparent border-0" @click="closeModalProcess">
                  <i class="far fa-times-circle fa-2x color-grayLight"></i>
                </button>
              </div>
              <div class="py-3">
                <div class="h3 text-center font-app">{{ openSingleData.recordSpecific.title }}</div>
                <div class="h4 text-center font-app">{{ openSingleData.recordSpecific.subtitle }}</div>
              </div>
              <div v-if="openSingleData.recordSpecific.type_show === 'productSampling'">
                <div class="form-quality container-fluid modal-productSampling d-flex justify-content-center flex-column w-100">
                  <div class="row bg-grayLight py-4 rounded">
                    <!-- OP -->
                    <div class="col-3">
                      <p class="font-weight-bold">Orden Producción</p>
                      <b-form-input  class="custom-input m-0 w-100" v-model="openSingleData.infoAbout.op" disabled></b-form-input>
                    </div>
                    <!-- Referencia -->
                    <div class="col-5">
                      <p class="font-weight-bold">Referencia</p>
                      <b-form-input  class="custom-input m-0 w-100" v-model="openSingleData.referencia" disabled></b-form-input>
                    </div>
                    <!-- Lote -->
                    <div class="col-2">
                      <p class="font-weight-bold">Lote</p>
                      <b-form-input  class="custom-input m-0 w-100" v-model="openSingleData.infoAbout.lote" disabled></b-form-input>
                    </div>
                    <!-- Hora -->
                    <div class="col-2">
                      <div class="text-left">
                          <p class="font-weight-bold">Hora</p>
                          <b-form-input  class="custom-input m-0 w-100" v-model="openSingleData.recordSpecific.hour_product_sampling" disabled></b-form-input>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4">
                  <div class="row bg-grayLight py-4 rounded">
                    <div class="col-5">
                      <!-- Cantidad unidades por estiba -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Cantidad unidades por estiba</p>
                          <b-form-input type="number" v-model="openSingleData.recordSpecific.stowage_units" class="custom-input m-0 w-100" :disabled="rolAdmin !== true"></b-form-input>
                      </div>
                      <!-- Cantidad unidades de muestra -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Cantidad unidades de muestra</p>
                          <b-form-input type="number" class="custom-input m-0 w-100" v-model="openSingleData.recordSpecific.units_sample" :disabled="rolAdmin !== true"></b-form-input>
                      </div>
                      <!-- Cantidades no conformes -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Cantidades no conformes</p>
                          <b-form-input  class="custom-input m-0 w-100 border text-center" type="number" v-model="openSingleData.recordSpecific.non_compliant_units" :disabled="rolAdmin !== true"></b-form-input>
                      </div>
                      <!-- Cantidades no conformes -->
                      <div class="text-left mb-4">
                        <p class="font-weight-bold mb-1">Estado de inspección</p>
                        <div class="input-actions-activity">
                          <b-form-spinbutton v-if="rolAdmin === true" :formatter-fn="(val) => labelFormatterEvalutionOrganoleptic(openSingleData.recordSpecific.inspection_status, val)" v-model="openSingleData.recordSpecific.inspection_status" min="0" max="1" wrap :class="{'bg-danger': openSingleData.recordSpecific.inspection_status === 0, 'bg-success': openSingleData.recordSpecific.inspection_status === 1}">
                          <!-- <b-form-spinbutton v-if="rolAdmin === true" :formatter-fn="labelFormatterEvalutionOrganoleptic" v-model="openSingleData.recordSpecific.inspection_status" min="0" max="1" wrap :class="{'bg-danger': openSingleData.recordSpecific.inspection_status === 0, 'bg-success': openSingleData.recordSpecific.inspection_status === 1}"> -->
                            <template v-slot:decrement>
                              <span class="btn-quality-product"><i class="fas fa-caret-left fa-2x"></i></span>
                            </template>
                            <template v-slot:increment >
                              <span class="btn-quality-product"><i class="fas fa-caret-right fa-2x"></i></span>
                            </template>
                          </b-form-spinbutton>
                          <b-input-group v-else-if="rolAdmin === false">
                            <!-- Group Center -->
                            <b-form-input v-model="typeStatusEvalutionOrganoleptic[openSingleData.recordSpecific.inspection_status]" type="text" class="btn-lock form-status-control-process" :class="{'text-white bg-danger': openSingleData.recordSpecific.inspection_status == 0, 'text-white bg-success': openSingleData.recordSpecific.inspection_status == 1}"></b-form-input>
                          </b-input-group>
                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                      <!-- Evaluación organoléptica -->
                      <div class="text-left mb-4">
                          <p class="font-weight-bold mb-1">Evaluación organoléptica</p>
                          <div class="wrapper-list-form-quality">
                            <ul class="list-unstyled list-form-quality">
                              <li class="item-list-form-quality bg-white mb-2 px-3 d-flex align-items-center justify-content-between border" v-for="(item, index) in openSingleData.recordSpecific.records" :key="index">
                                <span class="item-text">{{item.description}}</span>
                                <span class="item-action ml-2">
                                  <b-form-checkbox :id="'item-checkbox-' + index" v-model="item.status" :name="'item-checkbox-' + index" size="lg" :disabled="rolAdmin !== true"></b-form-checkbox>
                                </span>
                              </li>
                            </ul>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="h5 text-center font-app" v-if="openSingleData.recordSpecific.messageCase !== false">{{ openSingleData.recordSpecific.messageCase }}</div>
                  <div class="mt-3" v-if="openSingleData.recordSpecific.btnStatus">
                    <b-button class="btn-action-page py-2" size="lg" @click="validateDataToQualitySample()">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="openSingleData.recordSpecific.type_show === 'suppliesShow'" class="modal-supplies-quantity d-flex justify-content-center flex-column w-100 col-12">
                <div class="form-quality container-fluid suppliesQuality">
                  <div class="text-left mb-4 p-3 container-insumos modal-content">
                    <table class="w-100 table-insumos" v-if="openSingleData.recordSpecific.type_action">
                        <thead>
                          <tr>
                            <th class="col-2"><p class="font-weight-bold m-0">Referencia</p></th>
                            <th><span>Nombre</span></th>
                            <th class="col-2"><span>Lote</span></th>
                            <th class="col-2"><span>Hora</span></th>
                            <th v-if="(openSingleData.recordSpecific.type_action === 'create' && openSingleData.recordSpecific.dataSelected && openSingleData.recordSpecific.dataSelected.is_base) || openSingleData.recordSpecific.type_action === 'show'" class="col-1"><span>Fecha vencimiento</span></th>
                            <th v-if="(openSingleData.recordSpecific.type_action === 'create' && openSingleData.recordSpecific.dataSelected && openSingleData.recordSpecific.dataSelected.is_base) || openSingleData.recordSpecific.type_action === 'show'" class="col-md-2"><span>Foto</span></th>
                          </tr>
                        </thead>
                        <tbody v-if="openSingleData.recordSpecific.type_action === 'show'">
                          <tr v-for="(item, index) in openSingleData.recordSpecific.records" :key="index" :class="{'border-left-base' : item.is_base}">
                            <td class="position-relative">
                              <span v-if="item.is_base" class="vertical-base">Base</span>
                              <strong>{{item.reference}}</strong>
                            </td>
                            <td class="text-center">
                              <b-form-input v-model="item.name" disabled></b-form-input>
                            </td>
                            <td class="text-center">
                              <b-form-input v-model="item.batch" disabled></b-form-input>
                            </td>
                            <td class="text-center">
                              <b-form-input type="time" v-model="item.hour" disabled></b-form-input>
                            </td>
                            <td class="text-center">
                              <b-form-input type="date" v-if="item.due_date" v-model="item.due_date" disabled></b-form-input>
                              <b-form-input type="text" v-if="!item.due_date" disabled></b-form-input>
                            </td>
                            <td class="text-center">
                              <div class="image-upload text-center">
                                <label for="file-input" id="file-label">
                                  <img :src="getImagesPreviewBase(item.file)" id="foto" v-if="getImagesPreviewBase(item.file) != undefined" class="imagedisplay">
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-if="openSingleData.recordSpecific.type_action === 'create'">
                          <tr>
                            <td>
                              <b-form-select v-model="openSingleData.recordSpecific.dataSelected.id" @change="changeValuesSupplyQuality(openSingleData.recordSpecific.dataSelected.id)" :options="openSingleData.recordSpecific.records">
                                <template #first>
                                  <b-form-select-option :value="null">Referencia</b-form-select-option>
                                </template>
                              </b-form-select>
                            </td>
                            <td class="text-center">
                              <b-form-input v-model="openSingleData.recordSpecific.dataSelected.name" disabled></b-form-input>
                            </td>
                            <td class="text-center">
                              <b-form-input v-model="openSingleData.recordSpecific.dataSelected.batch"></b-form-input>
                            </td>
                            <td class="text-center">
                              <b-form-input type="time" v-model="openSingleData.recordSpecific.dataSelected.hour"></b-form-input>
                            </td>
                            <td v-if="openSingleData.recordSpecific.dataSelected.is_base" class="text-center">
                              <b-form-input type="date" v-model="openSingleData.recordSpecific.dataSelected.due_date"></b-form-input>
                            </td>
                            <td v-if="openSingleData.recordSpecific.dataSelected.is_base" class="text-center p-0 m-0">
                              <div class="image-upload text-center">
                                <label for="file-input" id="file-label">
                                  <div ref="icon" v-if="getImagesPreviewCreateBase() == undefined">
                                    <i class="fas fa-camera fa-2x color-grayLight"></i>
                                  </div>
                                  <img :src="getImagesPreviewCreateBase()" id="foto" v-if="getImagesPreviewCreateBase() != undefined" class="imagedisplay">
                                </label>
                                <input id="file-input" @change="previewFilesCreateBase($event)" class="d-none" type="file"/>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                  <div v-if="openSingleData.recordSpecific.type_action && openSingleData.recordSpecific.btnStatus">
                    <b-button v-if="openSingleData.recordSpecific.type_action === 'show'" class="btn-action-page py-2" size="lg" @click="createSupplyQuality()">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                    <b-button v-if="openSingleData.recordSpecific.type_action === 'create'" class="btn-action-page py-2" size="lg" @click="saveSupplyQuality()">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="openSingleData.recordSpecific.type_show === 'initialWitness'" class="modal-witness-quantity d-flex justify-content-center flex-column w-100">
                <div class="d-flex justify-content-center align-items-center flex-row col-12">
                  <label id="label_file_input_initial_witness"  for="file_input_initial_witness" class="d-flex justify-content-center align-items-center flex-row col-10 m-y-4">
                    <div ref="icon" class="d-flex align-items-center justify-content-center" id="container_icon" v-if="getImagesPreview() == undefined">
                      <i class="fas fa-camera fa-2x color-grayLight"></i>
                    </div>
                    <img :src="getImagesPreview()" id="picture_witness" v-if="getImagesPreview() !== undefined" class="imagedisplay">
                  </label>
                  <input id="file_input_initial_witness" class="d-none" type="file" @change="previewFiles($event)" />
                </div>
                <div class="h5 text-center font-app" v-if="openSingleData.recordSpecific.messageCase !== false">{{ openSingleData.recordSpecific.messageCase }}</div>
                <div class="form-quality container-fluid initialWitnessQuality mb-3 mt-4">
                  <div v-if="openSingleData.recordSpecific.type_action && openSingleData.recordSpecific.btnStatus">
                    <b-button v-if="openSingleData.recordSpecific.type_action === 'change'" class="btn-action-page py-2" size="lg" @click="saveInitialWitness()" :disabled="openSingleData.recordSpecific.btnDisabled === true">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="openSingleData.recordSpecific.type_show === 'modificateDateDetailQuality'" class="modal-witness-quantity d-flex justify-content-center flex-column w-100">
                <div class="py-3 container-change-time-detail">
                  <div class="h6 text-center font-app text-danger mb-3">Se creará un registro de seguimiento al modificar la hora</div>
                  <div class="h4 text-center font-app d-flex flex-row justify-content-center align-center">
                    <b-form-input type="time" v-model="openSingleData.recordSpecific.hour"></b-form-input>
                    <b-button class="btn-action-page py-2" size="lg" @click="saveHourToChangeDetailQuality()">Modificar</b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="openSingleData.recordSpecific.type_show !== 'productSampling' && openSingleData.recordSpecific.type_show !== 'suppliesShow' && openSingleData.recordSpecific.type_show !== 'showDescription'" class="p-4 d-flex justify-content-center flex-column w-100" :class="{'modal-clearance-line': openSingleData.recordSpecific.type_show === 'clearanceLine'}">
                <div class="container-fluid">
                  <div class="row wrapper-activity mb-2 align-items-center" v-for="(q,i) in openSingleData.recordSpecific.records" :key="i">
                    <div class="col-8 item-activity">
                      <p class="d-flex align-items-start">
                        <span v-if="openSingleData.recordSpecific.type_show === 'clearanceLine'" class="badge badge-secondary number-activity mt-2">{{i + 1}}</span>
                        <span class="ml-2">{{q.description}}</span>
                      </p>
                    </div>
                    <div class="col-4 actions-activity">
                      <div v-if="openSingleData.recordSpecific.type_show === 'clearanceLine'" class="input-actions-activity">
                        <b-input-group>
                          <!-- Group Izq -->
                          <b-input-group-prepend v-if="openSingleData.recordSpecific.roleSupCase === true">
                            <b-button v-if="q.status == 'c'" size="sm" @click="changeStatusClearanceLine('nc', i, 0)"><i class="fas fa-caret-left"></i></b-button>
                          </b-input-group-prepend>
                          <!-- Group Center -->
                          <b-form-input v-model="q.status" type="text" class="text-white btn-lock" :class="{ 'bg-gray': q.status == 'nc', 'bg-indigo': q.status == 'c' }" ></b-form-input>
                          <!-- Group Der -->
                          <b-input-group-append v-if="openSingleData.recordSpecific.roleSupCase === true">
                            <b-button v-if="q.status == 'nc'" size="sm" @click="changeStatusClearanceLine('c', i, 0)"><i class="fas fa-caret-right"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <div v-else-if="openSingleData.recordSpecific.type_show === 'processControl'" class="input-actions-activity">
                        <b-form-spinbutton v-if="rolAdmin === true" :id="'input-item-activity-' + i" @change="changeStatusProcessControl($event, i)" :formatter-fn="labelFormatterControlProcess" min="0" max="2" wrap v-model="q.status" :class="{'bg-danger': q.status == 0, 'bg-success': q.status == 1, 'bg-light': q.status == 2}">
                          <template v-slot:decrement>
                            <span class="btn-quality-product"><i class="fas fa-caret-left fa-2x"></i></span>
                          </template>
                          <template v-slot:increment>
                            <span class="btn-quality-product"><i class="fas fa-caret-right fa-2x"></i></span>
                          </template>
                        </b-form-spinbutton>
                        <b-input-group v-else-if="rolAdmin === false">
                          <!-- Group Center -->
                          <b-form-input v-model="typeStatusActivityControlProcess[q.status]" type="text" class="btn-lock form-status-control-process" :class="{'text-white bg-danger': q.status == 0, 'text-white bg-success': q.status == 1, 'text-dark bg-light': q.status == 2}"></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                  <div class="h5 text-center font-app" v-if="openSingleData.recordSpecific.messageCase !== false">{{ openSingleData.recordSpecific.messageCase }}</div>
                  <div class="mt-3" v-if="openSingleData.recordSpecific.btnStatus">
                    <b-button v-if="openSingleData.recordSpecific.type_show === 'clearanceLine'" class="btn-action-page py-2" size="lg" @click="executeRecordSpecific()">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                    <b-button v-else-if="openSingleData.recordSpecific.type_show === 'processControl'" class="btn-action-page py-2" size="lg" @click="validateDataCriterionEvaluate()">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="openSingleData.recordSpecific.type_show === 'showDescription'" class="p-4 d-flex justify-content-center flex-column w-100">
                <div class="container-fluid">
                  <div class="mb-5 h5 text-center font-app text-success">{{ openSingleData.recordSpecific.subcontent }}</div>
                  <div class="h5 text-center font-app" v-if="openSingleData.recordSpecific.messageCase !== false"><span class="font-weight-bold">Descripción:</span> {{ openSingleData.recordSpecific.messageCase }}</div>
                </div>
              </div>
              <div v-else class="p-4 modal-process-common d-flex justify-content-center flex-column w-100" :class="{'modal-clearance-line': openSingleData.recordSpecific.type_show === 'clearanceLine'}">
                <div class="container-fluid">
                  <div class="row wrapper-activity mb-2 align-items-center" v-for="(q,i) in openSingleData.recordSpecific.records" :key="i">
                    <div class="col item-activity">
                      <p class="d-flex align-items-start">
                        <span v-if="openSingleData.recordSpecific.type_show === 'clearanceLine'" class="badge badge-secondary number-activity mt-2">{{i + 1}}</span>
                        <span class="ml-2">{{q.description}}</span>
                      </p>
                    </div>
                    <div class="col-4 actions-activity">
                      <div v-if="openSingleData.recordSpecific.type_show === 'clearanceLine'" class="input-actions-activity">
                        <b-input-group>
                          <!-- Group Izq -->
                          <b-input-group-prepend v-if="openSingleData.recordSpecific.roleSupCase === true">
                            <b-button v-if="q.status == 'c'" size="sm" @click="changeStatusClearanceLine('nc', i, 0)"><i class="fas fa-caret-left"></i></b-button>
                          </b-input-group-prepend>
                          <!-- Group Center -->
                          <b-form-input v-model="q.status" type="text" class="text-white btn-lock" :class="{ 'bg-gray': q.status == 'nc', 'bg-indigo': q.status == 'c' }" ></b-form-input>
                          <!-- Group Der -->
                          <b-input-group-append v-if="openSingleData.recordSpecific.roleSupCase === true">
                            <b-button v-if="q.status == 'nc'" size="sm" @click="changeStatusClearanceLine('c', i, 0)"><i class="fas fa-caret-right"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <div v-else-if="openSingleData.recordSpecific.type_show === 'processControl'" class="input-actions-activity">
                        <b-form-spinbutton v-if="rolAdmin === true" :id="'input-item-activity-' + i" @change="changeStatusProcessControl($event, i)" :formatter-fn="(val) => labelFormatterControlProcess(q.status, val)" min="0" max="2" wrap v-model="q.status" :class="{'bg-danger': q.status == 0, 'bg-success': q.status == 1, 'bg-light': q.status == 2}">
                          <template v-slot:decrement>
                            <span class="btn-quality-product"><i class="fas fa-caret-left fa-2x"></i></span>
                          </template>
                          <template v-slot:increment>
                            <span class="btn-quality-product"><i class="fas fa-caret-right fa-2x"></i></span>
                          </template>
                        </b-form-spinbutton>
                        <b-input-group v-else-if="rolAdmin === false">
                          <!-- Group Center -->
                          <b-form-input v-model="typeStatusActivityControlProcess[q.status]" type="text" class="btn-lock form-status-control-process" :class="{'text-white bg-danger': q.status == 0, 'text-white bg-success': q.status == 1, 'text-dark bg-light': q.status == 2}"></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                  <div class="h5 text-center font-app" v-if="openSingleData.recordSpecific.messageCase !== false">{{ openSingleData.recordSpecific.messageCase }}</div>
                  <div class="mt-3" v-if="openSingleData.recordSpecific.btnStatus">
                    <b-button v-if="openSingleData.recordSpecific.type_show === 'clearanceLine'" class="btn-action-page py-2" size="lg" @click="executeRecordSpecific()">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                    <b-button v-else-if="openSingleData.recordSpecific.type_show === 'processControl'" class="btn-action-page py-2" size="lg" @click="validateDataCriterionEvaluate()">{{ openSingleData.recordSpecific.btnMessageCase }}</b-button>
                  </div>
                </div>
              </div>
              <div class="py-3 container-change-time" v-if="openSingleData.recordSpecific.modificate_time == true">
                <div class="h3 text-center font-app mb-0">Modificar hora</div>
                <div class="h6 text-center font-app text-danger mb-3">Se creará un registro de seguimiento al modificar la hora</div>
                <div class="h4 text-center font-app d-flex flex-row justify-content-center align-center">
                  <b-form-input type="time" v-model="openSingleData.recordSpecific.value_time"></b-form-input>
                  <b-button class="btn-action-page py-2" size="lg" @click="changeTimeQualityRecord()">Modificar</b-button>
                </div>
              </div>
          </b-modal>
        </div>
        <!-- modal ALERT ----------------------------------------------------------- -->
        <div class="wrapper-modal-alert-process">
          <b-modal ref="close-modal" id="modal-alert-process" centered size="lg" :hide-footer="true" :hide-header="true" >
              <div class="d-flex justify-content-end">
                <button class="bg-transparent border-0" @click="$bvModal.hide('modal-alert-process')">
                  <i class="far fa-times-circle fa-2x color-grayLight"></i>
                </button>
              </div>
              <div class="py-3">
                <div class="h4 text-center font-app">
                  <span class="align-middle"><i class="fas fa-exclamation-circle color-yellow fa-1x"></i></span>
                  <span class="mx-3 align-middle color-yellow">Tienes un nuevo proceso por verificar</span>
                </div>
              </div>
              <div class="p-4">
                <p class="h5 text-center">
                  Te han asignado verificar un proceso de calidad. Puedes ver los procesos pendientes. Recuerda verificarlo lo más pronto posible.
                </p>
              </div>
              <div class="mt-4">
                <b-button class="btn-action-page py-2" @click="$bvModal.hide('modal-alert-process')" size="lg">Aceptar</b-button>
              </div>
          </b-modal>
        </div>
        <!-- modal FILTER DATE ----------------------------------------------------------- -->
        <b-modal ref="view-per-day" id="view-per-day" centered size="xl" :hide-footer="true" :hide-header="true">
          <div class="px-3 mb-3">
            <div class="d-flex justify-content-between">
              <h4 class="color-bluedark col-9 m-0">Filtrar por día</h4>
              <button class="bg-transparent border-0" @click="$bvModal.hide('view-per-day')">
                <i class="far fa-times-circle fa-2x color-grayLight"></i>
              </button>
            </div>
            <hr>
            <div class="row d-flex flex-column justify-content-center align-items-center my-1">
              <div class="col-8">
                <h5 class="font-weight-normal">Seleccionar una fecha:</h5>
                <div class="">
                  <input v-model="dateChosen" class="form-control custom-input col-12" type="date">
                </div>
              </div>
              <div class="col-12 allData d-flex justify-content-center">
                <b-button class="btn-action-page  py-2 col-6" size="lg" @click="filterViewPerDay(dateChosen)" :disabled="dateChosen === null || dateChosen === undefined">Filtrar</b-button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="sidenav-page p-4" :class="{ openSidebar: openOptions}" :style="{width: sizeBlockOption}">
      <side-block
        :icon="'task-interna'"
        :tpl="'quality'"
        :messageVerificateProcess="openSingleData.verificateProcess"
        :verificateProcessBtn="openSingleData.verificateProcessBtn"
        :verificateProcessDiv="openSingleData.verificateProcessDiv"
        :messageVerificateProcessBtn="openSingleData.verificateProcessBtnMessage"
        :rolAdmin="rolAdmin"
        @closeBlock="close"
        @setDataTrackQuality="setDataTrackQuality"
        :dataQuality="currentData"
        :urlDataSheet="urlDataSheet"
      />
    </div>
  </div>
</template>

<script>
import ListQuality from '@/components/shared/ListQuality.vue'
import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url

export default {
  data: () => ({
    dataQuality: [],
    urlDataSheet: undefined,
    dateChosen: null,
    dataShowAllRecords: { data: [], state: '' },
    currentPage: 1,
    perPage: 8,
    filterLine: null,
    filterReference: null,
    rolAdmin: false,
    optionsLine: [],
    optionsReference: [],
    currentData: {},
    stateprocess: null,
    sizeBlockOption: '60%',
    lenQualityNoVerificated: 0,
    dataQualityNoVerificated: [],
    dataQualityVerificated: [],
    lenQualityVerificated: 0,
    openSingleData: { referencia: '', verificateProcess: '', changeHourMessage: '', changeHourBtn: false, asValidator: false, procedure: null, recordSpecific: { title: '', subtitle: '', records: [], creatorIsCase: false, validatorIsCase: false, roleSupCase: false, messageCase: false, btnMessageCase: '', btnStatus: true } },
    typeStatusActivityControlProcess: ['RECHAZADO', 'ACEPTADO', 'N.A.'],
    typeStatusEvalutionOrganoleptic: ['Rechazado', 'Aprobado']
  }),
  components: {
    ListQuality,
    SideBlock
  },
  mounted () {
    this.setDataTrackQuality()
    this.$store.state.storeQualityModule.dateInfo = null
    this.validateStatusQualityModule()
  },
  computed: {
    openOptions () {
      return this.$store.state.showOptions
    },
    getMessageVerificateProcess: {
      set (value) {
        this.$store.state.storeQualityModule.getMessageVerificateProcess = value
      },
      get () {
        return this.$store.state.storeQualityModule.getMessageVerificateProcess
      }
    }
  },
  methods: {
    closeModalProcess () {
      this.$bvModal.hide('modal-edit-process')
      this.getProceduresRecord()
    },
    getDataShowAllRecords () {
      if (this.dataShowAllRecords !== undefined && this.dataShowAllRecords.data !== undefined && this.dataShowAllRecords.data.length > 0) {
        return this.dataShowAllRecords.data.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        )
      } else {
        return []
      }
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    validateStatusQualityModule () {
      const self = this
      self.$store.dispatch('statusModulesEnabled').then(function () {
        if (self.$store.state.storeQualityModule.statusQualityModule !== true) {
          self.$router.push({ name: 'Inicio' })
        }
      })
    },
    showBlock: function () {
      this.$bvModal.hide('modal-show-single-process')
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
    },
    modalAllRecordsQuality: function (data) {
      this.dataShowAllRecords.data = data.dataRecords
      this.dataShowAllRecords.state = data.state
      this.$bvModal.show('modal-show-all-process')
    },
    openSingleProcess: function (data) {
      this.stateprocess = data.estado
      this.openSingleData.referencia = data.dataSend.referencia
      this.openSingleData.reference_id = data.dataSend.reference_id
      this.openSingleData.product_by_production_order = data.dataSend.product_by_production_order
      this.openSingleData.id = data.dataSend.id
      this.openSingleData.infoAbout = data.dataSend
      this.getProceduresRecord(data.dataSend.id)
    },
    async getProceduresRecord (dataId = this.openSingleData.id) {
      const self = this
      await axios.post('/api_quality/getProceduresRecord/', { id: dataId }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.openSingleData.procedure = null
        if (response.data.error !== undefined && response.data.error.length > 0) {
          self.openSingleData.procedure = null
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.procedure = response.data.records
          self.openSingleData.asValidator = response.data.asValidator
          self.openSingleData.verificateProcess = response.data.verificateProcess
          self.openSingleData.verificateProcessBtn = response.data.verificateProcessBtn
          self.openSingleData.verificateProcessDiv = response.data.verificateProcessDiv
          self.openSingleData.verificateProcessBtnMessage = response.data.messageVerificateProcessBtn
          self.openSingleData.changeHourMessage = response.data.changeHourMessage
          self.openSingleData.changeHourBtn = response.data.changeHourBtn
          self.$bvModal.hide('modal-show-all-process')
          self.$bvModal.show('modal-show-single-process')
        }
      })
    },
    editProcess: function (data) {
      this.getDataEditProcess(data.id, data.activity_code)
    },
    async getDataEditProcess (dataId, dataActivity) {
      const self = this
      await axios.post('/api_quality/getDataEditProcess/', { id: dataId, activity_code: dataActivity, id_quality: this.openSingleData.id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          self.openSingleData.procedure = null
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.recordSpecific = response.data.record
          self.$bvModal.hide('modal-show-single-process')
          self.$bvModal.show('modal-edit-process')
        }
      })
    },
    manageOutLineProcess: function (data) {
      this.openSingleData.recordSpecific = { title: data.subtitle, subtitle: data.activity, records: [], creatorIsCase: false, validatorIsCase: false, roleSupCase: false, messageCase: data.description, btnMessageCase: '', btnStatus: false, type_show: 'showDescription', subcontent: data.subcontent }
      this.$bvModal.hide('modal-show-single-process')
      this.$bvModal.show('modal-edit-process')
    },
    async clearDataTrack () {
      this.dataQualityNoVerificated = []
      this.lenQualityNoVerificated = 0
      this.dataQualityVerificated = []
      this.lenQualityVerificated = 0
    },
    async executeRecordSpecific () {
      const self = this
      await axios.post('/api_quality/saveDataEditProcess/', { dateInfo: self.$store.state.storeQualityModule.dateInfo, activityId: self.openSingleData.recordSpecific.activityId, activityCode: self.openSingleData.recordSpecific.activityCode, records: self.openSingleData.recordSpecific.records }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.$store.state.storeQualityModule.dateInfo = response.data.dateInfo
          self.clearDataTrack().then(function () {
            self.setDataTrackQuality()
          })
          self.announceToast(response.data.message.title, response.data.message.content, response.data.message.time, 'success')
        }
      })
    },
    changeStatusClearanceLine: function (value, posActivity) {
      this.openSingleData.recordSpecific.records[posActivity].status = value
    },
    viewReferencia: function () {
      this.sizeBlockOption = '90%'
      this.showBlock()
    },
    changeStatusProcessControl: function (event, posActivity) {
      this.openSingleData.recordSpecific.records[posActivity].status = event
    },
    labelFormatterControlProcess: function (value) {
      return this.typeStatusActivityControlProcess[value]
    },
    async validateDataCriterionEvaluate () {
      const self = this
      let validateHasNull = false
      const dataReceived = self.openSingleData.recordSpecific.records
      dataReceived.forEach((item) => {
        if (item.status === 0) {
          validateHasNull = true
        }
      })
      if (validateHasNull === true) {
        self.announceToast('Criterios de evaluación', `El estado para guardar los criterios no puede ser '${self.typeStatusActivityControlProcess[0]}'.`, 5000, 'danger')
      } else {
        axios.post('/api_quality/saveDataCriterionEvaluate/', { dateInfo: self.$store.state.storeQualityModule.dateInfo, id: self.openSingleData.recordSpecific.activityId, criterionEvaluate: self.openSingleData.recordSpecific.records, validate: true }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            self.$store.state.storeQualityModule.dateInfo = response.data.dateInfo
            self.announceToast('Criterios de evaluación', 'Se han guardado los criterios correctamente', 5000, 'success')
          }
        })
      }
    },
    async setDataTrackQuality (line_id = false, reference_code_id = false) {
      const self = this
      await axios.post('/api_quality/consultQuality/', { dateInfo: self.$store.state.storeQualityModule.dateInfo, line_id: line_id, reference_code: reference_code_id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.$store.state.storeQualityModule.dateInfo = response.data.dateInfo
          self.dataQualityNoVerificated = response.data.no_verificated
          self.lenQualityNoVerificated = response.data.no_verificated.length
          self.dataQualityVerificated = response.data.verificated
          self.lenQualityVerificated = response.data.verificated.length
          // self.dateUsed = response.data.dateUsed
          self.rolAdmin = response.data.rolAdmin
          if (self.rolAdmin === true) {
            self.optionsLine = response.data.lines_data
            self.optionsReference = response.data.reference_data
          }
          if (response.data.records_to_validate === true) {
            self.$bvModal.show('modal-alert-process')
          }
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          }
        }
      })
    },
    labelFormatterEvalutionOrganoleptic: function (value) {
      return this.typeStatusEvalutionOrganoleptic[value]
    },
    async validateDataToQualitySample () {
      const self = this
      let validateHasNull = false
      let otherEvaluations = 0
      let evaluation100 = false
      self.openSingleData.recordSpecific.records.forEach((item) => {
        if (item.id === 100 && item.status === true) {
          evaluation100 = true
        } else if (item.id !== 100 && item.status === true) {
          ++otherEvaluations
        }
      })
      if (otherEvaluations > 0) {
        if (evaluation100 === true) {
          self.announceToast('Muestreo de Producto', 'Recuerde que la evaluación 100 se marca cuando todo está correcto.', 5000, 'danger')
          validateHasNull = true
        }
      } else if (evaluation100 === false) {
        self.announceToast('Muestreo de Producto', 'Debes seleccionar al menos una evaluación.', 5000, 'danger')
        validateHasNull = true
      }
      if (self.openSingleData.recordSpecific.stowage_units === null || self.openSingleData.recordSpecific.stowage_units === '') {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'Debe especificar las cantidades de unidades por estiba.', 5000, 'danger')
      }
      if (self.openSingleData.recordSpecific.units_sample === null || self.openSingleData.recordSpecific.units_sample === '') {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'Debe especificar las cantidades que se tomarán como muestra.', 5000, 'danger')
      }
      if (self.openSingleData.recordSpecific.non_compliant_units === null || self.openSingleData.recordSpecific.non_compliant_units === '') {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'Debe especificar las cantidades No Conformes que se encuentraron.', 5000, 'danger')
      }
      if (self.openSingleData.recordSpecific.inspection_status !== 1) {
        validateHasNull = true
        self.announceToast('Muestreo de Producto', 'El Estado de Inspección debe encontrarse en Aprobado.', 5000, 'danger')
      }
      if (validateHasNull === false) {
        await axios.post('/api_quality/saveDataToQualitySample/', { dateInfo: self.$store.state.storeQualityModule.dateInfo, criterion_evaluate: self.openSingleData.recordSpecific.records, unit_stowage: self.openSingleData.recordSpecific.stowage_units, unit_sample: self.openSingleData.recordSpecific.units_sample, quantity_not_ok: self.openSingleData.recordSpecific.non_compliant_units, inspection_status: self.openSingleData.recordSpecific.inspection_status, hour: null, change: true, activityId: self.openSingleData.recordSpecific.activityId }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            self.$store.state.storeQualityModule.dateInfo = response.data.dateInfo
            self.announceToast('Muestreo de Producto', 'Se ha guardado el muestreo del producto correctamente.', 5000, 'success')
          }
        })
      }
    },
    async getDataSupplies () {
      const self = this
      await axios.post('/api_quality/getDataSupplies/', { quality_id: self.openSingleData.id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.recordSpecific = response.data
          self.sizeBlockOption = '90%'
          self.$bvModal.hide('modal-show-single-process')
          self.$bvModal.show('modal-edit-process')
        }
      })
    },
    async createSupplyQuality () {
      const self = this
      await axios.post('/api_quality/createSupplyQuality/', { quality_id: self.openSingleData.id, reference_id: self.openSingleData.reference_id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.recordSpecific = response.data
        }
      })
    },
    async changeValuesSupplyQuality (supplyId = false) {
      const gotSupplyId = supplyId
      const record = this.openSingleData.recordSpecific.records.find(function (post, index) {
        if (post.value === gotSupplyId) {
          return true
        }
      })
      if (record !== undefined) {
        this.openSingleData.recordSpecific.dataSelected.name = 'No seleccionado'
        this.openSingleData.recordSpecific.dataSelected.is_base = null
        if (record.name !== undefined) {
          this.openSingleData.recordSpecific.dataSelected.name = record.name
        }
        if (record.is_base !== undefined) {
          this.openSingleData.recordSpecific.dataSelected.is_base = record.is_base
        }
      }
    },
    async saveSupplyQuality () {
      const self = this
      let validateHasNull = false
      if (this.openSingleData.recordSpecific.dataSelected.id === null || this.openSingleData.recordSpecific.dataSelected.id === '') {
        validateHasNull = true
        this.announceToast('Muestreo de Producto', 'Debe seleccionar una referencia.', 5000, 'danger')
      }
      if (this.openSingleData.recordSpecific.dataSelected.batch === null || this.openSingleData.recordSpecific.dataSelected.batch === '') {
        validateHasNull = true
        this.announceToast('Muestreo de Producto', 'Debe especificar el lote de la referencia.', 5000, 'danger')
      }
      if (validateHasNull === false) {
        const data = new FormData()
        const record = this.openSingleData.recordSpecific.dataSelected
        data.append('quality_id', self.openSingleData.id)
        data.append('reference_id', self.openSingleData.referencia)
        data.append('supply_id', record.id)
        data.append('supply_hour', record.hour)
        data.append('supply_batch', record.batch)
        data.append('supply_due_date', record.due_date)
        data.append('file', record.imagesPreview[0])
        await axios.post('/api_quality/saveSupplyQuality/', data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            self.$bvModal.hide('modal-edit-process')
            self.getDataSupplies()
          }
        })
      }
    },
    async getDataForVerificateProcess () {
      const self = this
      await axios.post('/api_quality/getMoreDataQualityProcess/', { quality_id: self.openSingleData.id, product_id: self.openSingleData.reference_id, product_by_production_order: self.openSingleData.product_by_production_order }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.infoAbout.supplies = response.data.supplies_data
          self.openSingleData.infoAbout.url = url
          self.urlDataSheet = `${url}media/${response.data.pdf_url}`
          // self.openSingleData.infoAbout.dataSheet = `${url}media/${response.data.pdf_url}`
          self.openSingleData.infoAbout.quality_id = self.openSingleData.id
          self.openSingleData.infoAbout.product_supplies = response.data.product_supplies
          self.currentData = self.openSingleData.infoAbout
          self.viewReferencia()
        }
      })
    },
    async editInitialWitness () {
      const self = this
      await axios.post('/api_quality/manageInitialWitness/', { quality_id: self.openSingleData.id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.recordSpecific = response.data
          self.sizeBlockOption = '90%'
          self.$bvModal.hide('modal-show-single-process')
          self.$bvModal.show('modal-edit-process')
        }
      })
    },
    async previewFiles (event) {
      const self = this
      self.files = event.target.files
      self.openSingleData.recordSpecific.imagesPreview = event.target.files
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        self.openSingleData.recordSpecific.imagesPreview = null
        self.openSingleData.recordSpecific.url_image = undefined
        return
      }
      await self.createImage(files[0])
    },
    createImage: function (file) {
      const reader = new FileReader()
      const self = this
      self.openSingleData.recordSpecific.btnDisabled = false
      self.openSingleData.recordSpecific.messageCase = false
      reader.onload = (e) => {
        self.$set(self.openSingleData.recordSpecific.imagesPreview, 'url_image', e.target.result)
        self.openSingleData.recordSpecific.urlImage.splice(0, 1, e.target.result)
      }
      reader.readAsDataURL(file)
    },
    getImagesPreview: function () {
      const self = this
      if (self.openSingleData.recordSpecific.imagesPreview) {
        if (typeof (self.openSingleData.recordSpecific.imagesPreview) === 'string' && self.openSingleData.recordSpecific.imagesPreview.includes('Quality_Control_Initial_Witness')) {
          return url + 'media/' + self.openSingleData.recordSpecific.imagesPreview
        } else {
          return self.openSingleData.recordSpecific.urlImage[0]
        }
      } else {
        self.openSingleData.recordSpecific.imagesPreview = undefined
        return undefined
      }
    },
    async saveInitialWitness () {
      const self = this
      const data = new FormData()
      data.append('quality_id', self.openSingleData.id)
      data.append('file', self.openSingleData.recordSpecific.imagesPreview[0])
      await axios.post('/api_quality/saveInitialWitness/', data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.recordSpecific = { title: '', subtitle: '', records: [], creatorIsCase: false, validatorIsCase: false, roleSupCase: false, messageCase: false, btnMessageCase: '', btnStatus: true }
          self.$bvModal.hide('modal-edit-process')
        }
      })
    },
    async previewFilesCreateBase (event) {
      const self = this
      self.files = event.target.files
      self.openSingleData.recordSpecific.dataSelected.imagesPreview = event.target.files
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        self.openSingleData.recordSpecific.dataSelected.imagesPreview = null
        self.openSingleData.recordSpecific.dataSelected.file = null
        return
      }
      await self.createImageCreateBase(files[0])
    },
    createImageCreateBase: function (file) {
      const reader = new FileReader()
      const self = this
      reader.onload = (e) => {
        self.$set(self.openSingleData.recordSpecific.dataSelected.imagesPreview, 'file', e.target.result)
        self.openSingleData.recordSpecific.dataSelected.file = e.target.result
      }
      reader.readAsDataURL(file)
    },
    getImagesPreviewCreateBase: function () {
      const self = this
      if (self.openSingleData.recordSpecific.dataSelected.imagesPreview) {
        if (
          typeof self.openSingleData.recordSpecific.dataSelected.imagesPreview === 'string' && self.openSingleData.recordSpecific.dataSelected.imagesPreview.includes('Traceability')
        ) {
          return url + 'media/' + self.openSingleData.recordSpecific.dataSelected.imagesPreview
        } else {
          return self.openSingleData.recordSpecific.dataSelected.file
        }
      } else {
        self.openSingleData.recordSpecific.dataSelected.imagesPreview = undefined
        return undefined
      }
    },
    getImagesPreviewBase: function (fileBase) {
      if (fileBase) {
        if (typeof fileBase === 'string' && fileBase.includes('Traceability')) {
          return url + 'media/' + fileBase
        }
        return fileBase
      }
      return undefined
    },
    async modificateDateToWork () {
      const self = this
      self.dateChosen = self.$store.state.storeQualityModule.dateInfo
      self.$bvModal.show('view-per-day')
    },
    async filterViewPerDay (dateChosen = this.$store.state.storeQualityModule.dateInfo) {
      const self = this
      if (dateChosen !== null || dateChosen !== undefined) {
        self.$store.state.storeQualityModule.dateInfo = dateChosen
        self.setDataTrackQuality()
        self.$bvModal.hide('view-per-day')
      } else {
        self.announceToast('Error en selección de fecha', 'No se ha seleccionado una fecha', 9000, 'danger')
        self.closeBlock()
      }
    },
    async changeTimeQualityRecord () {
      const self = this
      await axios.post('/api_quality/changeTimeQualityRecord/', { hour: self.openSingleData.recordSpecific.value_time, activityId: self.openSingleData.recordSpecific.activityId, activityCode: self.openSingleData.recordSpecific.activityCode }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.announceToast(response.data.message.title, response.data.message.content, response.data.message.time, 'success')
        }
      })
    },
    async showModificateDateDetailQuality () {
      const self = this
      await axios.post('/api_quality/getHourToChangeDetailQuality/', { quality_id: self.openSingleData.id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.openSingleData.recordSpecific = response.data
          self.sizeBlockOption = '90%'
          self.$bvModal.hide('modal-show-single-process')
          self.$bvModal.show('modal-edit-process')
        }
      })
    },
    async saveHourToChangeDetailQuality () {
      const self = this
      await axios.post('/api_quality/saveHourToChangeDetailQuality/', { hour: self.openSingleData.recordSpecific.hour, quality_id: self.openSingleData.id }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.announceToast(response.data.message.title, response.data.message.content, response.data.message.time, 'success')
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .modal-lg {
    background-color: transparent;
    max-width: 100%;
  }
  .modal-content {
    border-radius: 20px;
  }
  .list-quality {
    thead {
      tr {
        background-color: #F5F6F8;
        border-bottom: 1px solid #ccc;
        th.row-entrada {
          background-color: #464FB5;
          color: #FFF;
        }
        th.row-salida {
          background-color: #363D8A;
          color: #FFF;
        }
      }
    }
    tbody {
      tr.subhead-titles {
        background-color: #FFF;
        border: 0;
      }
      td {
        vertical-align: middle;
        line-height: 1;
        text-align: center;
        &.multiple-register {
          span {
            border-bottom: 1px solid #8e8e8e;
            padding-bottom: 5px;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
  .list-process {
    font-size: 0.8rem;
    th {
      border: 0;
      background-color: transparent;
    }
    tr {
      text-align: center;
    }
    td {
      text-align: left;
      background-color: #FFF;
    }
  }
  #modal-show-all-process, #modal-show-single-process, #modal-edit-process, #modal-alert-process, #modal-details-reference, #view-per-day {
    padding-left: 8%;
    .modal-header {
      border: 0;
      position: absolute;
      right: 0;
      z-index: 1;
    }
  }
  #view-per-day {
    .modal-dialog {
      width: 45%;
    }
    .custom-input {
      width: 100%;
      font-size: 1.2rem;
    }
    .btn-action-back {
      background-color: #39C2A1;
    }
  }
  #modal-show-all-process {
    padding-left: 0;
    .modal-dialog {
      left: 0;
      right: 0;
      max-width: 100%;
      width: fit-content;
    }
  }
  #modal-show-single-process {
    .modal-dialog {
      left: 0;
      right: 0;
      max-width: 100%;
      width: fit-content;
    }
    .modal-content {
      width: 63vw;
    }
  }
  #modal-edit-process{
    .modal-process-common  {
      width: 63vw; //Editado mal
    }
    .input-group-prepend, .input-group-append {
      width: 2rem;
    }
    .input-actions-activity .bg-success output {
      background-color: var(--success);
    }
    .input-actions-activity .bg-danger output {
      background-color: var(--danger);
    }
    .input-actions-activity .bg-light output {
      background-color: var(--light);
      color: black !important;
    }
    .form-status-control-process {
      color: #FFF;
      height: 30px;
      padding: 3.3px;
      font-weight: 500;
    }
    .input-actions-activity {
      .input-group {
        align-items: center;
      }
      input {
        height: 35px;
        text-transform: uppercase;
      }
      .btn-quality-product {
        color: #CFC9DD !important;
      }
      .b-form-spinbutton {
        output {
          color: #FFF;
          height: 30px;
          padding: 3.3px;
          font-weight: 500;
          &.bg-light {
            color: #333;
          }
        }
      }
    }
    .modal-dialog {
      left: 0;
      right: 0;
      max-width: 100%;
      width: fit-content;
      width: 78%;
    }
  }
  .filter-line {
    .custom-select {
      height: auto;
    }
    .custom-select optgroup {
      font-weight: 600;
      color: black;
      background-color: white;
    }
  }
  .modal-productSampling {
    padding-top: 20px;
    padding-bottom: 20px;
    .custom-input, .custom-select {
      border: 0;
      height: 45px;
      border-radius: 5px;
    }
    .form-status-control-process {
      text-transform: none !important;
    }
    .list-form-quality {
      .item-list-form-quality {
        border: 0;
        height: 45px;
        border-radius: 5px;
      }
      .custom-checkbox {
        display: inline-block;
      }
      .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        background-color: #00C875;
        border-radius: 5px;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      appearance: none;
      -moz-appearance: textfield;
    }
    .input-actions-activity {
      output {
        color: #FFF;
        font-weight: 500;
      }
      .bg-success output {
        background-color: var(--success);
      }
      .bg-danger output {
        background-color: var(--danger);
      }
      .bg-light output {
        background-color: var(--light);
        color: black !important;
      }
    }
  }
  .modal-productSampling {
    width: 60vw;
    .custom-input.textarea-logistic-batch {
      resize: none;
      background: #e9ecef;
      padding: 2%;
      height: fit-content;
      min-height: 45px;
      span {
        border-bottom: 1px solid #8e8e8e;
        padding-bottom: 5px;
        &:last-child {
          border: 0;
        }
      }
    }
  }
  .suppliesQuality {
    .container-insumos {
      background-color:#F7F7FD;
      max-height: 61vh;
      overflow: auto;
      border-end-end-radius: 0;
      border-start-end-radius: 0;
    }
    .container-insumos::-webkit-scrollbar {
      width: 0.6vw;
      border-radius: 0px 20px 20px 20px;
    }
    .container-insumos::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 0px 20px 20px 0px;
    }
    .container-insumos::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 0px 20px 20px 0px;
    }
  }
  .modal-supplies-quantity {
    width: 76vw;
  }
  .modal-clearance-line, .modal-witness-quantity {
    width: 60vw;
  }
  .image-upload>input {
    display: none;
  }
  #btn_file_input_initial_witness {
    border: 0;
    width: fit-content;
    padding: 0;
    margin: 0;
    &.btn-secondary {
      background-color: transparent;
      border-color: transparent;
      border-radius: 10px;
    }
    div {
      width: 35%;
      box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
      background-color: white;
      border-radius: 10px 0 0 10px;
      padding: 0 1vh;
      height: 6vh;
    }
    span {
      box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
      font-size: 1.2rem;
      font-weight: 600;
      border-radius: 0 10px 10px 0;
      background-color: #b6b6b6;
      color: white;
      padding: 0 0.5vw;
      margin: 0;
      text-align: center;
      width: 100%;
      height: 6vh;
    }
  }
  #label_file_input_initial_witness {
    cursor: pointer;
    #container_icon {
      font-size: 2vw;
    }
    #picture_witness {
      width: 15vw;
    }
  }
  #changeDateToProcess {
    background-color: #03185A;
    font-size: 1rem;
  }
  .container-change-time, .container-change-time-detail {
    .font-app.d-flex {
      gap: 3vw;
    }
    .font-app.d-flex > * {
      width:20%;
      margin: 0
    }
  }
  .container-change-time {
    border-top: 2px dashed;
  }
  .container-btn-detail-quality {
    gap: 3vw;
  }
  .container-btn-detail-quality > * {
    margin: 0;
  }
  .main-content-gray {
    background-color: var(--gray-dark) !important;
    color: white !important;
  }
  .content-gray {
    background-color: var(--gray) !important;
    color: white !important;
  }
  .border-left-base {
    border-left-color: var(--yellow) !important;
  }
  .vertical-base {
    position: absolute;
    left: -13px;
    transform: rotate(270deg);
    text-align: center;
    color: var(--yellow);
    white-space: nowrap;
    font-weight: 600;
    top: 0;
    bottom: 0;
    border: 0;
  }
</style>

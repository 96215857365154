<template>
  <div>
    <div id="app" :class="{ 'overflow-hidden': isActiveSidebar }">
      <div class="container-fluid p-0">
        <main class="d-md-flex">
          <!-- Small Device -->
          <nav class="navbar bg-blue-dark d-flex d-sm-flex d-md-none">
            <a class="navbar-brand" href="/">
              <img src="~@/assets/logoIpro.png" class="img-fluid" alt="logo_ipro"><span
                class="color-yellowlight h1 px-4 align-middle">IPRO</span>
            </a>
            <div>
              <b-button v-b-toggle.sidebar-backdrop class="btn-menu-toggle"><i class="fas fa-bars"></i></b-button>
            </div>
          </nav>
          <b-sidebar id="sidebar-backdrop" v-model="isActiveSidebar" body-class="bg-blue-dark"
            header-class="bg-blue-deep" text-variant="light" backdrop right shadow>
            <p class="font-weight-bold text-white"><br>Hola,<br>{{ sessionUsername.toUpperCase() }}</p>
            <div class="px-3 py-2">
              <div class="row">
                <div v-if="is_admin()" class="col-4 nav-custom">
                  <router-link to="/plan-produccion" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon1.png" alt="icon1"></router-link>
                </div>
                <div v-if="!is_rol5()" class="col-4 nav-custom">
                  <router-link to="/apertura-linea" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon2.png" alt="icon2"></router-link>
                </div>
                <div v-if="activeQualityControl === true" class="col-4 nav-custom">
                  <router-link to="/control-calidad" class="btn-linkto position-relative"><img class="img-fluid"
                      src="~@/assets/icons/icon13.png" alt="icon6"><b-badge class="badge-menu"
                      variant="danger">2</b-badge></router-link>
                </div>
                <div class="col-4 nav-custom">
                  <router-link to="/actividad" class="btn-linkto"><img class="img-fluid" src="~@/assets/icons/icon4.png"
                      alt="icon4"></router-link>
                </div>
                <div class="col-4 nav-custom">
                  <router-link v-if="is_admin()" to="/orden-produccion" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon7.png"></router-link>
                </div>
                <div class="col-4 nav-custom">
                  <router-link v-if="is_admin()" to="/nota-despacho" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon9.png"></router-link>
                </div>
                <!-- <div class="col-4 nav-custom">
                  <router-link v-if="is_admin()" to="/lista-empaque" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon10.png"></router-link>
                </div> -->
                <div v-if="is_admin()" class="col-4 nav-custom">
                  <router-link to="/estado-linea" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon6.png" alt="icon6"></router-link>
                </div>
                <div v-if="is_admin()" class="col-4 nav-custom">
                  <router-link to="/picking" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon11.png"></router-link>
                </div>
                <div v-if="is_admin()" class="col-4 nav-custom">
                  <router-link to="/control-inventario" class="btn-linkto"><img class="img-fluid" src="~@/assets/icons/icon12.png"></router-link>
                </div>
                <div v-if="is_admin() && activeCostControl === true" class="col-4 nav-custom">
                  <router-link to="/costos" class="btn-linkto"><img src="~@/assets/icons/icon13.png" class="img-fluid"></router-link>
                </div>
                <div v-if="is_admin()" class="col-4 nav-custom">
                  <router-link to="/analitica" class="btn-linkto"><img src="~@/assets/icons/icon14.png" class="img-fluid"></router-link>
                </div>
                <!-- <div v-if="is_admin()" class="col-4 nav-custom">
                  <router-link to="/indicadores" class="btn-linkto"><img class="img-fluid"
                      src="~@/assets/icons/icon5.png"></router-link>
                </div> -->
                <div v-if="is_admin()" class="col-4 nav-custom">
                  <router-link to="/indicadores_prueba" class="btn-linkto"><img class="img-fluid" src="~@/assets/icons/icon5.png"></router-link>
                </div>
              </div>
            </div>
            <br>
            <b-button class="btn-action-page py-2 px-3" @click="logout()" size="lg">Salir</b-button>
          </b-sidebar>
          <!-- tablet & Desktop Device -->
          <div class="d-none d-sm-none d-md-block scroll sidebar " :class="[sidebar ? 'open' : 'sidebar']"
            v-if="currentRoute != '/'">
            <div class="logo-details">
              <a class="navbar-brand" href="/">
                <img src="~@/assets/logoIpro.png" class="img-fluid" alt="logo_ipro" width="63" height="50">
              </a>
              <div class="logo_name">IPRO</div>
              <i class='bx bx-menu' id="btn" @click="sidebar = !sidebar"></i>
            </div>
            <ul class="nav-list">
              <p class="font-weight-bold text-white links_name username_content">{{ sessionUsername.toUpperCase() }}</p>
              <!-- routes -->
              <li v-if="is_admin()">
                <router-link to="/plan-produccion">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon1.png" alt="package">
                  </div>
                  <span class="links_name">Plan de produccion</span>
                </router-link>
              </li>
              <li v-if="!is_rol5()">
                <router-link to="/apertura-linea">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon2.png" alt="icon-linea">
                  </div>
                  <span class="links_name">Apertura de linea</span>
                </router-link>
              </li>
              <!-- <li v-if="is_admin()">
                <router-link to="/trazabilidad">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon3.png" alt="icon-trazabilidad">
                  </div>
                  <span class="links_name">Trazabilidad</span>
                </router-link>
              </li> -->
              <li v-if="activeQualityControl === true">
                <router-link to="/control-calidad" class="btn-linkto position-relative">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon13.png" alt="icon-control-calidad">
                    <b-badge v-if="$store.state.storeQualityModule.lenValidateInQualityModule > 0" class="badge-menu"
                      variant="danger">{{
                        $store.state.storeQualityModule.lenValidateInQualityModule
                      }}</b-badge>
                  </div>
                  <span class="links_name">Control de calidad</span>
                </router-link>
              </li>
              <li>
                <router-link to="/actividad">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon4.png" alt="icon-actividad">
                  </div>
                  <span class="links_name">Actividad</span>
                </router-link>
              </li>
              <li v-if="is_admin()">
                <router-link to="/estado-linea">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon6.png" alt="icon-estado-linea">
                  </div>
                  <span class="links_name">Estado de linea</span>
                </router-link>
              </li>
              <li v-if="is_admin()">
                <router-link to="/orden-produccion" class="btn-linkto">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon7.png" alt="icon-orden-produccion">
                  </div>
                  <span class="links_name">Orden de produccion</span>
                </router-link>
              </li>
              <li v-if="is_admin()">
                <router-link to="/nota-despacho" class="btn-linkto">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon9.png" alt="icon-nota-despacho">
                  </div>
                  <span class="links_name">Nota de despacho</span>
                </router-link>
              </li>
              <li v-if="is_admin()">
                <router-link to="/picking" class="btn-linkto">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon11.png" alt="icon-picking">
                  </div>
                  <span class="links_name">Orden de Transporte</span>
                </router-link>
              </li>
              <li v-if="is_admin()">
                <router-link to="/control-inventario" class="btn-linkto">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon12.png" alt="icon-control-inventario">
                  </div>
                  <span class="links_name">Control de inventario</span>
                </router-link>
              </li>
              <!-- <li v-if="is_admin()">
                <router-link to="/indicadores">
                  <img src="~@/assets/icons/icon5.png" alt="icon-indicadores">
                  <span class="links_name">Indicadores</span>
                </router-link>
              </li> -->
              <li v-if="is_admin() && activeCostControl === true">
                <router-link to="/costos" class="btn-linkto">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon13.png" alt="icon-control-inventario">
                  </div>
                  <span class="links_name">Costos</span>
                </router-link>
              </li>
              <li v-if="is_admin()">
                <router-link to="/analitica" >
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon14.png" alt="icon-analítica">
                  </div>
                  <span class="links_name">Analítica</span>
                </router-link>
              </li>
              <li v-if="is_admin()">
                <router-link to="/indicadores">
                  <div class="content-icon-link">
                    <img src="~@/assets/icons/icon5.png" alt="icon-indicadores">
                  </div>
                  <span class="links_name">Indicadores</span>
                </router-link>
              </li>
            </ul>
            <div class="profile">
              <div class="profile-details" @click="logout()" @mouseover="isLogOutHovering = true" @mouseout="isLogOutHovering = false">
                <div class="name">Salir</div>
                <div id="log_out"><i :class="{'bx bxs-log-out': isLogOutHovering, 'bx bx-log-out': !isLogOutHovering}"></i></div>
              </div>
            </div>
          </div>
          <div class="home-section" id="body" :class="{ 'home-active': currentRoute == '/' }">
            <div class="overlay" :class="{ active: bodyOverlay }"></div>
            <router-view />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isActiveSidebar: false,
    sidebar: false,
    isLogOutHovering: false
  }),
  mounted () {
    if (sessionStorage.token) {
      this.$root.$on('overlayBodyMethod', () => {
        this.overlayBody('off')
      })
      this.$store.state.sessionUsername = sessionStorage.getItem('username')
      this.$store.dispatch('statusModulesEnabled')
    }
    if (!sessionStorage.token && this.$route.name !== 'Inicio') {
      this.$router.push({ name: 'Inicio' })
    }
  },
  methods: {
    overlayBody (value) {
      if (value === 'on') {
        this.$store.commit('actionOrvelay', true)
      } else if (value === 'off') {
        this.$store.commit('actionOrvelay', false)
      }
    },
    logout () {
      sessionStorage.clear()
      this.$router.push({ name: 'Inicio' })
    },
    is_admin () {
      if (sessionStorage.getItem('rol') === '1') {
        return true
      } else {
        return false
      }
    },
    is_rol5 () {
      if (sessionStorage.getItem('rol') === '5') {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    bodyOverlay () {
      return this.$store.state.bodyOverlay
    },
    currentRoute () {
      return this.$route.path
    },
    sessionUsername () {
      return this.$store.state.sessionUsername
    },
    activeQualityControl () {
      return this.$store.state.storeQualityModule.statusQualityModule
    },
    activeCostControl () {
      return this.$store.state.storeCost.statusCostModule
    }
  },
  watch: {
    $route (to, from) {
      this.$store.commit('actionOrvelay', false)
      this.$store.commit('actionBlockOptions', false)
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;900&display=swap');

// General -------------------------------------------------
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100% - 51px);
  width: 78px;
  background: #03185A;
  // padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
  transition: width 0.5s;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 14px;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
    left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar.open .logo-details #btn {
  text-align: right;
  left: inherit;
}

.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  padding: 0 14px;
  // height: 100%;
}

.sidebar li {
  position: relative;
  margin: 15px 0 0;
  list-style: none;
  height: 40px;
}

.sidebar input {
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #363D8A;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #363D8A;
  gap: 5px;
  // padding: 0 5px;
  &.router-link-exact-active {
    background-color: #00B285;
  }
}
.sidebar li a:hover {
  background: #00B285;
}
.sidebar .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  // opacity: 0;
  display: none;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open .links_name {
  opacity: 1;
  display: inherit;
  pointer-events: auto;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar div.profile {
  position: fixed;
  // height: 60px;
  width: 78px;
  left: 0;
  // bottom: -8px;
  bottom: 0;
  padding: 10px 14px;
  background: #363D8A;
  transition: all 0.5s ease;
  overflow: hidden;
  height: 51px;
}

.sidebar.open div.profile {
  width: 250px;
}

.sidebar .profile-details {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}
.sidebar .profile-details:hover {
  cursor: pointer;
}
@keyframes width_text_wrap {
  from {text-wrap: inherit;}
  to {text-wrap: wrap;}
}
.username_content {
  overflow: hidden;
  animation-name: width_text_wrap;
  animation-duration: 0.25s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.sidebar li .content-icon-link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
}
@keyframes width_icons {
  from {width: 40px;}
  to {width: 100%;}
}
.sidebar:not(.open) li .content-icon-link {
  animation-name: width_icons;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
  // width: 100%;
}
.sidebar li img {
  height: 100%;
  // width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.sidebar div.profile .name {
  cursor: pointer;
  padding-top: 7px;
  font-weight: 400;
  color: #fff;
}

.sidebar div.profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #363D8A;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.sidebar div.profile #log_out i {
  cursor: pointer;
  transition: all 1s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}
.home-section {
  padding-left: 78px
}

.sidebar.open~.home-section {
  padding-left: 250px;
}

// .sidebar.open~.home-section {
//   left: 250px;
//   width: calc(100% - 250px);
// }
// .home-section .text {
//   display: inline-block;
//   color: #11101d;
//   font-size: 25px;
//   font-weight: 500;
//   margin: 18px
// }
@media (max-width: 720px) {
  .home-section {
    padding-left: 0px
  }

  .sidebar.open~.home-section {
    padding-left: 0px
  }
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar:vertical {
  width: 10px;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #363D8A;
  border-radius: 20px;
  border: 2px solid #f1f2f3bd;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

// General -------------------------------------------------
#app {
  font-family: 'Barlow', sans-serif;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

#body {
  width: 100%;
  height: 100vh;

  &.home-active {
    margin-left: auto;
    padding-left: 0;
    background-color: #03185A;
    color: #FFF;
  }
}

.page,
.body-page,
.sub-page {
  height: 100%;
  width: 100%;
}

#sidebar {
  padding: 10px 15px;
  background-color: #03185A;
  position: fixed;
  height: 100%;
  box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 0.20);

  .logo {
    padding: 8px 0;
    border-bottom: 1px solid #fff;
  }
}

.overlay {
  position: fixed;
  height: 0;
  width: 100%;
  background-color: rgb(0 0 0 / 0.6);
  transition: opacity 1s;
  opacity: 0;
  z-index: 100;

  &.active {
    opacity: 1;
    height: 100%;
  }
}

.overlayclose {
  background-color: rgb(0 0 0 / 0);
}

.color-green {
  color: #00C875;
}

.color-yellow {
  color: #FDAB3D;
}

.color-yellowlight {
  color: #FFD012;
}

.color-red {
  color: #FF2626;
}

.color-bluedark {
  color: #02103a;
}

.color-grayLight {
  color: #b6b6b6;
}

.color-blue {
  color: #00b7ff;
}

.border-lf-green {
  border-left-color: #00C875 !important;
}

.border-lf-yellow {
  border-left-color: #FDAB3D !important;
}

.border-lf-red {
  border-left-color: #FF2626 !important;
}

.border-lf-blue {
  border-left-color: #00b7ff !important;
}

.bg-blue-dark {
  background-color: #03185A !important;
}

.bg-blue-deep {
  background-color: #02103a !important;
}

.bg-indigo {
  background-color: #00C875 !important;
}

.bg-yellow {
  background-color: #FDAB3D !important;
}

.bg-grayLight {
  background-color: #F5F6F8 !important;
}

.bg-red {
  background-color: #FF4D4D !important;
}

.bg-blue {
  background-color: #00BFFF !important;
}

.icon-green {
  background-color: #00C875;
}

.icon-yellow {
  background-color: #FDAB3D;
}

.icon-red {
  background-color: #FF2626;
}

.icon-blue-dark {
  background-color: #03185A;
}

.border-custom-left {
  border-left: 2px solid #dee2e6;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn-lock {
  pointer-events: none !important;
}

.btn-actions {
  border: 1px solid;
  border-radius: 15px;
  padding: 10px 40px;
  box-shadow: inset 2px 2px 2px 2px rgb(0 0 0 / 20%);

  &.btn-delete {
    background-color: #FF4D4D;

    &:hover,
    &:focus,
    &:active {
      background-color: #FF4D4D !important;
    }
  }

  &.btn-update {
    background-color: #00B8D6;

    &:hover,
    &:focus,
    &:active {
      background-color: #00B8D6 !important;
    }
  }

  &.btn-cancel {
    background-color: #9797A6;

    &:hover,
    &:focus,
    &:active {
      background-color: #9797A6 !important;
    }
  }
}

.font-app {
  font-family: 'Barlow', sans-serif;
}

.badge-menu {
  position: absolute;
  top: 0;
  right: 0;
}

// Navbar -------------------------------------------------
.nav-custom {
  padding-top: 30px;

  .btn-linkto {
    background-color: #363D8A;
    display: flex;
    border-radius: 50%;
    margin: 10px auto;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;

    &.router-link-exact-active {
      background-color: #00B285;
    }
  }
}

.btn-menu-toggle {
  background-color: #00C875;

  &:focus {
    background-color: #00C875;
  }
}

#sidebar-backdrop {
  .b-sidebar-header .close {
    font-size: 2.5rem;
  }
}

// Header -------------------------------------------------
.header-page {
  text-align: left;
  border-bottom: 2px solid #CACAD9;
  font-weight: 600;
}

// Page -------------------------------------------------
.circle-icon-page {
  background: #EDEEEF;
  border-radius: 50%;
  padding: 30px 25px;
  height: 190px;
  width: 190px;
  margin: auto;
}

.modal-lg {
  background-color: blue;
  width: 50%;
  margin: 0 auto;
}

.icon-list {
  color: #FFF;
  border-radius: 50%;
  padding: 4px 6px 0px 6px;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
}

.btn-action-page {
  background-color: #39C2A1;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
  font-weight: 600;
  border-radius: 10px;
  border: 0;
  padding: 15px 30px;
  font-size: 1.2rem;

  &:hover {
    background-color: #0bbb91;
  }
}
.background-color-sucess-ipro {
  background-color: #0bbb91;
}
.color-sucess-ipro {
  color: #0bbb91;
}
.sidenav-page {
  background-color: #F7F7FD;
  position: fixed;
  width: 60%;
  right: 0;
  top: 0;
  height: 100%;
  box-shadow: 5px 0px 10px 0px #000;
  overflow: auto;
  transition: all 1s;
  right: -2000px;
  z-index: 200;
}

.sidenav-page::-webkit-scrollbar {
  display: none;
}

.sidenav-page::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sidenav-page::-webkit-scrollbar:vertical {
  width: 10px;
}

.sidenav-page::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.sidenav-page::-webkit-scrollbar:horizontal {
  height: 10px;
}

.sidenav-page::-webkit-scrollbar-thumb {
  background-color: #363D8A;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.sidenav-page::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sidenav-pagebig {
  background-color: #F7F7FD;
  position: fixed;
  width: 80%;
  right: 0;
  top: 0;
  height: 100%;
  box-shadow: 5px 0px 10px 0px #000;
  overflow: auto;
  transition: all 1s;
  right: -2000px;
  z-index: 200;
}

.sidenav-pagebig::-webkit-scrollbar {
  display: none;
}

.sidenav-pagebig::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sidenav-pagebig::-webkit-scrollbar:vertical {
  width: 10px;
}

.sidenav-pagebig::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.sidenav-pagebig::-webkit-scrollbar:horizontal {
  height: 10px;
}

.sidenav-pagebig::-webkit-scrollbar-thumb {
  background-color: #a0a2af;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.sidenav-pagebig::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sidenav-pagebig100 {
  background-color: #F7F7FD;
  position: fixed;
  width: 100vw;
  right: 0;
  top: 0;
  height: 100%;
  box-shadow: 5px 0px 10px 0px #000;
  overflow: auto;
  right: -2000px;
  transition: all 1s;
  z-index: 200;
}

.sidenav-pagebig100::-webkit-scrollbar {
  display: none;
}

.sidenav-pagebig100::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sidenav-pagebig100::-webkit-scrollbar:vertical {
  width: 10px;
}

.sidenav-pagebig100::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.sidenav-pagebig100::-webkit-scrollbar:horizontal {
  height: 10px;
}

.sidenav-pagebig100::-webkit-scrollbar-thumb {
  background-color: #a0a2af;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.sidenav-pagebig100::-webkit-scrollbar-track {
  border-radius: 10px;
}

.openSidebar {
  right: 0;
}

.table-list {
  thead {
    text-align: left;

    th {
      padding: 10px 15px;
      font-weight: 400;
    }

    .border-left-head {
      border-left: 1px solid #ccc;
    }
  }

  tbody {
    tr {
      background-color: #F5F6F8;
      border-left: 6px solid #03185A;
      border-bottom: 8px solid #FFF;

      &.active {
        background-color: #e9e9e9;
        border-color: #00BFFF;
        border-bottom: 0;
      }

      &.success {
        border-color: #00C875;
        border-bottom: 8px solid #FFF;
      }

      &.warning {
        border-color: #FDAB3D;
        border-bottom: 8px solid #FFF;
      }

      &.danger {
        border-color: #FF4D4D;
        border-bottom: 8px solid #FFF;
      }
    }

    td {
      padding: 8px 10px;
      border-left: 1px solid #ccc;
      vertical-align: middle;
    }

    .column-left {
      text-align: left;
    }

    .option-divider {
      border-left: 1px solid #ccc;
      border-radius: 0;
    }
  }
}

.table-list-production {
  thead {
    text-align: left;

    th {
      padding: 10px 15px;
      font-weight: 400;
    }

    .border-left-head {
      border-left: 1px solid #ccc;
    }
  }

  tbody {
    tr {
      background-color: #F5F6F8;
      border-left: 7px solid;
      border-bottom: 8px solid #FFF;
    }

    td {
      padding: 3px 10px;
      border-left: 1px solid #ccc;
    }

    .column-left {
      text-align: left;
    }

    .option-divider {
      border-left: 1px solid #ccc;
      border-radius: 0;
    }
  }
}

.list-pagination {
  .page-item.active {
    button {
      background-color: #363D8A;
      border: 0;
    }
  }
}

.search-custom {
  z-index: 0;

  .input-search {
    border-radius: 8px 0 0 8px !important;
  }

  button {
    background-color: #03185A !important;
    border-radius: 0 8px 8px 0 !important;

    &:hover,
    &:focus,
    &:active {
      background-color: #03185A !important;
      color: #FFF !important;
    }
  }
}

// Forms -------------------------------------------------
.custom-select {
  border: 1px solid #363D8A;
  border-radius: 8px;
  height: 45px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;

  option {
    background-color: #ECECFB;
    color: #363D8A;
    border: 1px solid #6f6f6f;

    &:hover {
      background: red;
    }

    &:checked {
      background-color: #363D8A;
      color: #FFF;
    }
  }
}

.custom-input-trazability {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #363D8A;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-input {
  width: 45%;
  margin: auto;
  border: 2px solid #ccc;
  height: 50px;
  border-radius: 10px;
  text-align: center;
}

.input-group {
  input {
    text-align: center;
    border-radius: 10px !important;
    height: 50px;
    border: 2px solid #ccc;
  }

  button {
    background-color: transparent;
    color: #CFC9DD;
    border: 0;
    font-size: 2rem;
    padding: 0 10px;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      color: #CFC9DD !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

// // Responsive -------------------------------------------------
// @media (min-width: 768px) and (max-width: 991.98px) {
//   #body {
//     margin-left: 5%;
//     &.home-active {
//       padding-left: 0;
//     }
//   }
//   #sidebar {
//     width: 12%;
//   }
//   .sidenav-page {
//     width: 85%;
//   }
// }
// @media (min-width: 576px) and (max-width: 767.98px) {
//   #body {
//     padding-left: 0;
//   }
//   .page,
//   .body-page,
//   .sub-page {
//     height: auto;
//   }
//   .border-custom-left {
//     border-left: 0;
//   }
//   .header-list-production {
//     .header-column-left,
//     .header-column-right {
//       width: auto !important;
//     }
//   }
//   .header-modal-production {
//     .header-column-left,
//     .header-column-right {
//       width: auto !important;
//     }
//   }
//   #modal-list-production {
//     margin-left: 0 !important;
//   }
// }
// @media (max-width: 575.98px) {
//   #body {
//     padding-left: 0;
//   }
//   .sidenav-page {
//     width: 100%;
//   }
//   .border-custom-left {
//     border-left: 0;
//   }
//   .header-list-production {
//     .header-column-left,
//     .header-column-right {
//       width: auto !important;
//     }
//   }
//   #modal-list-detail-production {
//     margin-left: 0;
//     .title-list {
//       display: inline-block;
//     }
//     .icon-list {
//       display: inline;
//     }
//   }
//   #modal-list-production {
//     margin-left: 0;
//     .title-list {
//       display: inline-block;
//     }
//     .icon-list {
//       display: inline;
//     }
//   }
//   #detail-task {
//     margin-left: 0;
//   }
// }
  .text-decoration-none {
    text-decoration: none;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div class="form-bsn">
    <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
    <div>
      <div class="box-option mx-2">
        <div class="p-4">
          <div class="text-left">
            <div class="form-group row">
              <label for="codeOp" class="font-weight-bold col-2 col-form-label">Número de OP</label>
              <input type="text" class="col-10" v-model="codeOp" id="codeOp" placeholder="Ingrese el número de la OP" :disabled="this.$store.state.editarReferenciaPlan ? '' : false">
            </div>
            <div class="form-group row">
              <label for="fechaOP" class="col-2 col-form-label font-weight-bold">Fecha</label>
              <input class="col-10" v-model="dateBsn" type="date" id="fechaOP" :min="dateTwoMonthsBefore" :max="dateTwoMonthsAfter" @change="validateRangeDate()">
            </div>
            <p class="font-weight-bold m-0">Ficha técnica OP</p>
            <div class="box-add-file mt-3 text-center">
              <div class="px-3 py-4" v-show="!fichaTecnica">
                <label for="fileFichaTecnica">
                  <img src="~@/assets/icons/uploadFile.png" class="img-fluid"><br>Adjuntar archivo
                </label>
                <input type="file" name="file" id="fileFichaTecnica" @change="fileFichaTecnica($event)"
                  class="inputfile" />
              </div>
              <div class="col-10 m-auto pt-4" v-show="fichaTecnica">
                <div>
                  <img :src="fichaTecnica" class="img-fluid m-auto" alt="">
                </div>
                <div class="d-flex align-items-center mt-3">
                  <b-button v-b-modal.zoom-file class="color-grayLight col" variant="link"><i
                      class="fas fa-search-plus h3"></i></b-button>
                  <span class="h2 font-weight-light col color-grayLight"> | </span>
                  <b-button class="col" variant="link" @click="deleteFile()"><i
                      class="fas fa-trash-alt text-danger h3"></i></b-button>
                </div>
              </div>
            </div>
            <div v-if="$store.state.editarReferenciaPlan" class="form-group row mt-5 mb-2 d-flex justify-content-end align-content-center">
              <button class="btn-action-page py-2 d-flex justify-content-center align-content-center text-light m-0 btn-show-quantityWorked" size="sm" @click="storageOppositeValue()">
                <p v-show="showQuantityWorked" class="m-0">
                  Ocultar cantidad maquilada
                  <i class="fa-solid fa-eye-slash"></i>
                </p>
                <p v-show="!showQuantityWorked" class="m-0">
                  Mostrar cantidad maquilada
                  <i class="fa-solid fa-eye"></i>
                </p>
              </button>
            </div>
            <table id="tablePlan" class="table" style="text-align: center;">
              <thead>
                <tr>
                  <td><strong>Código Producto</strong></td>
                  <td><strong>Descripción</strong></td>
                  <td><strong>Cantidad</strong></td>
                  <td v-show="showQuantityWorked"><strong>Cantidad Maquilada</strong></td>
                  <td><strong>Lote</strong></td>
                  <td><strong>Horas</strong></td>
                  <td v-if="$store.state.editarReferenciaPlan ? '' : true"><strong>Ficha técnica</strong></td>
                  <td v-if="$store.state.editarReferenciaPlan ? '' : true && supplyProcessingStatus"><strong>Acción</strong></td>
                </tr>
              </thead>
              <tbody id="tbody">
                <tr v-for="(dataplan, r) in this.$store.state.dataPlanProduccion.rowsPlanProduccion" :key="r">
                  <td>
                    <multiselect :max-height="150" v-model="dataplan.codigo" :show-labels="false" :options="dataReferencias || []" placeholder="Referencia" label="ref" @select="getSuppliesProduct(dataplan.codigo, r, true, true).then(() => productProcess_ppk(dataplan.codigo, dataplan.cantidad, r, true));">
                    </multiselect>
                  </td>
                  <td>
                    {{ dataplan.codigo ? dataplan.codigo.nombre : "" }}
                  </td>
                  <td>
                    <input v-model="dataplan.cantidad" type="number" min="0" @input="productProcess_ppk(dataplan.codigo, dataplan.cantidad, r)">
                  </td>
                  <td v-show="showQuantityWorked">
                    <input v-model="dataplan.quantityWorked" type="text" class="text-center" disabled>
                  </td>
                  <td>
                    <input v-model="dataplan.lote" placeholder="Ingrese lote, ej: 123abc" type="text">
                  </td>
                  <td>
                    <input v-model="dataplan.horas" type="number" class="text-center" disabled>
                  </td>
                  <td v-if="$store.state.editarReferenciaPlan ? '' : true">
                    <label :for="'FichaTecnicaReferencia' + r" v-if="!filereferencesStatus[r]">
                      <img src="~@/assets/icons/uploadFile.png" class="img-fluid" alt="subir ficha tecnica" title="subir ficha tecnica">
                    </label>
                    <div v-if="filereferencesStatus[r]">
                      <i class="fa-regular fa-floppy-disk fa-2xl" style="color: #39c2a1;"></i>
                      <b-badge class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" pill variant="danger" @click="deleteFileReferencia(r)" style="cursor: pointer;">
                        <i class="fa-solid fa-xmark" style="color: #ffffff;"></i>
                      </b-badge>
                    </div>
                    <input style="display: none;" type="file" :id="'FichaTecnicaReferencia' + r" @change="fichaTecnicaReferencia($event, r)">
                  </td>
                  <td v-if="supplyProcessingStatus">
                    <b-button variant="link" class="d-flex justify-content-center flex-row btn-action-page my-0 py-0 px-3 text-light text-decoration-none btn-description-supplies" @click="describeSupplies(r)" :disabled="(dataplan.codigo !== false && dataplan.codigo !== null && dataplan.codigo !== '') && (dataplan.cantidad !== false && dataplan.cantidad !== null && dataplan.cantidad !== '' && dataplan.cantidad > 0) ? false : true" title="Asignar insumos">
                      <div class="d-flex justify-content-center flex-column align-items-center pr-2">
                        <span>{{ dataplan.basesCount }}</span>
                        <span><i class="fa-solid fa-inbox"></i></span>
                      </div>
                      <div class="px-0 bg-light divider-supplies-add-op"> </div>
                      <div class="d-flex justify-content-center flex-column align-items-center pl-2">
                        <span>{{ dataplan.suppliesCount }}</span>
                        <span><i class="fa-solid fa-dolly"></i></span>
                      </div>
                    </b-button>
                  </td>
                  <td class="d-flex justify-content-center">
                    <b-button v-if="$store.state.editarReferenciaPlan" variant="link" @click="$bvModal.show('delete-reference-plan'), referenceConfirm(dataplan.pk_productsOp), confirmDelete(r)" title="Eliminar">
                      <i class="far fa-trash-alt text-danger fa-lg"></i>
                    </b-button>
                    <b-button v-if="$store.state.editarReferenciaPlan ? '' : true" variant="link" @click="$bvModal.show('delete-reference-plan'), confirmDelete(r)" title="Eliminar">
                      <i class="far fa-trash-alt text-danger fa-lg"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center;">
              <b-button class="btn-action-page py-2" size="sm" id="addRow" @click="addRow()">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </b-button>
            </div>
            <!-- Modal de eliminar -->
            <div class="modal-delete-reference-plan">
              <b-modal ref="close-delete-modal" id="delete-reference-plan" centered :hide-footer="true" :hide-header="true" >
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div class="py-5">
                    <div class="circle-icon-page d-flex justify-content-center align-items-center">
                      <div>
                        <img src="~@/assets/icons/trash.png" class="img-fluid">
                      </div>
                    </div>
                    <div class="pt-4 text-center">
                      <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar esta referencia?<br></h5>
                      <div>
                        <b-button size="lg" class="btn-actions btn-cancel mx-3" @click="close_delete_modal()">Cancelar</b-button>
                        <b-button v-if="$store.state.editarReferenciaPlan" size="lg" class="btn-actions btn-delete mx-3" @click="referenceDelete()">Eliminar</b-button>
                        <b-button v-if="$store.state.editarReferenciaPlan ? '' : true" size="lg" class="btn-actions btn-delete mx-3" @click="removeRow()">Eliminar</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*
import dataproduct from '../../public/product.json'
*/
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'FormPlanProduccion',
  props: {
    icon: String,
    data: Array,
    extraData: Array
  },
  components: {
    Multiselect
  },
  data () {
    return {
      tags: [],
      indexDelete: '',
      pk_productsOp: '',
      // filereferencesStatus: [false],
      inputBsn: {
        quantity: [],
        batch: []
      },
      showQuantityWorked: false
    }
  },
  computed: {
    mandatorySupplyProductionPlan: {
      set (value) {
        this.$store.state.storeQualityModule.mandatorySupplyProductionPlan = value
      },
      get () {
        return this.$store.state.storeQualityModule.mandatorySupplyProductionPlan
      }
    },
    supplyProcessingStatus: {
      set (value) {
        this.$store.state.storeQualityModule.supplyProcessingStatus = value
      },
      get () {
        return this.$store.state.storeQualityModule.supplyProcessingStatus
      }
    },
    filereferencesStatus: {
      get () {
        return this.$store.state.filereferencesStatus
      },
      set (value) {
        this.$store.state.filereferencesStatus = value
      }
    },
    dateBsn: {
      get () {
        return this.$store.state.dataPlanProduccion.date
      },
      set (value) {
        this.$store.state.dataPlanProduccion.date = value
      }
    },
    dateTwoMonthsBefore: {
      get () {
        return this.$store.state.storeProduction.dateTwoMonthsBefore
      }
    },
    dateTwoMonthsAfter: {
      get () {
        return this.$store.state.storeProduction.dateTwoMonthsAfter
      }
    },
    cantidad: {
      set (value) {
        this.$store.state.cantidadPlan = value
      },
      get () {
        return this.$store.state.cantidadPlan
      }
    },
    codeOp: {
      set (value) {
        this.$store.state.dataPlanProduccion.op = value
      },
      get () {
        return this.$store.state.dataPlanProduccion.op
      }
    },
    horas: {
      set (value) {
        this.$store.state.horasPlan = value
      },
      get () {
        return this.$store.state.horasPlan
      }
    },
    referencia: {
      set (value) {
        this.$store.state.storeProduction.referencia = value
      },
      get () {
        return this.$store.state.storeProduction.referencia
      }
    },
    fichaTecnica: {
      get () {
        return this.$store.state.storeProduction.fichaTecnica
      },
      set (value) {
        this.$store.state.storeProduction.fichaTecnica = value
      }
    },
    lotes: {
      set (value) {
        this.$store.state.storeProduction.lotes = value
      },
      get () {
        return this.$store.state.storeProduction.lotes
      }
    },
    tagOfBatch: {
      set (value) {
        this.$store.state.storeProduction.tagOfBatch = value
      },
      get () {
        return this.$store.state.storeProduction.tagOfBatch
      }
    },
    batchList: {
      set (value) {
        this.$store.state.storeProduction.batchList = value
      },
      get () {
        return this.$store.state.storeProduction.batchList
      }
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    referenciaObj () {
      return this.$store.state.storeProduction.referencia
    },
    bases () {
      return this.$store.state.storeProduction.bases
    },
    insumos () {
      return this.$store.state.storeProduction.insumos
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      self.$store.state.dataReferencias = []
      axios
        .get('/api/product/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
          response.data.forEach(function (item, index) {
            var data = { nombre: item.name, ref: item.reference }
            self.$store.commit('actionSetDataReferencias', data)
          })
        })
    }
  },
  methods: {
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    calculateQuantitySupply (quantityReference, supplyId, index, positionSupply) {
      const supply = this.$store.state.dataPlanProduccion.rowsPlanProduccion[positionSupply].suppliesObject[supplyId]
      let quantity = 0
      if (supply.required_quantity !== 0 && supply.production_quantity !== 0) {
        quantity = (supply.required_quantity / supply.production_quantity) * quantityReference
      }
      // }
      if (supply.required_quantity === 0 || supply.production_quantity === 0) {
        quantity = quantityReference
      }
      this.$set(this.$store.state.dataPlanProduccion.rowsPlanProduccion[positionSupply].suppliesList[index], 'quantity', Number(Math.ceil(quantity)))
    },
    productProcess_ppk (reference, cantidad, posicion) {
      var referencia = reference?.ref
      const self = this
      if (this.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].cantidad < 0) {
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].cantidad = 0
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].horas = 0
      } else {
        if (referencia !== null && cantidad !== '') {
          axios.post('/api/ProductProcess_ppk/', { referencia: referencia }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
            .then((response) => {
              let totalHoras = 0
              response.data.forEach(element => {
                var horas = Number((cantidad * 60 / element.ppk_standard) / 60).toFixed(2)
                totalHoras += (Math.round(horas))
              })
              self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].horas = totalHoras
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      }
      if (self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].suppliesList.length > 0) {
        for (let index = 0; index < self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].suppliesList.length; index++) {
          const supply = self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].suppliesList[index].id_supply
          self.calculateQuantitySupply(self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].cantidad, supply, index, posicion)
        }
      }
      // if (!self.$store.state.editarReferenciaPlan) {
      //   if (self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].suppliesDataArray.length > 0) {
      //     for (let index = 0; index < self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].suppliesDataArray.length; index++) {
      //       const supply = self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].suppliesDataArray[index].id_supply
      //       self.calculateQuantitySupply(self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].cantidad, supply, index, posicion)
      //     }
      //   }
      // }
    },
    async getSuppliesProduct (reference, posicion, updateSuppliesDataArray = true, resetSuppliesArrays = false) {
      const self = this
      var referencia = reference?.ref
      if (resetSuppliesArrays) {
        this.$set(this.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion], 'suppliesList', [])
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].basesCount = 0
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion].suppliesCount = 0
      }
      await axios.post('/api/SuppliesProductProcess/', { product_code: referencia }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then((response) => {
          self.$set(self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion], 'suppliesObject', response.data.product_supplies_dict)
          if (updateSuppliesDataArray) {
            self.$set(self.$store.state.dataPlanProduccion.rowsPlanProduccion[posicion], 'suppliesList', response.data.product_supplies_data)
          }
        })
        .catch(function (error) {
          console.log(error)
        }).then(() => {
          if (updateSuppliesDataArray) {
            self.calculateCountBasesAndSupplies(posicion)
          }
        })
    },
    calculateCountBasesAndSupplies (position) {
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].basesCount = this.calculateCountSupplies(position, true)
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].suppliesCount = this.calculateCountSupplies(position, false)
    },
    calculateCountSupplies (position, isBase = false) {
      return this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].suppliesList.filter(
        x => this.$store.state.dataPlanProduccion.rowsPlanProduccion[position].suppliesObject[x.id_supply].is_base === isBase
      ).length
    },
    getQuantity (index) {
      return this.$store.state.storeTrack.inputBsn.quantity[index]
    },
    validateRangeDate () {
      const dateChosed = this.$store.state.dataPlanProduccion.date
      const dateBefore = this.dateTwoMonthsBefore
      const dateAfter = this.dateTwoMonthsAfter
      if (!(dateChosed >= dateBefore & dateChosed <= dateAfter)) {
        this.dateBsn = dateBefore
      }
    },
    addRow () {
      this.$store.state.dataPlanProduccion.rowsPlanProduccion.push({ codigo: '', descripcion: '', cantidad: '', lote: '', horas: 0, ficha: null, fichaName: '', suppliesObject: {}, basesCount: 0, suppliesCount: 0 })
    },
    confirmDelete (index) {
      this.indexDelete = index
    },
    referenceConfirm (pkproductsOp) {
      this.pk_productsOp = pkproductsOp
    },
    close_delete_modal () {
      this.$refs['close-delete-modal'].hide()
    },
    removeRow () {
      this.$store.state.dataPlanProduccion.rowsPlanProduccion.splice(this.indexDelete, 1)
      this.close_delete_modal()
    },
    referenceDelete () {
      if (this.pk_productsOp) {
        axios.delete('/api/deleteproductplan/' + this.pk_productsOp + '/' + this.codeOp, {
          headers: { Authorization: 'token ' + sessionStorage.getItem('token') }
        }).then((response) => {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach((error) => {
              this.announceToast(error.title, error.content, 7000, 'warning')
            })
          } else {
            this.announceToast('Éxito', 'Se eliminó un producto de la OP', 4000, 'success')
            this.$store.state.dataPlanProduccion.rowsPlanProduccion.splice(this.indexDelete, 1)
            this.close_delete_modal()
          }
        }).catch(() => {
          this.announceToast('Advertencia', 'Algo salió mal. Por favor, vuelva a intentarlo.', 4000, 'warning')
        })
      } else {
        this.close_delete_modal()
        this.$store.state.dataPlanProduccion.rowsPlanProduccion.splice(this.indexDelete, 1)
      }
    },
    setCalculateQuantity (index) {
      this.insumos[index].quantity = Math.round(this.cantidad * this.insumos[index].calculate_quantity)
      return this.insumos[index].quantity
    },
    quantity: function (index, value) {
      this.$store.commit('actionSetInputQuantity', { index, value })
    },
    getBatch (index) {
      return this.$store.state.storeTrack.inputBsn.batch[index]
    },
    batch: function (index, value) {
      this.$store.commit('actionSetInputBatch', { index, value })
    },
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    cantidadMin: function () {
      const self = this
      if (this.cantidad !== 0) {
        this.cantidad--
      }
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
      self.updateHour()
    },
    updateHour: function () {
      const self = this
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    cantidadMax: function () {
      const self = this
      this.cantidad++
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
      self.updateHour()
    },
    selectReference: function (id, resetMainQuantity = false) {
      const self = this
      if (resetMainQuantity === true) {
        self.cantidad = 0
      }
      self.$store.state.referenciaPlan = id.ref
      axios.get('/api/productinfo/' + id.ref, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.$store.commit('actionSetBases', response.data.Bases)
        if (self.$store.state.editarReferenciaPlan === false) {
          self.$store.commit('actionSetInsumos', response.data.Supplies)
        }
        self.$store.commit('actionSetLotes', self.$store.state.storeProduction.lotes)
        self.$store.commit('actionInsumoBsn', response.data.Supplies)
        if (self.referencia != null) {
          self.ppk_standard = response.data.Product.ppk_standard
          var personStandard = self.ppk_standard
          // ajustar
          var hours = (self.cantidad * 60 / personStandard) / 60
          self.$store.state.horasPlan = Number(hours).toFixed(2)
        }
      })
    },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    async fichaTecnicaReferencia (event, index) {
      var fichareference = event.target.files[0]
      if (fichareference) {
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[index].ficha = fichareference
        this.$store.state.dataPlanProduccion.rowsPlanProduccion[index].fichaName = event.target.files[0]?.name
        this.filereferencesStatus.splice(index, 1, true)
      }
    },
    deleteFileReferencia (index) {
      document.getElementById('FichaTecnicaReferencia' + index).value = ''
      this.$store.state.dataPlanProduccion.rowsPlanProduccion[index].ficha = ''
      this.filereferencesStatus.splice(index, 1, false)
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    },
    /* tags */
    mySetFunction: function () {
      this.tagOfBatch = ''
    },
    addTag (tag) {
      const regex = /^\d{8}-\d{1,7}$/
      if (!regex.test(tag)) {
        this.$bvToast.toast('Por favor, ingrese un lote de 8 números', {
          title: 'Advertencia!',
          variant: 'warning',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        this.batchList.push(tag)
      }
    },
    removeTag (index) {
      this.batchList.splice(index, 1)
      this.tagOfBatch = ''
    },
    tagExists (tag) {
      return this.batchList.indexOf(tag) !== -1
    },
    handleTyping (e) {
      if (e.keyCode === 13) {
        this.tagOfBatch = this.$store.state.storeProduction.lotes.replace(/,/, '-')
        if (!this.tagExists(this.tagOfBatch)) {
          this.addTag(this.tagOfBatch)
          this.mySetFunction()
          this.tagOfBatch = ''
        }
        this.lotes = ''
      }
    },
    storageOppositeValue () {
      this.showQuantityWorked = !this.showQuantityWorked
    },
    describeSupplies (index) {
      if (this.$store.state.editarReferenciaPlan) {
        this.getSuppliesProduct(this.$store.state.dataPlanProduccion.rowsPlanProduccion[index].codigo, index, false)
      }
      this.$store.state.dataPlanProduccion.indexRowSupplyPlanProduction = index
      this.$bvModal.show('modal_supplies_product_production')
    }
  }
}
</script>
<style lang="scss" scoped>
// Plan de Producción -------------------------------------------------
.box-simple {
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
}
.colform1 {
  position: relative;
  width: 30%;
  padding-right: 15px;
  padding-left: 15px;
}

.colform2 {
  position: relative;
  width: 70%;
  padding-right: 15px;
  padding-left: 15px;
}
.inputs {
  width: 42vh !important;
}
.title {
  margin-top: 1rem;
}
input {
  width: 100%;
  margin-bottom: 25px;
  padding: 8px 12px;
  // box-shadow: 0 1px 2px #0064b7;
  border: none;
  border-radius: 5px;
  &:hover, &:active, &:focus {
    outline: none;
    box-shadow: 0 1px 8px #0064b7;
  }
}
.tags {
  .tag {
      position: relative;
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 8px 30px 8px 12px;
      color: #fff;
      background: #03185a;
      border: 1px solid #03185a;
      border-radius: 5px;
      cursor: pointer;
      &:after {
        position: absolute;
        right: 8px;
        color: #39c2a1;
        content: '\00d7';
      }
    }
}
.textores {
  width: 100px;
  /* Control de la altura con base en el texto del div*/
  height: auto;
  word-wrap: break-word;
}
.btn-show-quantityWorked {
  gap: 0.5rem;
}
.btn-description-supplies {
  font-size: 15px;
}
.divider-supplies-add-op {
  width: 1px;
  height: 25px;
}
</style>

<template>
  <div class="page estadoLinea px-3 py-5 px-md-5">
    <div class="header-page">
      <h3>Orden de producción y compra</h3>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
        <!-- Pantalla Inicio Orden de producción -->
        <div class="item-page-list w-100" v-if="listTrack">
          <div>
            <div class="text-left my-3 d-flex justify-content-between">
              <div class="col-sm d-flex justify-content-start">
                <span class="icon-list icon-blue-dark">
                  <i class="fas fa-cogs fa-1x"></i></span> <span class="mx-3 h4">Gestión de órdenes</span>
              </div>
            </div>
            <div class="row justify-content-end mt-4 mb-4 filters">
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por OP:</p>
                <div>
                  <b-form-input v-model="codeOp" @change="searchOp(codeOp)" placeholder="Ingrese una OP" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por OC:</p>
                <div>
                  <b-form-input v-model="nOc" @change="searchOc(nOc)" placeholder="Ingrese una OC" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar Cód. producto:</p>
                <div>
                  <b-form-input v-model="refOp" @change="searchCodeProduct(refOp)" placeholder="Ingrese un código" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por mes:</p>
                <div>
                  <b-form-select v-model="monthOp" @change="searchMoth(monthOp)" :options="options"></b-form-select>
                </div>
              </div>
              <div class="col-xl-1 allData text-left">
                <button class="btn-filter-search" @click="allData()">Refrescar</button>
              </div>
            </div>
            <div class="table-responsive">
              <table v-if="loadTable" class="table table-list list-track">
                <thead>
                  <tr>
                    <th class="row-op text-center">Orden de Producción</th>
                    <th class="row-oc text-center">Orden de Compra</th>
                    <th style="width: 10rem;"></th>
                    <th style="width: 10rem;"></th>
                    <th></th>
                    <th></th>
                    <th v-for="(data, i) in product_process_type_data" :key="i"></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="subhead-titles">
                    <td class="font-weight">N°. OP</td>
                    <td class="font-weight">N°. OC</td>
                    <td class="font-weight">Cod. Producto</td>
                    <td class="font-weight">Línea</td>
                    <td class="font-weight">Cantidad</td>
                    <td class="font-weight" v-for="(data, i) in product_process_type_data" :key="i">{{ data.name }}</td>
                    <td class="font-weight">Cantidad Maquilada</td>
                    <td class="font-weight">Porcentaje de cumplimiento</td>
                  </tr>
                  <template v-for="item in paginatedOcAndOp" >
                    <tr v-for="(subitem, i) in item.referencias" :key="subitem.pk">
                      <td v-if="i === 0" :rowspan="item.referencias.length">
                        <b-button class="text-dark font-weight-bold" variant="link" @click="$bvModal.show('detail-order-purchase');modalListClose();putCurrentTask(item.code_op);trazabilityFunction(item.code_op)">{{ item.code_op }}</b-button>
                      </td>
                      <td v-if="i === 0" :rowspan="item.referencias.length">
                        {{ item.code_oc }}
                      </td>
                      <td>
                        {{ subitem.product_code }}
                      </td>
                      <td v-if="i === 0" :rowspan="item.referencias.length">
                        {{ item.line }}
                      </td>
                      <td>
                        {{ subitem.start_quantity }}
                      </td>
                      <td v-for="process in subitem.quantityprocess" :key="process.order">
                        {{ process.produccion }}
                      </td>
                      <td>
                        {{ subitem.endquantity }}
                      </td>
                      <td>
                      <span class="font-weight-bold" :class="{ 'color-green' : subitem.percentage >= 100, 'color-yellow' : subitem.percentage <= 99 && subitem.percentage >= 60, 'color-red' : subitem.percentage <= 59 && subitem.percentage >= 1}"> {{ subitem.percentage + ' %' }}
                      </span>
                      </td>
                      <td v-if="!item.code_oc && i === 0" :rowspan="item.referencias.length">
                      <button class="btn py-0 px-2" @click="showBlock(item);"><i class="far fa-edit text-success"></i></button>
                    </td>
                    </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="load" style="color: #03185A;">
              <b-spinner label="Text Centered"></b-spinner>
            </div>
            <div  class="center" v-if="dataOrderStatus.length === 0 && load === false">
              <span><b-badge variant="danger align-middle"><h6>No existen registros en la base de datos.</h6></b-badge></span>
            </div>
            <br>
            <b-pagination class="list-pagination" pills align="center" hide-ellipsis :total-rows="dataOrderStatus.length" v-model="pag" :per-page="NUM_RESULTS" />
            <div class="my-3" v-if="is_leader()">
              <b-button class="btn-action-page py-2" @click="showBlock();currentData = []" size="lg">Agregar nota de despacho</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav-page p-4" :class="{ openSidebar: openOptions }">
      <side-block
        :icon="'track-interna'"
        :tpl="'purchase'"
        @closeBlock="close"
        :data="currentData"
      />
    </div>
    <!-- Modal Trazabilidad por órdenes de producción -->
    <div>
      <b-modal v-if="showModal" ref="close-sub-modal" id="detail-order-purchase" size="xl" :hide-footer="true" :hide-header="true" body-class="">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_sub_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 mb-3" id="imprimir3">
          <div class="" style="display: flex; justify-content: center; padding-bottom: 50px;">
            <img src="../assets/logoproduempak.png" alt="" width="350">
          </div>
          <h4 class="color-bluedark mt-1 mb-5">Trazabilidad por órdenes de producción</h4>
          <div class="table-responsive mt-4" style="text-align: center">
            <div class="table-responsive">
              <div class="d-flex" id="typeFilter">
                <div class="text-center col-12 row-cost"><span>Detalle orden de producción</span></div>
              </div>
              <table v-if="loadTable" class="table class-trazability">
                <thead>
                  <div class="container-fluid text-center">
                    <div class="row border-thead" v-if="statusop === false">
                      <div class="col">
                        <div class="mb-3 mt-3">
                          <label for="exampleInputEmail1" style="align-items: end;display: flex;">Estado</label>
                          <div class="alert alert-success" role="alert">
                            Por maquilar
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row border-thead" v-if="statusop === true">
                      <div class="col">
                        <div class="mb-3 mt-3">
                          <label for="exampleInputEmail1" style="align-items: end;display: flex;">Estado</label>
                          <div class="alert alert-primary" role="alert">
                            Maquilado
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </thead>
                <tbody>
                  <tr>
                    <div class="container-fluid text-center border-body">
                      <div class="row border-table" v-for="(item, a) in trasbilityOpAndOc" :key="a">
                        <div class="col">
                          <div class="mb-3 mt-3">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Código orden de producción</label>
                            <input type="text" class="form-control" v-model="item.op" :placeholder="item.op" readonly>
                          </div>
                          <div class="mb-3 mt-3">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Código orden de
                              compra</label>
                            <input type="text" class="form-control" :value="item.oc" :placeholder="item.oc" readonly>
                          </div>
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Código del
                              producto y Lote</label>
                            <div v-for="(referencias, i) in item.referenciaOp" :key="i" style="text-align: initial;">
                              <input type="text" class="form-control" v-model="referencias.referencia" :placeholder="referencias.referencia" readonly>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Descripción</label>
                            <div v-for="(descripcion, i) in item.descripcionOp" :key="i" style="text-align: initial;">
                              <input type="text" class="form-control" v-model="descripcion.nombre" :placeholder="descripcion.nombre" readonly>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Cantidad</label>
                            <div v-for="(cantidad, i) in item.cantidadReference" :key="i" style="text-align: initial;">
                              <input type="text" class="form-control" v-model="cantidad.cantidad" :placeholder="cantidad.cantidad" readonly>
                            </div>
                          </div>
                          <div class="mb-4">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Fecha de
                              creación</label>
                            <input type="text" class="form-control" :value="item.fechaOp" :placeholder="item.fechaOp" readonly>
                          </div>
                          <div class="mb-4">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Fecha de
                              creación OC</label>
                            <input type="text" class="form-control" :value="item.fechaOc" :placeholder="item.fechaOc" readonly>
                          </div>
                          <!-- <div class="mb-3 mt-3">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Lotes</label>
                            <div v-for="(lotes, i) in item.loteop" :key="i" style="text-align: initial;">
                              <input type="text" class="form-control" v-model="lotes.batch" :placeholder="lotes.batch" readonly>
                            </div>
                          </div> -->
                        </div>
                        <!-- <div class="col">
                          <div class="mb-3 mt-3">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Código orden de
                              compra</label>
                            <input type="text" class="form-control" :value="item.oc" :placeholder="item.oc" readonly>
                          </div>
                          <div class="mb-4">
                            <label for="exampleFormControlInput1" class="form-label label-detail">Fecha de
                              creación</label>
                            <input type="text" class="form-control" :value="item.fechaOc" :placeholder="item.fechaOc" readonly>
                          </div>
                        </div> -->
                      </div>
                      <div class="center p-3" v-if="trasbilityOpAndOc.length === 0">
                          <!-- <span><b-badge variant="danger align-middle">
                            <h6>Por favor, filtre por una orden de producción</h6>
                          </b-badge></span> -->
                          <div class="alert alert-danger mt-3 mb-3" role="alert">
                            No existen registros.
                          </div>
                      </div>
                    </div>
                    <div class="accordion mt-5 mb-5" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab" >
                          <b-button block v-b-toggle.accordion-1 class="btn-header" v-on:click="rotated1 = !rotated1" >
                            Trazabilidad por picking
                            <svg v-bind:class="{ rotate: true, rotated: rotated1 }" style="position: absolute;right: 2vw;top: 2vh;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                            </svg>
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" v-model="collapseStates[0]">
                          <div class="col-xs-8 col-xs-offset-2 well">
                            <table class="table table-scroll table-list">
                              <tbody v-if="trasbilityPicking.length != 0">
                                <tr class="subhead-titles">
                                  <th>Código material</th>
                                  <th>Descripción</th>
                                  <th>Lote</th>
                                  <th>Cantidad orden de transporte</th>
                                  <th>Cantidad recibida</th>
                                </tr>
                                <tr v-for="(a, b) in trasbilityPicking" :key="b">
                                  <td>{{ a.supplies_code }}</td>
                                  <td>{{ a.supplies_code__name }}</td>
                                  <td>{{ a.batch }}</td>
                                  <td>{{ a.amount_of_transportation }}</td>
                                  <td>{{ a.received_amount }}</td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                  <!-- <span style="color: #fff;background-color: #dc3545;"><b-badge variant="danger align-middle mt-4 mb-5">
                                    <h6>La orden de producción no cuenta con trazabilidad por picking.</h6>
                                  </b-badge></span> -->
                                  <div class="alert alert-danger mt-3 mb-3" role="alert">
                                    La orden de producción no cuenta con trazabilidad por picking.
                                  </div>
                                <tr class="subhead-titles">
                                  <th>Código material</th>
                                  <th>Descripción</th>
                                  <th>Lote</th>
                                  <th>Cantidad orden de transporte</th>
                                  <th>Cantidad recibida</th>
                                </tr>
                                <tr>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-collapse>
                      </b-card>
                    </div>
                    <div class="accordion mt-5 mb-5">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-2 class="btn-header" v-on:click="rotated2 = !rotated2">
                            Trazabilidad por producción
                            <svg v-bind:class="{ rotate: true, rotated: rotated2 }" style="position: absolute;right: 2vw;top: 2vh;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                            </svg>
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" v-model="collapseStates[1]">
                          <div class="col-xs-8 col-xs-offset-2 well">
                            <table class="table table-scroll table-list">
                              <tbody v-if="trasbilityProduction.length != 0">
                                <tr class="subhead-titles">
                                  <th>Fecha</th>
                                  <th>Línea</th>
                                  <th>Lote</th>
                                  <th>Cantidad</th>
                                </tr>
                                <template v-for="data in trasbilityProduction">
                                  <tr v-for="(c, d) in data" :key="d">
                                    <td>{{ c.date_without_time }}</td>
                                    <td>{{ c.line_code__name }}</td>
                                    <td>{{ c.batch }}</td>
                                    <td>
                                      <span v-if="c.process_id === null || c.process_id === 1">Empaque: </span><span v-if="c.process_id === 2">Horno: </span>{{ c.suma }}
                                    </td>
                                  </tr>
                                </template>
                                <tr>
                                  <td colspan="4" class="text-center" style="font-weight: 600">Cantidad total: {{ sumCant }}</td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr class="subhead-titles">
                                  <th>Fecha</th>
                                  <th>Línea</th>
                                  <th>Lote</th>
                                  <th>Cantidad</th>
                                </tr>
                                <!-- <span><b-badge variant="danger align-middle mt-4 mb-5">
                                    <h6>La OP no cuenta con trazabilidad por producción.</h6>
                                  </b-badge></span> -->
                                  <div class="alert alert-danger mt-3 mb-3" role="alert">
                                    La OP no cuenta con trazabilidad por producción.
                                  </div>
                                <tr>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-collapse>
                      </b-card>
                    </div>
                    <div class="accordion mt-5 mb-5" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-3 class="btn-header" v-on:click="rotated3 = !rotated3">
                            Trazabilidad por inventario
                            <svg v-bind:class="{ rotate: true, rotated: rotated3 }" style="position: absolute;right: 2vw;top: 2vh;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                            </svg>
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-3" v-model="collapseStates[3]">
                          <div class="col-xs-8 col-xs-offset-2 well">
                            <table class="table table-scroll table-list">
                              <tbody v-if="trasbilityInventario.length != 0">
                                <tr class="subhead-titles">
                                  <th>Fecha</th>
                                  <th>Tipo</th>
                                  <th>Código insumo</th>
                                  <th>Lote</th>
                                  <th style="width: 11vw">Cantidad</th>
                                  <th>Lugar</th>
                                  <th>Responsable</th>
                                </tr>
                                <tr v-for="(e, f) in trasbilityInventario" :key="f">
                                  <td>{{ e.date_without_time }}</td>
                                  <td>{{ e.type__name }}<span v-b-tooltip.hover.right :title="e.description"><i
                                        class="fa fa-info-circle ml-2" style="width: 15px;" aria-hidden="true"></i></span></td>
                                  <td>{{ e.supplies_code__pk }}</td>
                                  <td>{{ e.batch }}</td>
                                  <td style="width: 11vw">{{ e.quantity }}</td>
                                  <td>{{ e.place__name }}</td>
                                  <td>{{ e.creator__last_name }}</td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <center>
                                  <!-- <span><b-badge variant="danger align-middle mt-4 mb-5">
                                      <h6>La orden de producción no cuenta con trazabilidad por inventario.</h6>
                                    </b-badge></span> -->
                                    <div class="alert alert-danger mt-3 mb-3" role="alert">
                                      La orden de producción no cuenta con trazabilidad por inventario.
                                    </div>
                                </center>
                                <tr class="subhead-titles">
                                  <th>Fecha</th>
                                  <th>Tipo</th>
                                  <th>Código insumo</th>
                                  <th>Descripción</th>
                                  <th>Lote</th>
                                  <th>Cantidad</th>
                                  <th>Lugar</th>
                                  <th>Responsable</th>
                                </tr>
                                <tr>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-collapse>
                      </b-card>
                    </div>
                    <div class="accordion mt-5 mb-5" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-4 class="btn-header" v-on:click="rotated4 = !rotated4">
                            Trazabilidad por nota de despacho
                            <svg v-bind:class="{ rotate: true, rotated: rotated4 }" style="position: absolute;right: 2vw;top: 2vh;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                            </svg>
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-4" v-model="collapseStates[4]">
                          <div class="col-xs-8 col-xs-offset-2 well" style="display: flex;justify-content: center;">
                            <table v-if="loadTable" class="table-list">
                              <tbody v-if="trasbilityremission.length != 0">
                                <tr class="subhead-titles">
                                  <td class="font-weight" style="width: 10%;">Consecutivo lista empaque</td>
                                  <td class="font-weight" style="width: 10%;">Consecutivo nota despacho</td>
                                  <td class="font-weight">Fecha</td>
                                  <td class="font-weight">N° OP</td>
                                  <td class="font-weight">N° OC</td>
                                  <td class="font-weight">Cod. Producto</td>
                                  <td class="font-weight">Descripción</td>
                                  <td class="font-weight">Lote verificado</td>
                                  <td class="font-weight">Estado despacho</td>
                                  <td class="font-weight">Código SIESA</td>
                                  <td class="font-weight">Cantidad</td>
                                  <td></td>
                                </tr>
                                <template v-for="item in   trasbilityremission  ">
                                  <tr v-for="(subitem, i) in   item.Remission  " :key="subitem.pk">
                                    <td v-if="i === 0" :rowspan="item.Remission.length" style="width: 10%;">
                                      <span class="d-block text-dark font-weight-bold">{{ item.PackingList }}</span>
                                    </td>
                                    <td style="width: 10%;">
                                      <span class="d-block text-dark font-weight-bold">{{ subitem.id }}</span>
                                    </td>
                                    <td style="width: 8%;"><span class="d-block">{{ subitem.date }}</span></td>
                                    <td style="width: 9%;"><span class="d-block">{{ subitem.Op }}</span></td>
                                    <td style="width: 9%;"><span class="d-block">{{ subitem.oc }}</span></td>
                                    <td style="width: 12%;"><span class="d-block">{{ subitem.reference }}</span></td>
                                    <td style="width: 17%;"><span class="d-block">{{ subitem.descripcion }}</span></td>
                                    <td style="width: 8%;"><span class="d-block">{{ subitem.batch }}</span></td>
                                    <td style="width: 8%;"><span class="d-block">{{ subitem.status }}</span></td>
                                    <td style="width: 10%;"><span class="d-block">{{ subitem.codigo_siesa }}</span></td>
                                    <td style="width: 15%;"><span class="d-block">{{ subitem.quantity }}</span></td>
                                  </tr>
                                </template>
                                <tr>
                                  <td colspan="11" class="text-center" style="font-weight: 600">Cantidad total: {{ sumCantInv }}</td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <center>
                                  <!-- <span><b-badge variant="danger align-center mt-4 mb-5">
                                      <h6>La orden de producción no cuenta con trazabilidad por notas de despacho.</h6>
                                    </b-badge></span> -->
                                  <tr style="background-color: #fff;border-left: none;border-bottom: none;">
                                    <td colspan="11" class="text-center" style="font-weight: 600;padding: 0;border-left: none;vertical-align: bottom;"><div class="alert alert-danger mt-3 mb-3" role="alert">
                                    La orden de producción no cuenta con trazabilidad por inventario.
                                  </div></td>
                                  </tr>
                                  <tr class="subhead-titles">
                                    <th>Consecutivo lista empaque</th>
                                    <th>Consecutivo nota despacho</th>
                                    <th>N° OP</th>
                                    <th>N° OC</th>
                                    <th>Cod. Producto</th>
                                    <th>Descripción</th>
                                    <th>Lote verificado</th>
                                    <th>Estado despacho</th>
                                    <th>Código SIESA</th>
                                    <th>Cantidad</th>
                                  </tr>
                                  <template>
                                    <tr>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                    </tr>
                                  </template>
                                </center>
                              </tbody>
                            </table>
                          </div>
                        </b-collapse>
                      </b-card>
                    </div>
                  </tr>
                </tbody>
              </table>
              <div v-if=" is_admin() " class="my-5">
                <div class="row justify-content-center">
                  <div class="col-3">
                    <b-button class="btn-action-page py-2" @click=" $bvModal.show('modal-close-op'); " size="lg">Cerrar orden</b-button>
                  </div>
                  <div class="col-2">
                    <b-button class="btn-action-page py-2" @click="print3()" size="lg">Imprimir</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- Modal de eliminar -->
    <div class="modal-close-op">
      <b-modal ref="close-delete-modal" id="modal-close-op" centered :hide-footer=" true " :hide-header=" true ">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="py-5">
            <div class="circle-icon-page d-flex justify-content-center align-items-center">
              <div>
                <img src="~@/assets/icons/loop.png" class="img-fluid">
              </div>
            </div>
            <div class="pt-4 text-center">
              <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea cerrar la orden de producción #
                {{ op_number }}?</h5>
              <div>
                <b-button size="lg" class="btn-actions btn-cancel mx-3" @click=" close_delete_modal_traz() ">Cancelar</b-button>
                <b-button size="lg" class="btn-actions btn-delete mx-3" @click=" updateProductionOrder() ">Cerrar</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- Modal PDF --->
    <div>
      <b-modal ref="pdf-modal" id="pdf-modal" size="xl" centered :hide-footer="true" :hide-header="true">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_pdf_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div align="center">
          <pdf :src="currentDataSheetURL" id="pdf-view" :resize="true" @loading="removeText()" :page="1">
            <template slot="loading">
              Cargando ficha técnica...
            </template>
          </pdf>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import pdf from 'pdfvuer'
import SideBlock from '@/components/SideBlock.vue'
import moment from 'moment'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    op: '',
    load: true,
    rotated1: false,
    rotated2: false,
    rotated3: false,
    rotated4: false,
    code: undefined,
    trasbilityOpAndOc: [],
    trasbilityPicking: [],
    trasbilityProduction: [],
    trasbilityInventario: [],
    trasbilityremission: [],
    collapseStates: [false, false, false, false],
    op_number: 0,
    datedetail: '',
    detaildata: [],
    datearray: [],
    consecutivoarray: [],
    observationsarray: [],
    statusop: [],
    NUM_RESULTS: 10, // Numero de resultados por página
    pag: 1, // Página inicial
    pagdetail: 1, // Página inicial
    currentDataSheetURL: '',
    showModal: false,
    listTrack: true,
    searchTrack: '',
    currentTrackItem: [],
    currentData: [],
    product_process_type_data: [],
    urlsheet: '',
    loadTable: true,
    codeOp: undefined,
    codeList: [],
    monthOp: null,
    selectedItems: null,
    nOc: undefined,
    refOp: undefined,
    filterOption: null,
    sumCant: null,
    sumCantInv: null,
    options: [
      { value: null, text: 'Seleccione un mes' },
      { value: '01', text: 'Enero' },
      { value: '02', text: 'Febrero' },
      { value: '03', text: 'Marzo' },
      { value: '04', text: 'Abril' },
      { value: '05', text: 'Mayo' },
      { value: '06', text: 'Junio' },
      { value: '07', text: 'Julio' },
      { value: '08', text: 'Agosto' },
      { value: '09', text: 'Septiembre' },
      { value: '10', text: 'Octubre' },
      { value: '11', text: 'Noviembre' },
      { value: '12', text: 'Diciembre' }
    ],
    filterOptions: [
      { value: null, text: 'Seleccione un filtro' },
      { value: '1', text: 'Filtro por OP' },
      { value: '2', text: 'Filtro por OC' },
      { value: '3', text: 'Filtro por mes' },
      { value: '4', text: 'Ver todo' }
    ]
  }),
  components: {
    pdf,
    SideBlock
  },
  async mounted () {
    if (sessionStorage.token && sessionStorage.rol === '1') {
      this.setdataOrderStatus()
      this.getproduct_process_type()
      this.$store.dispatch('getLineListWithTeam')
      this.$root.$on('setdataOrderStatus', () => {
        this.setdataOrderStatus()
      })
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('atLeastQualityRecordFn')
    } else {
      this.$router.push({ name: 'Inicio' })
    }
  },
  computed: {
    paginatedOp () {
      // Calcular la lista paginada
      const startIndex = (this.pagdetail - 1) * this.NUM_RESULTS
      const endIndex = startIndex + this.NUM_RESULTS
      return this.codeList.slice(startIndex, endIndex)
    },
    paginatedOcAndOp () {
      // Calcular la lista paginada
      const startIndex = (this.pag - 1) * this.NUM_RESULTS
      const endIndex = startIndex + this.NUM_RESULTS
      return this.dataOrderStatus.slice(startIndex, endIndex)
    },
    getLineList () {
      return this.$store.state.lineListWithTeam
    },
    currentTask () {
      return this.$store.state.storeProduction.currentTask
    },
    dataOrderStatus: {
      get () {
        return this.$store.state.storeTrack.dataOrderStatus
      },
      set (value) {
        this.$store.state.storeTrack.dataOrderStatus = value
      }
    },
    openOptions () {
      return this.$store.state.showOptions
    }
  },
  methods: {
    close_sub_modal_traz () {
      this.$refs['close-sub-modal'].hide()
    },
    close_pdf_modal_traz () {
      this.$refs['pdf-modal'].hide()
    },
    close_delete_modal_traz () {
      this.$refs['close-delete-modal'].hide()
    },
    modalListCloseTraz: function () {
      this.$bvModal.hide('modal-close-op')
    },
    is_admin () {
      if (sessionStorage.getItem('rol') === '1') {
        return true
      } else {
        return false
      }
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    print () {
      this.$htmlToPaper('imprimir')
    },
    print2 () {
      this.$htmlToPaper('imprimir2')
    },
    print3 () {
      this.$htmlToPaper('imprimir3')
    },
    collapseAll () {
      this.collapseStates = this.collapseStates.map(x => false)
      this.rotated1 = false
      this.rotated2 = false
      this.rotated3 = false
      this.rotated4 = false
    },
    formatDate (fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    modalListClose: function () {
      this.$bvModal.hide('modal-list-production')
    },
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    reload () {
      setTimeout(function () {
        location.reload()
      }, 100)
    },
    async searchCodeProduct (code) {
      const self = this
      self.load = true
      await axios.post('/api_orders/getorders/', { reference: code }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.filterSupplie = true
        self.alldata = false
        self.dataOrderStatus = response.data.data
        self.load = false
      })
      self.refOp = ''
    },
    async searchOc (code) {
      const self = this
      self.load = true
      await axios.post('/api_orders/getorders/', { code_oc: code }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.filterSupplie = true
        self.alldata = false
        self.dataOrderStatus = response.data.data
        self.load = false
      })
      self.nOc = ''
    },
    async searchOp (code) {
      const self = this
      self.load = true
      await axios.post('/api_orders/getorders/', { codeOp: code }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.filterSupplie = true
        self.alldata = false
        self.dataOrderStatus = response.data.data
        self.load = false
      })
      self.codeOp = ''
    },
    async searchMoth (month) {
      const self = this
      self.load = true
      await axios.post('/api_orders/getorders/', { monthOp: month }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.filterSupplie = true
        self.alldata = false
        self.dataOrderStatus = response.data.data
        self.load = false
      })
    },
    async allData () {
      this.monthOp = null
      return await this.setdataOrderStatus()
    },
    renderPdf (urlSent) {
      this.currentDataSheetURL = url + 'media/' + urlSent.split('/media/')[1]
      this.$bvModal.show('pdf-modal')
    },
    showBlock: function (info) {
      const self = this
      this.$store.state.storeInventory.editarPk = true
      this.$store.state.storeInventory.opForSideBlock = info.code_op
      this.$store.state.dataReferencias = []
      axios.post('/api_orders/getReferencesByOp/', { OP: this.$store.state.storeInventory.opForSideBlock }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.forEach(function (item, index) {
          var data = { value: item.product_code__reference, text: item.product_code__reference + ' - ' + item.batch, id: item.id }
          self.$store.commit('actionSetDataReferencias', data)
        })
        // console.log(response)
      })
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    updateTrack () {
      this.$store.state.storeTrack.updateTrack = true
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    close_modal: function () {
      this.$refs['close-modal'].hide()
    },
    setTrazToAction: function (item) {
      this.$store.commit('actionSetOpToAction', item)
    },
    async putCurrentTask (task) {
      const self = this
      await axios.post('/api_orders/getConsolidatedLinebsn/', { code_op: task }).then(function (response) {
        self.codeList = response.data
        self.showModal = true
      }).catch(function (error) {
        console.log(error)
      })
      this.$bvModal.show('detail-order-purchase')
    },
    infoLoaded () {
      if (this.codeList.length !== 0) {
        return true
      } else {
        return false
      }
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
    },
    close_pdf_modal () {
      this.$refs['pdf-modal'].hide()
    },
    async setdataOrderStatus () {
      const self = this
      self.dataOrderStatus = []
      self.load = true
      await axios.get('/api_orders/getorders/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.dataOrderStatus = response.data.data
        self.load = false
      })
    },
    async getproduct_process_type () {
      const self = this
      self.product_process_type_data = []
      await axios.get('/api/getproduct_process_type/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.product_process_type_data = response.data
      })
    },
    /* Trazabilidad por orden de producción */
    async trazabilityFunction (item) {
      const self = this
      await axios.post('/api_orders/TrasbilityOp/', { code_op: item }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach(error => {
            self.announceToast('Advertencia', error.title, error.time, 'warning')
          })
        } else {
          self.trasbilityOpAndOc = response.data.trasbilityOpAndOc
          self.op_number = response.data.trasbilityOpAndOc[0].code
          self.trasbilityPicking = response.data.trasbilityPicking
          self.trasbilityProduction = response.data.trasbilityProduction
          self.trasbilityInventario = response.data.trasbilityInventario
          self.trasbilityremission = response.data.trasbilityremission
          self.statusop = response.data.statusop[0].complete
          self.loadTable = true
          self.collapseStates = self.collapseStates.map(x => true)
          self.rotated1 = true
          self.rotated2 = true
          self.rotated3 = true
          self.rotated4 = true
          // let sumatotal = 0
          // for (let index = 0; index < response.data.trasbilityProduction.length; index++) {
          //   const element = response.data.trasbilityProduction[index].suma
          //   sumatotal = sumatotal + element
          // }
          // self.sumCant = sumatotal
          let sumaempaque = 0
          let sumahorno = 0
          let sumatotal = 0
          response.data.trasbilityProduction.forEach((element, i) => {
            element.forEach((data, i) => {
              if (data.process_id === null || data.process_id === 1) {
                sumaempaque = sumaempaque + data.suma
              }
              if (data.process_id === 2) {
                sumahorno = sumahorno + data.suma
              }
            })
          })
          if (sumaempaque !== 0) {
            sumatotal = sumaempaque
          }
          if (sumahorno !== 0) {
            sumatotal = sumahorno
          }
          self.sumCant = sumatotal
          // console.log(response.data.trasbilityProduction)
          let sumaTotalInv = 0
          for (let index = 0; index < response.data.trasbilityremission.length; index++) {
            for (let a = 0; a < response.data.trasbilityremission[index].Remission.length; a++) {
              const element = response.data.trasbilityremission[index].Remission[a].quantity
              sumaTotalInv = sumaTotalInv + element
            }
          }
          self.sumCantInv = sumaTotalInv
        }
      })
      self.op = ''
    },
    async filterTrazabilityFunction () {
      const self = this
      await axios.get('/api_orders/TrasbilityOp/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.trasbilityOpAndOc = response.data.trasbilityOpAndOc
        self.op_number = 0
        self.trasbilityPicking = response.data.trasbilityPicking
        self.trasbilityProduction = response.data.trasbilityProduction
        self.trasbilityInventario = response.data.trasbilityInventario
        self.trasbilityremission = response.data.trasbilityremission
        self.collapseStates = self.collapseStates.map(x => false)
        self.loadTable = true
        self.rotated1 = false
        self.rotated2 = false
        self.rotated3 = false
        self.rotated4 = false
        self.statusop = []
      })
    },
    async updateProductionOrder () {
      await axios.post('/api_orders/updateProductionOrder/', { codeOp: this.op_number }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      }).catch(function (error) {
        console.log(error)
      })
      this.filterTrazabilityFunction()
      this.collapseStates = this.collapseStates.map(x => false)
      this.announceToast('Éxito', 'La orden de producción # ' + this.op_number + ' se ha cerrado.', 10000, 'success')
      this.close_delete_modal_traz()
      this.op_number = 0
    },
    async detailPackinglist (packinglist) {
      await axios.post('/api_orders/StartRemission/', { packing_list: packinglist }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        response.data.forEach((element, index) => {
          this.observationsarray = element.Remission[0].observations
          this.datearray = element.Remission[0].date
          this.consecutivoarray = element.PackingList
          this.detaildata = element.Remission
        })
      })
    },
    async detailRemission (remission) {
      await axios.post('/api_orders/StartRemissiondetail/', { id: remission }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        this.detaildata = response.data.Remission
        this.datedetail = response.data.Remission[0].date
      })
    }
  }
}
</script>

<style lang="scss">
  .list-track {
    thead {
      tr {
        background-color: #F5F6F8;
        border-bottom: 1px solid #ccc;
        th.row-op {
          background-color: #39C2A1;
          color: #FFF;
        }
        th.row-oc {
          background-color: #03185A;
          color: #FFF;
        }
      }
    }
    tbody {
      tr.subhead-titles {
        background-color: #FFF;
        border: 0;
      }
      td {
        vertical-align: middle;
        &.multiple-register {
          span {
            border-bottom: 1px solid #8e8e8e;
            padding-bottom: 5px;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
  .form-control-false {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #00C875;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
.form-control-true {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #00b7ff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .border-body {
    border-bottom: 1px solid #03185a;
    border-right: 1px solid #03185a;
    border-left: 1px solid #03185a;
  }
  .border-thead {
    border-right: 1px solid #03185a;
    border-left: 1px solid #03185a;
  }
  .rotate {
    transition: transform .3s;
  }
  .rotate.rotated {
    transform: rotate(90deg);
  }
  .icon-rotate:hover {
    transform: rotateZ(89deg);
  }
  .cerrar-btn {
    font-size: 15px;
  }
  .refrescar-btn {
    font-size: 15px;
  }
  .tbody-scroll {
    height: 322px;
    overflow-y: auto;
    width: 100%;
    display: block;
  }
  .accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
  }
  .filter-adjust {
    margin-right: 0vw;
    margin-top: 2.5rem;
  }
  .well {
    background: none;
    height: auto;
  }

  .table-scroll tr {
    width: 100%;
    table-layout: fixed;
    display: inline-table;
  }
  .table-scroll thead > tr > th {
    border: none;
  }
  #typeFilter div.row-cost {
    background-color: #03185A;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    color: white;
    font-weight: 600;
    border: 1px solid #03185A;
  }
  .class-trazability {
    thead {
    tr {
        background-color: white;
        th.row-op {
          background-color: #39C2A1;
          color: #FFF;
        }
        th.row-oc {
          background-color: #03185A;
          color: #FFF;
        }
      }
    }
    tbody {
      tr.subhead-titles {
        background-color: #FFF;
        border: 0;
      }
      td {
        vertical-align: middle;
        &.multiple-register {
          span {
            border-bottom: 1px solid #8e8e8e;
            padding-bottom: 5px;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none!important;
    border-radius: 0.25rem;
  }
  .label-detail {
    display: flex;
    justify-content: left;
  }
  .btn-info {
    color: #fff;
  }
  .btn-header {
    background-color: #03185A!important;
    border: 1px solid #03185A!important;
  }
  .btn-header:hover {
    background-color: #03185A!important;
    border: 1px solid #03185A!important;
  }
  @media (max-width: 1024px) {
    .class-trazability thead tr {
      background-color: white;
      font-size: 14px;
    }
  }
  @media (max-width: 950.98px) {
    #detail-task {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  }
  @media (min-width: 950.98px) {
    #detail-task, #pdf-modal {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 15%;
        margin-right: 10%;
      }
    }
  }
  @media (max-width: 834px) {
    .filter-adjust {
      margin-right: 8vw;
      margin-top: 1.5rem;
    }
    .cerrar-btn {
      margin-left: 2.2rem;
      width: 97px;
      font-size: 13px;
    }
    .refrescar-btn {
      font-size: 13px;
    }
    .table-list tbody td {
      padding: none;
      border-left: 1px solid #ccc;
      vertical-align: middle;
    }
  }
  @media (max-width: 500px) {
    .list-track thead tr th.row-op {
      background-color: #39C2A1;
      color: #FFF;
      font-size: 12px;
    }
    .list-track thead tr th.row-oc {
      background-color: #03185A;
      color: #FFF;
      font-size: 12px;
    }
  }
  .container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
button, input, optgroup, select, textarea {
  padding-left: 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.btn-refresh {
  // background-color: #39C2A1;
  background-color: #03185A;
  display: flex;
  box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
  font-weight: 600;
  border-radius: 10px;
  border: 0;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1rem;
}
.my-class-form-control-group{
  display: flex;
  align-items: center;
  margin-left: 60vw;
  padding-bottom: 2rem;
}
.titleFilter {
  margin-right: 11rem;
}
.custom-filter {
  border: 1px solid #363D8A;
  border-radius: 8px;
  height: 45px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
}
.allData {
  margin-top: 2.5rem;
}
.font-weight {
  font-weight: 600;
}
</style>
